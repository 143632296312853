import { useState } from 'react';
import { ErrorBoundary } from 'shared-ui';

import { fetchUsers } from 'api/users/users';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { Users } from './Users';

export const UsersContainer = () => {
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.USERS,
      resetPaginationDependencyArray: [tableSearchQuery],
    });

  const { data: usersResponse, isLoading: isLoadingUsers } = useQuery(
    [QueryKeyEnum.USERS, storedQueryWithoutPagination, page, rowsPerPage, tableSearchQuery],
    fetchUsers,
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        query: tableSearchQuery,
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  return (
    <ErrorBoundary>
      <Users
        users={usersResponse?.data || []}
        isLoadingUsers={isLoadingUsers}
        usersCount={usersResponse?.count || 0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setTableSearchQuery={setTableSearchQuery}
      />
    </ErrorBoundary>
  );
};
