import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { ExpandedServerIdContextController } from 'context/expandedServer/expandedServerIdContextController/ExpandedServerIdContextController';

import { SequencesBar } from './sequencesBar/SequencesBar';
import { SequencesListContainer } from './sequencesList/SequencesListContainer';
import { AddSequenceContainer } from './sequencesBar/addSequence/AddSequenceContainer';
import { SequencesHistoryContainer } from './sequencesHistory/SequencesHistoryContainer';

export const Sequences = () => {
  const { formatMessage } = useLocale();
  const [sequencesTableSearchQuery, setSequencesTableSearchQuery] = useState('');
  const location = useLocation();
  const isListView = useIsExactPath(AppRoute.sequences);

  const currentPage = {
    [AppRoute.sequences]: <SequencesListContainer sequencesTableSearchQuery={sequencesTableSearchQuery} />,
    [AppRoute.sequencesHistory]: <SequencesHistoryContainer />,
  }[location.pathname];

  const addComponent = {
    [AppRoute.sequences]: <AddSequenceContainer />,
    [AppRoute.sequencesHistory]: null,
  }[location.pathname];

  return (
    <ErrorBoundary>
      <ExpandedServerIdContextController>
        <PageLayout
          header={formatMessage({ id: AppMessages['sequences.title'] })}
          renderContent={() => (
            <>
              <SequencesBar
                searchQueryKey={SearchQueryKeysEnum.WANTED}
                setTableSearchQuery={isListView ? setSequencesTableSearchQuery : undefined}
                addComponent={addComponent}
              />

              {currentPage}
            </>
          )}
        />
      </ExpandedServerIdContextController>
    </ErrorBoundary>
  );
};
