import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum, SchedulesQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';
import { fetchSchedules } from 'api/schedules/schedules';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { SchedulesTable } from './SchedulesTable';
import { SchedulesTableContainerProps } from './SchedulesTable.types';

export const SchedulesTableContainer = ({
  expandedServer,
  setSchedulesCount,
  schedulesTableSearchQuery,
}: SchedulesTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const { setExpandedServerId } = useExpandedServerId();

  const { page, rowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULES,
  });

  const { data: schedulesResponse } = useQuery(
    [QueryKeyEnum.SCHEDULES, serverId, storedQueryWithoutPagination, schedulesTableSearchQuery, page, rowsPerPage],
    () => fetchSchedules(serverId),
    {
      args: {
        ...storedQueryWithoutPagination,
        [SchedulesQueryKeysEnum.QUERY]: schedulesTableSearchQuery,
        [SchedulesQueryKeysEnum.LIMIT]: rowsPerPage,
        [SchedulesQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setSchedulesCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return <SchedulesTable schedulesResponse={schedulesResponse} schedulesTableSearchQuery={schedulesTableSearchQuery} />;
};
