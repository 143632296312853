import { Option } from 'shared-ui';

import { CardNumberSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { searchCard } from 'api/cards/cards';
import { useQuery } from 'hooks/useQuery/useQuery';
import * as Styled from '../TableSearch.styles';

export const useSearchByCards = (optionsQuery: string, isEnabled: boolean) => {
  const { data: cardsResponse, isInitialLoading } = useQuery([QueryKeyEnum.CARD_SEARCH, optionsQuery], searchCard, {
    args: { query: optionsQuery, offset: 0, limit: 100, sort: [CardNumberSortEnum.ASC] },
    enabled: isEnabled,
  });
  const cards = cardsResponse?.data || [];

  const createOptions = () => {
    return cards.map((card) => ({
      label: card.numerKarty,
      value: card.numerKarty,
    }));
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return {
    isLoading: isInitialLoading,
    createOptions,
    createOptionLabel,
  };
};
