import { useState, Fragment, useLayoutEffect } from 'react';
import { IconButton, List, Typography } from '@mui/material';
import { NothingToShow } from 'shared-ui';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { Loader } from 'ui/loader/Loader';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { ExpandableListProps } from './ExpandableList.types';
import * as Styled from './ExpandableList.styles';

export const ExpandableList = <T extends { id: string }>({
  data,
  title,
  renderExpandableItemBody,
  nothingToShowText,
  isLoading,
  selectedItem,
  toggleSelectedItem,
  addonEnd,
  toggleItemExpansion,
  initiallyExpandedItem,
  onClickExpandButton,
}: ExpandableListProps<T>) => {
  const { formatMessage } = useLocale();
  const [expandedItem, setExpandedItem] = useState<T | undefined>(undefined);

  useLayoutEffect(() => {
    if (!initiallyExpandedItem) return;

    const itemToExpand = expandedItem ? expandedItem : initiallyExpandedItem;

    setExpandedItem(itemToExpand);

    if (!toggleItemExpansion) return;
    toggleItemExpansion(itemToExpand);
  }, [!!initiallyExpandedItem]);

  const onClickExpandArrowButton = (itemData: T) => {
    const itemToExpand = expandedItem && expandedItem.id === itemData.id ? undefined : itemData;

    setExpandedItem(itemToExpand);

    if (toggleItemExpansion) {
      toggleItemExpansion(itemToExpand);
    }

    if (onClickExpandButton) {
      onClickExpandButton(itemToExpand);
    }
  };

  const onClickListItem = (itemData: T) => {
    if (!toggleSelectedItem) return;

    toggleSelectedItem(selectedItem && selectedItem.id === itemData.id ? undefined : itemData);
  };

  if (isLoading) {
    return <Loader />;
  } else if (!data.length) {
    return (
      <NothingToShow
        text={nothingToShowText || formatMessage({ id: AppMessages['common.noRecords'] })}
        data-testid="expandable-table-nothing-to-show-label"
      />
    );
  } else {
    return (
      <List>
        {data.map((itemData) => {
          const isExpanded = expandedItem?.id === itemData.id;
          const isActive = selectedItem?.id === itemData.id;

          return (
            <Fragment key={itemData.id}>
              <Styled.ExpandableListItem>
                <IconButton onClick={() => onClickExpandArrowButton(itemData)} size="large">
                  {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>

                <Styled.ExpandableListItemClickableArea
                  isActive={isActive}
                  isClickable={!!toggleSelectedItem || !!selectedItem}
                  onClick={() => onClickListItem(itemData)}
                >
                  {typeof title(itemData) === 'string' ? (
                    <Typography variant="h6">{title(itemData)}</Typography>
                  ) : (
                    title(itemData)
                  )}
                </Styled.ExpandableListItemClickableArea>

                {addonEnd && <>{addonEnd(itemData)}</>}
              </Styled.ExpandableListItem>

              <Styled.Collapse in={isExpanded} timeout={{ exit: 0, appear: 400, enter: 400 }}>
                {renderExpandableItemBody(itemData, expandedItem)}
              </Styled.Collapse>
            </Fragment>
          );
        })}
      </List>
    );
  }
};
