import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, RandomControlQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchControl } from 'api/control/control';
import { ControlTypeEnum } from 'api/control/control.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { RandomControl } from './RandomControl';
import { RandomControlContainerProps } from './RandomControl.types';

export const RandomControlContainer = ({ randomControlTableSearchQuery }: RandomControlContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.RANDOM_CONTROL]: randomControlQuery },
  } = useLocaleStoreQuery();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, offset, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.RANDOM_CONTROL,
      resetPaginationDependencyArray: [randomControlTableSearchQuery],
    });

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.RANDOM_CONTROL_OWNERS, randomControlQuery, page, rowsPerPage, randomControlTableSearchQuery],
    fetchControl,
    {
      args: {
        ...storedQueryWithoutPagination,
        [RandomControlQueryKeysEnum.LIMIT]: rowsPerPage,
        [RandomControlQueryKeysEnum.OFFSET]: offset,
        [RandomControlQueryKeysEnum.QUERY]: randomControlTableSearchQuery,
        [RandomControlQueryKeysEnum.TYP]: ControlTypeEnum.LOSOWA,
      },
      onSuccess: (res) => {
        setPaginationCount(res.count);
      },
    },
  );

  return (
    <RandomControl
      ownersResponse={ownersResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      randomControlQuery={randomControlQuery}
    />
  );
};
