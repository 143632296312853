import { DateRange } from '@mui/lab';
import { formatISO } from 'date-fns';
import { Select } from 'shared-ui';
import { PaginationSelectIcon, CloseGrayIcon } from 'shared-ui';

import { EventsQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { EventEnum, EventModeEnum, EventModEnum } from 'api/events/events.enum';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';

import { EventsFiltersProps } from './EventsFilters.types';
import * as Styled from './EventsFilters.styles';

export const EventsFilters = ({
  servers,
  setTableSearchQuery,
  tableSearchQuery,
  setArchivedSearchSelectedValue,
  archivedSearchSelectedValue,
  tkdSearchQuery,
  setTkdSearchQuery,
}: EventsFiltersProps) => {
  const { formatMessage } = useLocale();
  const {
    setQuery: setEventsHistoryQuery,
    storedQueries: { [QueryKeyLocalStorageEnum.EVENTS_HISTORY]: eventsHistoryQuery },
  } = useLocaleStoreQuery();
  const { eventOptions, modOptions, modeOptions, yesNoOptions, serverOptions } = useAppSelectOptions({ servers });

  const handleEventsHistoryQueryChange = (eventHistoryQuery: typeof eventsHistoryQuery) => {
    setEventsHistoryQuery(QueryKeyLocalStorageEnum.EVENTS_HISTORY, eventHistoryQuery);
  };

  const isClearFiltersButtonVisible =
    Object.values(filterQueryFromPagination(eventsHistoryQuery, ['sort'])).some((query) => query !== undefined) ||
    tableSearchQuery;

  const handleSetDates = (dates: DateRange<Date>) => {
    setEventsHistoryQuery(QueryKeyLocalStorageEnum.EVENTS_HISTORY, {
      ...eventsHistoryQuery,
      dataOd: dates[0] ? formatISO(dates[0]) : undefined,
      dataDo: dates[1] ? formatISO(dates[1]) : undefined,
    });
  };

  const onClearCardHistoryQuery = () => {
    handleEventsHistoryQueryChange({
      page: eventsHistoryQuery.page,
      rowsPerPage: eventsHistoryQuery.rowsPerPage,
    });
    setTableSearchQuery('');
  };

  const createSelectIcons = (props: Record<string, unknown>, queryKey: EventsQueryKeysEnum) =>
    eventsHistoryQuery[queryKey] !== undefined ? (
      <Styled.RemoveIconWrapper
        {...props}
        onClick={() =>
          handleEventsHistoryQueryChange({
            ...eventsHistoryQuery,
            [queryKey]: undefined,
            [EventsQueryKeysEnum.CZYTNIK]: queryKey === EventsQueryKeysEnum.SERVER ? undefined : eventsHistoryQuery.tkd,
          })
        }
      >
        <CloseGrayIcon />
      </Styled.RemoveIconWrapper>
    ) : (
      <Styled.SelectIconWrapper {...props}>
        <PaginationSelectIcon />
      </Styled.SelectIconWrapper>
    );

  return (
    <Styled.FiltersWrapper>
      <Styled.Filters>
        <HistoryDatepickerWithModal
          dates={[
            eventsHistoryQuery.dataOd ? new Date(eventsHistoryQuery.dataOd) : null,
            eventsHistoryQuery.dataDo ? new Date(eventsHistoryQuery.dataDo) : null,
          ]}
          setDates={handleSetDates}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.ZDARZENIE]: e.target.value as EventEnum,
              [EventsQueryKeysEnum.CZYTNIK]: e.target.value === EventEnum.MANUALNY ? undefined : eventsHistoryQuery.tkd,
              [EventsQueryKeysEnum.SERVER]:
                e.target.value === EventEnum.MANUALNY ? undefined : eventsHistoryQuery.serwer,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.ZDARZENIE] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.ZDARZENIE)}
          placeholder={formatMessage({ id: AppMessages['events.filters.event.placeholder'] })}
          options={eventOptions}
        />

        {!!servers.length && (
          <Select
            fullWidth
            variant="outlined"
            onChange={(e) =>
              handleEventsHistoryQueryChange({
                ...eventsHistoryQuery,
                [EventsQueryKeysEnum.SERVER]: e.target.value as string,
              })
            }
            value={eventsHistoryQuery[EventsQueryKeysEnum.SERVER] || ''}
            IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.SERVER)}
            placeholder={formatMessage({ id: AppMessages['events.filters.server.placeholder'] })}
            options={serverOptions}
            disabled={eventsHistoryQuery.zdarzenie === EventEnum.MANUALNY}
          />
        )}

        {!!servers.length && (
          <TableSearchContainer
            withoutToggle
            inputPlaceholder={formatMessage({ id: AppMessages['announcements.history.filters.reader'] })}
            searchBy="terminals"
            tableSearchQuery={tkdSearchQuery}
            setTableSearchQuery={setTkdSearchQuery}
            selectedValue={eventsHistoryQuery[EventsQueryKeysEnum.CZYTNIK]}
            setSelectedValue={(option) =>
              handleEventsHistoryQueryChange({
                ...eventsHistoryQuery,
                [EventsQueryKeysEnum.CZYTNIK]: option || undefined,
              })
            }
            inputSize="big"
            isDisabled={!eventsHistoryQuery.serwer}
            serverId={eventsHistoryQuery.serwer}
          />
        )}

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.MOD]: e.target.value as EventModEnum,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.MOD] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.MOD)}
          placeholder={formatMessage({ id: AppMessages['events.filters.mod.placeholder'] })}
          options={modOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.TRYB]: e.target.value as EventModeEnum,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.TRYB] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.TRYB)}
          placeholder={formatMessage({ id: AppMessages['events.filters.mode.placeholder'] })}
          options={modeOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.RCP]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.RCP])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.RCP)}
          placeholder={formatMessage({ id: AppMessages['events.filters.rcp.placeholder'] })}
          options={yesNoOptions}
        />

        <TableSearchContainer
          withoutToggle
          inputSize="big"
          searchBy="cardsAndOwners"
          tableSearchQuery={tableSearchQuery}
          setTableSearchQuery={setTableSearchQuery}
          inputPlaceholder={formatMessage({ id: AppMessages['events.filters.search.placeholder'] })}
          selectedValue={archivedSearchSelectedValue}
          setSelectedValue={setArchivedSearchSelectedValue}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.POSZUKIWANY]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.POSZUKIWANY])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.POSZUKIWANY)}
          placeholder={formatMessage({ id: AppMessages['events.filters.wanted.placeholder'] })}
          options={yesNoOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.REWIZJA]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.REWIZJA])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.REWIZJA)}
          placeholder={formatMessage({ id: AppMessages['events.filters.control.placeholder'] })}
          options={yesNoOptions}
        />
      </Styled.Filters>

      {isClearFiltersButtonVisible && (
        <Styled.ClearFiltersWrapper>
          <Styled.ClearFiltersButton onClick={onClearCardHistoryQuery}>
            {formatMessage({ id: AppMessages['common.clear'] })}
            <Styled.ClearFiltersIcon />
          </Styled.ClearFiltersButton>
        </Styled.ClearFiltersWrapper>
      )}
    </Styled.FiltersWrapper>
  );
};
