import { ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';
import { SequenceTypeDescriptionEnum, SequenceTypeEnum } from 'api/sequences/sequences.enum';
import {
  QueryKeyLocalStorageEnum,
  SequencesCardASortEnum,
  SequencesCardBSortEnum,
  SequencesIdSortEnum,
  SequencesQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

import { SequencesTableHeaderProps } from './SequencesTableHeader.types';
import * as Styled from './SequencesTableHeader.styles';

export const SequencesTableHeader = ({ terminals }: SequencesTableHeaderProps) => {
  const { formatMessage } = useLocale();

  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];

  const typeFilterOptions = Object.values(SequenceTypeEnum).map((option) => ({
    name: formatMessage({ id: SequenceTypeDescriptionEnum[option] }),
    filterBy: option,
  }));

  const terminalsFilterOptions = terminals.map((terminal) => ({
    name: (
      <>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['common.id'] })} {terminal.numerLogicznyKontrolera}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{terminal.nazwa}</Styled.OptionLabel>
      </>
    ),
    filterBy: `${terminal.numerLogicznyKontrolera}`,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        sortBy={SequencesIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.id'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        queryName={SequencesQueryKeysEnum.NAZWA_CZYTNIKA}
        filterItems={terminalsFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.name'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        queryName={SequencesQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        queryName={SequencesQueryKeysEnum.TYP}
        filterItems={typeFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.type'] })}
        queryHeaderDescriptions={SequenceTypeDescriptionEnum}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        sortBy={SequencesCardASortEnum}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.cardA'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.SEQUENCES}
        sortBy={SequencesCardBSortEnum}
        queryHeader={formatMessage({ id: AppMessages['sequences.filters.cardB'] })}
      />
    </>
  );
};
