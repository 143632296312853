import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { SequencesHistoryQuery } from 'context/query/queryContext/QueryContext.types';
import { SequenceTypeEnum } from 'api/sequences/sequences.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { SequencesHistoryFiltersProps } from './SequencesHistoryFilters.types';
import * as Styled from './SequencesHistoryFilters.styles';

export const SequencesHistoryFilters = ({
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  cardSearchQuery,
  setCardSearchQuery,
}: SequencesHistoryFiltersProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SEQUENCES_HISTORY]: sequencesHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setSequenceHistoryQuery = (query: SequencesHistoryQuery) => {
    setQuery(QueryKeyLocalStorageEnum.SEQUENCES_HISTORY, { ...sequencesHistoryQuery, ...query });
  };

  const { serverOptions, sequenceHistoryTypeOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <HistoryDatepickerWithModal
        dates={[
          sequencesHistoryQuery.dataOd ? new Date(sequencesHistoryQuery.dataOd) : null,
          sequencesHistoryQuery.dataDo ? new Date(sequencesHistoryQuery.dataDo) : null,
        ]}
        setDates={(dates) =>
          setSequenceHistoryQuery({
            dataOd: dates[0] ? formatISO(dates[0]) : undefined,
            dataDo: dates[1] ? formatISO(dates[1]) : undefined,
          })
        }
      />

      <HistoryActionAutocomplete
        selectedAction={sequencesHistoryQuery.akcja}
        onChangeAction={(action) => setSequenceHistoryQuery({ akcja: action })}
        historyType="sequences"
      />

      <Autocomplete
        onChange={(_, option) => setSequenceHistoryQuery({ serwer: option || undefined, tkd: undefined })}
        value={serverOptions.find((server) => sequencesHistoryQuery.serwer?.value === server.value) || null}
        options={serverOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['sequences.history.filters.server'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['sequences.history.filters.reader'] })}
        searchBy="terminals"
        tableSearchQuery={tkdSearchQuery}
        setTableSearchQuery={setTkdSearchQuery}
        selectedValue={sequencesHistoryQuery.tkd}
        setSelectedValue={(option) => setSequenceHistoryQuery({ tkd: option || undefined })}
        inputSize="big"
        isDisabled={!sequencesHistoryQuery.serwer}
        serverId={sequencesHistoryQuery.serwer?.value as string}
      />

      <Autocomplete
        onChange={(_, option) => setSequenceHistoryQuery({ typ: option?.value as SequenceTypeEnum })}
        value={sequenceHistoryTypeOptions.find((type) => sequencesHistoryQuery.typ === type.value) || null}
        options={sequenceHistoryTypeOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['sequences.history.filters.type'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label.toLowerCase()}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['sequences.history.filters.cardNumber'] })}
        searchBy="cards"
        tableSearchQuery={cardSearchQuery}
        setTableSearchQuery={setCardSearchQuery}
        selectedValue={sequencesHistoryQuery.numerKarty}
        setSelectedValue={(option) => {
          setSequenceHistoryQuery({
            numerKarty: option ? { ...option, value: option.value } : undefined,
          });
        }}
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['sequences.history.filters.executor'] })}
        searchBy="users"
        tableSearchQuery={executorSearchQuery}
        setTableSearchQuery={setExecutorSearchQuery}
        selectedValue={sequencesHistoryQuery.query}
        setSelectedValue={(option) => setSequenceHistoryQuery({ query: option || undefined })}
        inputSize="big"
      />
    </>
  );
};
