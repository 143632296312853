import { Option } from 'shared-ui';

import { CardBlockTypeEnum, CardStatusEnum, CardTypeEnum } from 'api/cards/cards.enum';
import { EventEnum, EventModeEnum, EventModEnum } from 'api/events/events.enum';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { Position } from 'api/positions/positions.types';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { TerminalStatusEnum } from 'api/terminals/terminals.enum';
import { UserStatusEnum, UserTypeEnum } from 'api/users/users.enum';
import { HistoryActionEnum } from 'core/global.enum';
import { Glc } from 'api/groups/groups.types';
import { ControlTypeEnum } from 'api/control/control.enum';
import { ScheduleHistoryTypeFilterEnum } from 'api/schedules/schedules.enum';
import { SequenceTypeEnum } from 'api/sequences/sequences.enum';

import {
  CardNumberSortEnum,
  CardsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
  TerminalsQueryKeysEnum,
  TerminalGlcIdSortEnum,
  CardHistoryQueryKeysEnum,
  EventsQueryKeysEnum,
  IdTkdSortEnum,
  OwnerSortEnum,
  EventDateSortEnum,
  OwnerIdSortEnum,
  OwnerRegistrationNumberSortEnum,
  OwnerNameSortEnum,
  OwnersQueryKeysEnum,
  CardsHistorySearchByEnum,
  TerminalIdSortEnum,
  OwnerPermissionsQueryKeysEnum,
  OwnerPermissionsIdSortEnum,
  OwnerPermissionsReaderNameSortEnum,
  OwnerGroupNameSortEnum,
  OwnerGroupsQueryKeysEnum,
  PositionQueryKeysEnum,
  PositionNameSortEnum,
  EventExportFormatQueryKeysEnum,
  EventExportFormatNameSortEnum,
  GroupsQueryKeysEnum,
  GroupsIdSortEnum,
  GroupsNameSortEnum,
  GroupTerminalsPermissionsQueryKeysEnum,
  GroupTerminalsPermissionsIdSortEnum,
  GroupTerminalsPermissionsReaderNameSortEnum,
  GroupGlcPermissionsQueryKeysEnum,
  GroupGlcPermissionsIdSortEnum,
  GroupGlcPermissionsGlcNameSortEnum,
  GroupAssignedOwnersQueryKeysEnum,
  UsersQueryKeysEnum,
  UsersFullNameSortEnum,
  UsersUsernameSortEnum,
  CardOwnerSortEnum,
  OwnerHistoryQueryKeysEnum,
  OwnerHistorySearchByEnum,
  SearchQueryKeysEnum,
  GroupHistoryQueryKeysEnum,
  GlcQueryKeysEnum,
  GlcNumberSortEnum,
  GlcNameSortEnum,
  GlcHistoryQueryKeysEnum,
  GlcTerminalsIdSortEnum,
  GlcTerminalsNameSortEnum,
  OwnerGroupIdSortEnum,
  GroupPermissionsHistoryQueryKeysEnum,
  OwnerEffectivePermissionsHistoryQueryKeysEnum,
  TerminalsWithDisplayQueryKeysEnum,
  TerminalWithDisplayIdSortEnum,
  ControlHistoryQueryKeysEnum,
  InstantControlQueryKeysEnum,
  InstantControlIdSortEnum,
  InstantControlOwnerRegistrationNumberSortEnum,
  InstantControlOwnerNameSortEnum,
  InstantControlTerminalsQueryKeysEnum,
  InstantControlTerminalsIdSortEnum,
  InstantControlTerminalsNameSortEnum,
  RandomControlQueryKeysEnum,
  RandomControlIdSortEnum,
  RandomControlOwnerRegistrationNumberSortEnum,
  RandomControlOwnerNameSortEnum,
  RandomControlTerminalsQueryKeysEnum,
  RandomControlTerminalsIdSortEnum,
  RandomControlTerminalsNameSortEnum,
  WantedQueryKeysEnum,
  WantedIdSortEnum,
  WantedOwnerNameSortEnum,
  WantedOwnerRegistrationNumberSortEnum,
  WantedHistoryQueryKeysEnum,
  WantedTerminalsQueryKeysEnum,
  WantedTerminalsIdSortEnum,
  WantedTerminalsNameSortEnum,
  AnnouncementsQueryKeysEnum,
  AnnouncementsIdSortEnum,
  AnnouncementsNameSortEnum,
  AnnouncementTerminalNameSortEnum,
  AnnouncementsHistoryQueryKeysEnum,
  AnnouncementAssignedOwnersQueryKeysEnum,
  SchedulesQueryKeysEnum,
  SchedulesNameSortEnum,
  SchedulesExceptionsQueryKeysEnum,
  SchedulesExceptionsDateSortEnum,
  SchedulesHistoryQueryKeysEnum,
  SequencesQueryKeysEnum,
  SequencesIdSortEnum,
  SequencesCardASortEnum,
  SequencesCardBSortEnum,
  PaginationQueryKeysEnum,
  TerminalWithSequenceQueryKeysEnum,
  TerminalWithSequenceIdSortEnum,
  TerminalWithSequenceNameSortEnum,
  SequencesHistoryQueryKeysEnum,
} from './QueryContext.enum';

export type QueryState = {
  [QueryKeyLocalStorageEnum.CARDS]: CardsQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_CARDS]: CardsQuery;
  [QueryKeyLocalStorageEnum.TERMINALS]: TerminalsQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_CARD_HISTORY]: CardHistoryQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_CARD_HISTORY]: CardHistoryQuery;
  [QueryKeyLocalStorageEnum.EVENTS_HISTORY]: EventsHistoryQuery;
  [QueryKeyLocalStorageEnum.OWNERS]: OwnersQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNERS]: OwnersQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY]: OwnerHistoryQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_EFFECTIVE]: OwnerPermissionsQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_INDIVIDUAL]: OwnerPermissionsQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_GROUPS]: OwnerGroupsQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_HISTORY]: OwnerHistoryQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_EFFECTIVE]: OwnerPermissionsQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_INDIVIDUAL]: OwnerPermissionsQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_GROUPS]: OwnerGroupsQuery;
  [QueryKeyLocalStorageEnum.POSITIONS]: PositionsQuery;
  [QueryKeyLocalStorageEnum.EVENTS_EXPORT_FORMAT]: EventExportFormatQuery;
  [QueryKeyLocalStorageEnum.GROUPS]: GroupsQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_GROUPS]: GroupsQuery;
  [QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS]: GroupTerminalsPermissionsQuery;
  [QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS]: GroupGlcPermissionsQuery;
  [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: GroupAssignedOwnersQuery;
  [QueryKeyLocalStorageEnum.ACTIVE_GLC]: GlcQuery;
  [QueryKeyLocalStorageEnum.ARCHIVED_GLC]: GlcQuery;
  [QueryKeyLocalStorageEnum.GLC_TERMINALS]: GlcTerminalsQuery;
  [QueryKeyLocalStorageEnum.USERS]: UsersQuery;
  [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: SelectedSearchOptionsQuery;
  [QueryKeyLocalStorageEnum.GROUP_HISTORY]: GroupHistoryQuery;
  [QueryKeyLocalStorageEnum.GROUP_PERMISSIONS_HISTORY]: GroupPermissionsHistoryQuery;
  [QueryKeyLocalStorageEnum.GLC_HISTORY]: GlcHistoryQuery;
  [QueryKeyLocalStorageEnum.OWNER_EFFECTIVE_PERMISSIONS_HISTORY]: OwnerEffectivePermissionsHistoryQuery;
  [QueryKeyLocalStorageEnum.TERMINALS_WITH_DISPLAY]: TerminalsWithDisplayQuery;
  [QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE]: TerminalsWithSequenceQuery;
  [QueryKeyLocalStorageEnum.CONTROL_HISTORY]: ControlHistoryQuery;
  [QueryKeyLocalStorageEnum.INSTANT_CONTROL]: InstantControlQuery;
  [QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS]: InstantControlServersQuery;
  [QueryKeyLocalStorageEnum.RANDOM_CONTROL]: RandomControlQuery;
  [QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS]: RandomControlServersQuery;
  [QueryKeyLocalStorageEnum.WANTED]: WantedQuery;
  [QueryKeyLocalStorageEnum.WANTED_HISTORY]: WantedHistoryQuery;
  [QueryKeyLocalStorageEnum.WANTED_TERMINALS]: WantedServersQuery;
  [QueryKeyLocalStorageEnum.ANNOUNCEMENTS]: AnnouncementsQuery;
  [QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY]: AnnouncementsHistoryQuery;
  [QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]: AnnouncementAssignedOwnersQuery;
  [QueryKeyLocalStorageEnum.SCHEDULES]: SchedulesQuery;
  [QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS]: SchedulesExceptionsQuery;
  [QueryKeyLocalStorageEnum.SCHEDULES_HISTORY]: SchedulesHistoryQuery;
  [QueryKeyLocalStorageEnum.SCHEDULE_TERMINALS]: SchedulesServersQuery;
  [QueryKeyLocalStorageEnum.SCHEDULE_EXCEPTION_TERMINALS]: ScheduleExceptionTerminalsQuery;
  [QueryKeyLocalStorageEnum.SEQUENCES]: SequencesQuery;
  [QueryKeyLocalStorageEnum.SEQUENCES_HISTORY]: SequencesHistoryQuery;
};

export const IS_SORT_DIRTY = 'isSortDirty';

export type PaginationQuery = {
  [PaginationQueryKeysEnum.PAGE]?: number;
  [PaginationQueryKeysEnum.ROWS_PER_PAGE]?: number;
};

export type CardSortValue = CardNumberSortEnum | CardOwnerSortEnum;

export type CardsQuery = {
  [CardsQueryKeysEnum.OFFSET]?: number;
  [CardsQueryKeysEnum.LIMIT]?: number;
  [CardsQueryKeysEnum.TYPE]?: CardTypeEnum;
  [CardsQueryKeysEnum.STATUS]?: CardStatusEnum;
  [CardsQueryKeysEnum.BLOCKADE]?: CardBlockTypeEnum;
  [CardsQueryKeysEnum.SORT]?: CardSortValue[];
  [CardsQueryKeysEnum.ARCHIVED]?: boolean;
  [CardsQueryKeysEnum.ID]?: string;
  [CardsQueryKeysEnum.QUERY]?: string;
  [CardsQueryKeysEnum.PROBLEM_TKD]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type TerminalsSortValue = TerminalIdSortEnum | TerminalGlcIdSortEnum;

export type TerminalsQuery = {
  [TerminalsQueryKeysEnum.STATUS]?: TerminalStatusEnum;
  [TerminalsQueryKeysEnum.LIMIT]?: number;
  [TerminalsQueryKeysEnum.OFFSET]?: number;
  [TerminalsQueryKeysEnum.SORT]?: TerminalsSortValue[];
  [TerminalsQueryKeysEnum.GLC_IDS]?: Glc[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type CardHistoryQuery = {
  [CardHistoryQueryKeysEnum.LIMIT]?: number;
  [CardHistoryQueryKeysEnum.OFFSET]?: number;
  [CardHistoryQueryKeysEnum.DATA_OD]?: string;
  [CardHistoryQueryKeysEnum.DATA_DO]?: string;
  [CardHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [CardHistoryQueryKeysEnum.UZYTKOWNIK]?: string;
  [CardHistoryQueryKeysEnum.BY]?: CardsHistorySearchByEnum;
  [CardHistoryQueryKeysEnum.QUERY]?: string;
} & PaginationQuery;

export type EventsSortValue = IdTkdSortEnum | OwnerSortEnum | EventDateSortEnum | CardNumberSortEnum;

export type EventsHistoryQuery = {
  [EventsQueryKeysEnum.LIMIT]?: number;
  [EventsQueryKeysEnum.OFFSET]?: number;
  [EventsQueryKeysEnum.SORT]?: EventsSortValue[];
  [EventsQueryKeysEnum.ZDARZENIE]?: EventEnum;
  [EventsQueryKeysEnum.MOD]?: EventModEnum;
  [EventsQueryKeysEnum.TRYB]?: EventModeEnum;
  [EventsQueryKeysEnum.RCP]?: boolean;
  [EventsQueryKeysEnum.POSZUKIWANY]?: boolean;
  [EventsQueryKeysEnum.REWIZJA]?: boolean;
  [EventsQueryKeysEnum.SERVER]?: string;
  [CardHistoryQueryKeysEnum.DATA_OD]?: string;
  [CardHistoryQueryKeysEnum.DATA_DO]?: string;
  [EventsQueryKeysEnum.QUERY]?: string;
  [EventsQueryKeysEnum.CZYTNIK]?: Option;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type OwnersSortValue = OwnerIdSortEnum | OwnerRegistrationNumberSortEnum | OwnerNameSortEnum;

export type OwnersQuery = {
  [OwnersQueryKeysEnum.LIMIT]?: number;
  [OwnersQueryKeysEnum.OFFSET]?: number;
  [OwnersQueryKeysEnum.QUERY]?: string;
  [OwnersQueryKeysEnum.SORT]?: OwnersSortValue[];
  [OwnersQueryKeysEnum.KOMORKA]?: string[];
  [OwnersQueryKeysEnum.STANOWISKO]?: Position[];
  [OwnersQueryKeysEnum.KARTA]?: boolean;
  [OwnersQueryKeysEnum.PAGE]?: number;
  [OwnersQueryKeysEnum.ROWS_PER_PAGE]?: number;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type OwnerPermissionsSortValue = OwnerPermissionsIdSortEnum | OwnerPermissionsReaderNameSortEnum;

export type OwnerPermissionsQuery = {
  [OwnerPermissionsQueryKeysEnum.LIMIT]?: number;
  [OwnerPermissionsQueryKeysEnum.OFFSET]?: number;
  [OwnerPermissionsQueryKeysEnum.SORT]?: OwnerPermissionsSortValue[];
  [OwnerPermissionsQueryKeysEnum.REJESTRUJ_ODBICIE]?: PermissionEnum;
  [OwnerPermissionsQueryKeysEnum.OTWIERAJ_ZAMEK]?: PermissionEnum;
  [OwnerPermissionsQueryKeysEnum.REJESTRUJ_RCP]?: PermissionEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type OwnerGroupsSortValue = OwnerGroupNameSortEnum | OwnerGroupIdSortEnum;

export type OwnerGroupsQuery = {
  [OwnerGroupsQueryKeysEnum.LIMIT]?: number;
  [OwnerGroupsQueryKeysEnum.OFFSET]?: number;
  [OwnerGroupsQueryKeysEnum.SORT]?: OwnerGroupsSortValue[];
  [OwnerGroupsQueryKeysEnum.RODZAJ_GRUPY]?: GroupTypeEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type OwnerHistoryQuery = {
  [OwnerHistoryQueryKeysEnum.LIMIT]?: number;
  [OwnerHistoryQueryKeysEnum.OFFSET]?: number;
  [OwnerHistoryQueryKeysEnum.DATA_OD]?: string;
  [OwnerHistoryQueryKeysEnum.DATA_DO]?: string;
  [OwnerHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [OwnerHistoryQueryKeysEnum.UZYTKOWNIK]?: string;
  [OwnerHistoryQueryKeysEnum.BY]?: OwnerHistorySearchByEnum;
  [OwnerHistoryQueryKeysEnum.QUERY]?: string;
} & PaginationQuery;

export type OwnerEffectivePermissionsHistoryQuery = {
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.LIMIT]?: number;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.OFFSET]?: number;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.DATA_OD]?: string;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.DATA_DO]?: string;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.SERWER]?: Option;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [OwnerEffectivePermissionsHistoryQueryKeysEnum.WYKONAWCA]?: Option;
} & PaginationQuery;

export type PositionSortValue = PositionNameSortEnum;

export type PositionsQuery = {
  [PositionQueryKeysEnum.SORT]?: PositionSortValue[];
  [IS_SORT_DIRTY]?: boolean;
};

export type EventExportFormatSortValue = EventExportFormatNameSortEnum;

export type EventExportFormatQuery = {
  [EventExportFormatQueryKeysEnum.SORT]?: EventExportFormatSortValue[];
  [IS_SORT_DIRTY]?: boolean;
};

export type GroupsSortValue = GroupsIdSortEnum | GroupsNameSortEnum;

export type GroupsQuery = {
  [GroupsQueryKeysEnum.LIMIT]?: number;
  [GroupsQueryKeysEnum.OFFSET]?: number;
  [GroupsQueryKeysEnum.SORT]?: GroupsSortValue[];
  [OwnersQueryKeysEnum.KOMORKA]?: string[];
  [GroupsQueryKeysEnum.RODZAJ_GRUPY]?: GroupTypeEnum;
  [GroupsQueryKeysEnum.QUERY]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type GroupTerminalsPermissionsSortValue =
  | GroupTerminalsPermissionsIdSortEnum
  | GroupTerminalsPermissionsReaderNameSortEnum;

export type GroupTerminalsPermissionsQuery = {
  [GroupTerminalsPermissionsQueryKeysEnum.LIMIT]?: number;
  [GroupTerminalsPermissionsQueryKeysEnum.OFFSET]?: number;
  [GroupTerminalsPermissionsQueryKeysEnum.SORT]?: GroupTerminalsPermissionsSortValue[];
  [GroupTerminalsPermissionsQueryKeysEnum.REJESTRUJ_ODBICIE]?: PermissionEnum;
  [GroupTerminalsPermissionsQueryKeysEnum.OTWIERAJ_ZAMEK]?: PermissionEnum;
  [GroupTerminalsPermissionsQueryKeysEnum.REJESTRUJ_RCP]?: PermissionEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type GroupGlcPermissionsSortValue = GroupGlcPermissionsIdSortEnum | GroupGlcPermissionsGlcNameSortEnum;

export type GroupGlcPermissionsQuery = {
  [GroupGlcPermissionsQueryKeysEnum.LIMIT]?: number;
  [GroupGlcPermissionsQueryKeysEnum.OFFSET]?: number;
  [GroupGlcPermissionsQueryKeysEnum.SORT]?: GroupGlcPermissionsSortValue[];
  [GroupGlcPermissionsQueryKeysEnum.REJESTRUJ_ODBICIE]?: PermissionEnum;
  [GroupGlcPermissionsQueryKeysEnum.OTWIERAJ_ZAMEK]?: PermissionEnum;
  [GroupGlcPermissionsQueryKeysEnum.REJESTRUJ_RCP]?: PermissionEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type GroupAssignedOwnersQuery = {
  [GroupAssignedOwnersQueryKeysEnum.LIMIT]?: number;
  [GroupAssignedOwnersQueryKeysEnum.OFFSET]?: number;
  [GroupAssignedOwnersQueryKeysEnum.QUERY]?: string;
  [GroupAssignedOwnersQueryKeysEnum.SORT]?: OwnersSortValue[];
  [GroupAssignedOwnersQueryKeysEnum.KOMORKA]?: string[];
  [GroupAssignedOwnersQueryKeysEnum.STANOWISKO]?: Position[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type UsersSortValue = UsersFullNameSortEnum | UsersUsernameSortEnum;

export type UsersQuery = {
  [UsersQueryKeysEnum.LIMIT]?: number;
  [UsersQueryKeysEnum.OFFSET]?: number;
  [UsersQueryKeysEnum.SORT]?: UsersSortValue[];
  [UsersQueryKeysEnum.TYP]?: UserTypeEnum;
  [UsersQueryKeysEnum.STATUS]?: UserStatusEnum;
  [UsersQueryKeysEnum.QUERY]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type GlcSortValue = GlcNumberSortEnum | GlcNameSortEnum;

export type GlcQuery = {
  [GlcQueryKeysEnum.LIMIT]?: number;
  [GlcQueryKeysEnum.OFFSET]?: number;
  [GlcQueryKeysEnum.SORT]?: GlcSortValue[];
  [GlcQueryKeysEnum.QUERY]?: string;
  [GlcQueryKeysEnum.WITHOUT_DIRECT_GROUP_PERMISSION]?: boolean;
  [GlcQueryKeysEnum.ARCHIVED]?: boolean;
  [GlcQueryKeysEnum.SERVER_ID]?: string;
  [GlcQueryKeysEnum.READER]?: boolean;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type GlcTerminalsSortValue = GlcTerminalsIdSortEnum | GlcTerminalsNameSortEnum;

export type GlcTerminalsQuery = {
  [GlcQueryKeysEnum.LIMIT]?: number;
  [GlcQueryKeysEnum.OFFSET]?: number;
  [GlcQueryKeysEnum.SORT]?: GlcTerminalsSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

type SearchOption = Option | null;

export type SelectedSearchOptionsQuery = {
  [SearchQueryKeysEnum.CARDS_ACTIVE]?: SearchOption;
  [SearchQueryKeysEnum.CARDS_ARCHIVED]?: SearchOption;
  [SearchQueryKeysEnum.ACTIVE_CARD_HISTORY]?: SearchOption;
  [SearchQueryKeysEnum.ARCHIVED_CARD_HISTORY]?: SearchOption;
  [SearchQueryKeysEnum.OWNERS_ACTIVE]?: SearchOption;
  [SearchQueryKeysEnum.OWNERS_ARCHIVED]?: SearchOption;
  [SearchQueryKeysEnum.ACTIVE_OWNER_HISTORY]?: SearchOption;
  [SearchQueryKeysEnum.ARCHIVED_OWNER_HISTORY]?: SearchOption;
  [SearchQueryKeysEnum.ACTIVE_OWNER_PERMISSIONS_GROUP]?: SearchOption;
  [SearchQueryKeysEnum.ARCHIVED_OWNER_PERMISSIONS_GROUP]?: SearchOption;
  [SearchQueryKeysEnum.GROUPS_ACTIVE]?: SearchOption;
  [SearchQueryKeysEnum.GROUPS_ARCHIVED]?: SearchOption;
  [SearchQueryKeysEnum.ACTIVE_GLC]?: SearchOption;
  [SearchQueryKeysEnum.ARCHIVED_GLC]?: SearchOption;
  [SearchQueryKeysEnum.CONTROL_INSTANT]?: SearchOption;
  [SearchQueryKeysEnum.CONTROL_RANDOM]?: SearchOption;
  [SearchQueryKeysEnum.WANTED]?: SearchOption;
};

export type GroupHistoryQuery = {
  [GroupHistoryQueryKeysEnum.LIMIT]?: number;
  [GroupHistoryQueryKeysEnum.OFFSET]?: number;
  [GroupHistoryQueryKeysEnum.DATA_OD]?: string;
  [GroupHistoryQueryKeysEnum.DATA_DO]?: string;
  [GroupHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [GroupHistoryQueryKeysEnum.KOMORKA]?: string;
  [GroupHistoryQueryKeysEnum.POSIADACZ]?: string;
  [GroupHistoryQueryKeysEnum.QUERY]?: Option;
} & PaginationQuery;

export type GroupPermissionsHistoryQuery = {
  [GroupPermissionsHistoryQueryKeysEnum.LIMIT]?: number;
  [GroupPermissionsHistoryQueryKeysEnum.OFFSET]?: number;
  [GroupPermissionsHistoryQueryKeysEnum.DATA_OD]?: string;
  [GroupPermissionsHistoryQueryKeysEnum.DATA_DO]?: string;
  [GroupPermissionsHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [GroupPermissionsHistoryQueryKeysEnum.SERWER]?: Option;
  [GroupPermissionsHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [GroupPermissionsHistoryQueryKeysEnum.GLC]?: Option;
  [GroupPermissionsHistoryQueryKeysEnum.WYKONAWCA]?: Option;
} & PaginationQuery;

export type GlcHistoryQuery = {
  [GlcHistoryQueryKeysEnum.LIMIT]?: number;
  [GlcHistoryQueryKeysEnum.OFFSET]?: number;
  [GlcHistoryQueryKeysEnum.DATA_OD]?: string;
  [GlcHistoryQueryKeysEnum.DATA_DO]?: string;
  [GlcHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [GlcHistoryQueryKeysEnum.CZYTNIK]?: string;
  [GlcHistoryQueryKeysEnum.QUERY]?: string;
} & PaginationQuery;

export type TerminalsWithDisplaySortValue = TerminalWithDisplayIdSortEnum;

export type TerminalsWithDisplayQuery = {
  [TerminalsWithDisplayQueryKeysEnum.LIMIT]?: number;
  [TerminalsWithDisplayQueryKeysEnum.OFFSET]?: number;
  [TerminalsWithDisplayQueryKeysEnum.SORT]?: TerminalsWithDisplaySortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type TerminalsWithSequenceSortValue = TerminalWithSequenceIdSortEnum | TerminalWithSequenceNameSortEnum;

export type TerminalsWithSequenceQuery = {
  [TerminalWithSequenceQueryKeysEnum.LIMIT]?: number;
  [TerminalWithSequenceQueryKeysEnum.OFFSET]?: number;
  [TerminalWithSequenceQueryKeysEnum.SORT]?: TerminalsWithSequenceSortValue[];
  [TerminalWithSequenceQueryKeysEnum.SEKWENCJA_TYP]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type ControlHistoryQuery = {
  [ControlHistoryQueryKeysEnum.LIMIT]?: number;
  [ControlHistoryQueryKeysEnum.OFFSET]?: number;
  [ControlHistoryQueryKeysEnum.DATA_OD]?: string;
  [ControlHistoryQueryKeysEnum.DATA_DO]?: string;
  [ControlHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [ControlHistoryQueryKeysEnum.TYP]?: ControlTypeEnum;
  [ControlHistoryQueryKeysEnum.SERWER]?: Option;
  [ControlHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [ControlHistoryQueryKeysEnum.QUERY]?: Option;
  [ControlHistoryQueryKeysEnum.POSIADACZ]?: Option;
} & PaginationQuery;

export type InstantControlSortValue =
  | InstantControlIdSortEnum
  | InstantControlOwnerRegistrationNumberSortEnum
  | InstantControlOwnerNameSortEnum;

export type InstantControlQuery = {
  [InstantControlQueryKeysEnum.LIMIT]?: number;
  [InstantControlQueryKeysEnum.OFFSET]?: number;
  [InstantControlQueryKeysEnum.QUERY]?: string;
  [InstantControlQueryKeysEnum.SORT]?: InstantControlSortValue[];
  [InstantControlQueryKeysEnum.KOMORKA]?: string[];
  [InstantControlQueryKeysEnum.PROBLEM_TKD]?: string;
  [InstantControlQueryKeysEnum.TYP]?: ControlTypeEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type InstantControlServersSortValue = InstantControlTerminalsIdSortEnum | InstantControlTerminalsNameSortEnum;

export type InstantControlServersQuery = {
  [InstantControlTerminalsQueryKeysEnum.LIMIT]?: number;
  [InstantControlTerminalsQueryKeysEnum.OFFSET]?: number;
  [InstantControlTerminalsQueryKeysEnum.SORT]?: InstantControlServersSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type RandomControlServersSortValue = RandomControlTerminalsIdSortEnum | RandomControlTerminalsNameSortEnum;

export type RandomControlServersQuery = {
  [RandomControlTerminalsQueryKeysEnum.LIMIT]?: number;
  [RandomControlTerminalsQueryKeysEnum.OFFSET]?: number;
  [RandomControlTerminalsQueryKeysEnum.SORT]?: RandomControlServersSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type RandomControlSortValue =
  | RandomControlIdSortEnum
  | RandomControlOwnerRegistrationNumberSortEnum
  | RandomControlOwnerNameSortEnum;

export type RandomControlQuery = {
  [RandomControlQueryKeysEnum.LIMIT]?: number;
  [RandomControlQueryKeysEnum.OFFSET]?: number;
  [RandomControlQueryKeysEnum.QUERY]?: string;
  [RandomControlQueryKeysEnum.SORT]?: RandomControlSortValue[];
  [RandomControlQueryKeysEnum.KOMORKA]?: string[];
  [RandomControlQueryKeysEnum.PROBLEM_TKD]?: string;
  [RandomControlQueryKeysEnum.TYP]?: ControlTypeEnum;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type WantedQuery = {
  [WantedQueryKeysEnum.LIMIT]?: number;
  [WantedQueryKeysEnum.OFFSET]?: number;
  [WantedQueryKeysEnum.QUERY]?: string;
  [WantedQueryKeysEnum.SORT]?: WantedSortValue[];
  [WantedQueryKeysEnum.KOMORKA]?: string[];
  [WantedQueryKeysEnum.PROBLEM_TKD]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type WantedSortValue = WantedIdSortEnum | WantedOwnerRegistrationNumberSortEnum | WantedOwnerNameSortEnum;

export type WantedHistoryQuery = {
  [WantedHistoryQueryKeysEnum.LIMIT]?: number;
  [WantedHistoryQueryKeysEnum.OFFSET]?: number;
  [WantedHistoryQueryKeysEnum.DATA_OD]?: string;
  [WantedHistoryQueryKeysEnum.DATA_DO]?: string;
  [WantedHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [WantedHistoryQueryKeysEnum.INFORMACJA]?: string;
  [WantedHistoryQueryKeysEnum.SERWER]?: Option;
  [WantedHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [WantedHistoryQueryKeysEnum.QUERY]?: Option;
  [WantedHistoryQueryKeysEnum.POSIADACZ]?: Option;
} & PaginationQuery;

export type WantedServersSortValue = WantedTerminalsIdSortEnum | WantedTerminalsNameSortEnum;

export type WantedServersQuery = {
  [WantedTerminalsQueryKeysEnum.LIMIT]?: number;
  [WantedTerminalsQueryKeysEnum.OFFSET]?: number;
  [WantedTerminalsQueryKeysEnum.SORT]?: WantedServersSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type AnnouncementsQuery = {
  [AnnouncementsQueryKeysEnum.LIMIT]?: number;
  [AnnouncementsQueryKeysEnum.OFFSET]?: number;
  [AnnouncementsQueryKeysEnum.QUERY]?: string;
  [AnnouncementsQueryKeysEnum.SORT]?: AnnouncementsSortValue[];
  [AnnouncementsQueryKeysEnum.TRYB]?: string[];
  [AnnouncementsQueryKeysEnum.PROBLEM_TKD]?: string;
  [AnnouncementsQueryKeysEnum.MOD]?: string;
  [AnnouncementsQueryKeysEnum.POSIADACZ]?: boolean;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type AnnouncementsSortValue =
  | AnnouncementsIdSortEnum
  | AnnouncementsNameSortEnum
  | AnnouncementTerminalNameSortEnum;

export type AnnouncementsHistoryQuery = {
  [AnnouncementsHistoryQueryKeysEnum.LIMIT]?: number;
  [AnnouncementsHistoryQueryKeysEnum.OFFSET]?: number;
  [AnnouncementsHistoryQueryKeysEnum.DATA_OD]?: string;
  [AnnouncementsHistoryQueryKeysEnum.DATA_DO]?: string;
  [AnnouncementsHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [AnnouncementsHistoryQueryKeysEnum.MOD]?: string;
  [AnnouncementsHistoryQueryKeysEnum.TRYB]?: string;
  [AnnouncementsHistoryQueryKeysEnum.SERWER]?: Option;
  [AnnouncementsHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [AnnouncementsHistoryQueryKeysEnum.NAZWA]?: Option;
  [AnnouncementsHistoryQueryKeysEnum.QUERY]?: Option;
  [AnnouncementsHistoryQueryKeysEnum.POSIADACZ]?: Option;
} & PaginationQuery;

export type AnnouncementAssignedOwnersQuery = {
  [AnnouncementAssignedOwnersQueryKeysEnum.LIMIT]?: number;
  [AnnouncementAssignedOwnersQueryKeysEnum.OFFSET]?: number;
  [AnnouncementAssignedOwnersQueryKeysEnum.QUERY]?: string;
  [AnnouncementAssignedOwnersQueryKeysEnum.SORT]?: OwnersSortValue[];
  [AnnouncementAssignedOwnersQueryKeysEnum.KOMORKA]?: string[];
  [AnnouncementAssignedOwnersQueryKeysEnum.STANOWISKO]?: Position[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type SchedulesQuery = {
  [SchedulesQueryKeysEnum.LIMIT]?: number;
  [SchedulesQueryKeysEnum.OFFSET]?: number;
  [SchedulesQueryKeysEnum.QUERY]?: string;
  [SchedulesQueryKeysEnum.SORT]?: SchedulesSortValue[];
  [SchedulesQueryKeysEnum.TYP]?: string;
  [SchedulesQueryKeysEnum.PROBLEM_TKD]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type SchedulesSortValue = SchedulesNameSortEnum;

export type SchedulesExceptionsQuery = {
  [SchedulesExceptionsQueryKeysEnum.LIMIT]?: number;
  [SchedulesExceptionsQueryKeysEnum.OFFSET]?: number;
  [SchedulesExceptionsQueryKeysEnum.SORT]?: SchedulesExceptionsSortValue[];
  [SchedulesExceptionsQueryKeysEnum.TYP]?: string;
  [SchedulesExceptionsQueryKeysEnum.PROBLEM_TKD]?: string;
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type SchedulesExceptionsSortValue = SchedulesExceptionsDateSortEnum;

export type SchedulesHistoryQuery = {
  [SchedulesHistoryQueryKeysEnum.LIMIT]?: number;
  [SchedulesHistoryQueryKeysEnum.OFFSET]?: number;
  [SchedulesHistoryQueryKeysEnum.DATA_OD]?: string;
  [SchedulesHistoryQueryKeysEnum.DATA_DO]?: string;
  [SchedulesHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [SchedulesHistoryQueryKeysEnum.TYP]?: ScheduleHistoryTypeFilterEnum;
  [SchedulesHistoryQueryKeysEnum.SERWER]?: Option;
  [SchedulesHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [SchedulesHistoryQueryKeysEnum.QUERY]?: Option;
  [SchedulesHistoryQueryKeysEnum.NAZWA]?: Option;
  [SchedulesHistoryQueryKeysEnum.WYJATEK]?: string;
} & PaginationQuery;

export type SchedulesServersQuery = {
  [WantedTerminalsQueryKeysEnum.LIMIT]?: number;
  [WantedTerminalsQueryKeysEnum.OFFSET]?: number;
  [WantedTerminalsQueryKeysEnum.SORT]?: WantedServersSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type ScheduleExceptionTerminalsQuery = {
  [GlcQueryKeysEnum.LIMIT]?: number;
  [GlcQueryKeysEnum.OFFSET]?: number;
  [GlcQueryKeysEnum.SORT]?: WantedServersSortValue[];
  [IS_SORT_DIRTY]?: boolean;
} & PaginationQuery;

export type SequencesSortValue = SequencesIdSortEnum | SequencesCardASortEnum | SequencesCardBSortEnum;

export type SequencesQuery = {
  [SequencesQueryKeysEnum.LIMIT]?: number;
  [SequencesQueryKeysEnum.OFFSET]?: number;
  [SequencesQueryKeysEnum.QUERY]?: string;
  [SequencesQueryKeysEnum.SORT]?: SequencesSortValue[];
  [SequencesQueryKeysEnum.TYP]?: string;
  [SequencesQueryKeysEnum.PROBLEM_TKD]?: string;
  [IS_SORT_DIRTY]?: boolean;
};

export type SequencesHistoryQuery = {
  [SequencesHistoryQueryKeysEnum.LIMIT]?: number;
  [SequencesHistoryQueryKeysEnum.OFFSET]?: number;
  [SequencesHistoryQueryKeysEnum.DATA_OD]?: string;
  [SequencesHistoryQueryKeysEnum.DATA_DO]?: string;
  [SequencesHistoryQueryKeysEnum.AKCJA]?: HistoryActionEnum;
  [SequencesHistoryQueryKeysEnum.TYP]?: SequenceTypeEnum;
  [SequencesHistoryQueryKeysEnum.SERWER]?: Option;
  [SequencesHistoryQueryKeysEnum.CZYTNIK]?: Option;
  [SequencesHistoryQueryKeysEnum.QUERY]?: Option;
  [SequencesHistoryQueryKeysEnum.NUMER_KARTY]?: Option;
} & PaginationQuery;

export type QueryContextValueType = {
  storedQueries: QueryState;
  setQuery: <T extends QueryKeyLocalStorageEnum>(key: T, value: QueryState[T]) => void;
  setSortQuery: <T extends keyof SortKeysWithValues>(queryKey: T, sortValue: SortKeysWithValues[T]) => void;
  resetQueryState: () => void;
  setPartialStoredQueries: (partialStoredQueries: Partial<QueryState>) => void;
};

export type FilteredKeysWithSort<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends { sort?: unknown } ? K : never;
}[keyof T];

export type SortKeysWithValues = {
  [Q in FilteredKeysWithSort<QueryState>]: QueryState[Q] extends { sort?: unknown }
    ? NonNullable<QueryState[Q]['sort']>[number]
    : never;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AscSortEnums<T> = T extends `-${any}` ? never : T;
