import { useLocale } from 'shared-ui';
import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';
import { SequenceTypeEnum } from 'api/sequences/sequences.enum';

export const useAddSequenceValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    kartaA: yup
      .string()
      .required()
      .when('typ', (typ) => {
        if (typ && typ === SequenceTypeEnum.SKARBIEC) {
          return yup
            .string()
            .required(formatMessage({ id: AppMessages['common.validation.required'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.required'] }));
        }
        return yup.array().min(1, formatMessage({ id: AppMessages['common.validation.required'] }));
      }),
    kartaB: yup
      .string()
      .required()
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    typ: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
