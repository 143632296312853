import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { DeleteOwnerProps } from './DeleteOwner.types';

export const DeleteOwner = ({
  selectedOwner,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: DeleteOwnerProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['owner.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owner.delete.submitButton'] })}
      >
        <ActionModal.Subtitle>{`${selectedOwner.nazwisko || ''} ${selectedOwner.imie || ''}`}</ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['owner.delete.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
