import { Pagination } from 'shared-ui';

import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';

import { SequencesTableContainer } from './sequencesTable/SequencesTableContainer';
import { SequencesListProps } from './SequencesList.types';

export const SequencesList = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  sequencesCount,
  setSequencesCount,
  sequencesTableSearchQuery,
}: SequencesListProps) => {
  return (
    <>
      <Pagination
        page={page}
        setPage={setPage}
        count={sequencesCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ExpandableList
        data={servers || []}
        isLoading={isLoading}
        title={(server) => server.nazwa}
        initiallyExpandedItem={findServerToExpand(servers || [])}
        toggleItemExpansion={(expandedServer) => {
          setPage(0);
          if (!expandedServer) setSequencesCount(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <SequencesTableContainer
            key={server.id}
            expandedServer={expandedServer}
            setSequencesCount={setSequencesCount}
            sequencesTableSearchQuery={sequencesTableSearchQuery}
          />
        )}
      />
    </>
  );
};
