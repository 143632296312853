import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteSequenceProps } from './DeleteSequence.types';
import * as Styled from './DeleteSequence.styles';

export const DeleteSequence = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: DeleteSequenceProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.TrashIcon onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['sequences.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
      >
        <ActionModal.Text>{formatMessage({ id: AppMessages['sequences.delete.text'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
