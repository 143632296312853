import { useEffect, useState } from 'react';

import { QueryContext } from '../queryContext/QueryContext';
import { QueryKeyLocalStorageEnum } from '../queryContext/QueryContext.enum';
import { QueryState, SortKeysWithValues } from '../queryContext/QueryContext.types';

import { QueryContextControllerProps } from './QueryContextController.types';
import { DEFAULT_QUERY_STATE } from './QueryContextController.variables';

export const QueryContextController = ({ children }: QueryContextControllerProps) => {
  const initStoredQueriesState = () => {
    const localStorageQueries = Object.values(QueryKeyLocalStorageEnum)
      .map((queryKey) => {
        const item = window.localStorage.getItem(queryKey);

        return item ? { [queryKey]: JSON.parse(item) as QueryState[QueryKeyLocalStorageEnum] } : { [queryKey]: {} };
      })
      .reduce<QueryState>(
        (acc, val) => ({ ...acc, ...val }),
        Object.fromEntries(Object.entries(DEFAULT_QUERY_STATE).map(([key, state]) => [key, state])) as QueryState,
      );

    return localStorageQueries;
  };

  const [storedQueries, setStoredQueries] = useState<QueryState>(initStoredQueriesState);

  const setQuery = <T extends QueryKeyLocalStorageEnum>(key: T, value: QueryState[T]) => {
    setStoredQueries({ ...storedQueries, [key]: value });

    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const setSortQuery = <T extends keyof SortKeysWithValues>(key: T, sortValue: SortKeysWithValues[T]) => {
    const sortValues = storedQueries[key].sort;

    if (!sortValues) return setQuery(key, { ...storedQueries[key], sort: [sortValue] });

    setQuery(key, { ...storedQueries[key], sort: [sortValue] });
  };

  const resetQueryState = () => {
    Object.entries(DEFAULT_QUERY_STATE).forEach(([key, defaultQueryState]) => {
      window.localStorage.setItem(key, JSON.stringify(defaultQueryState));
    });

    setStoredQueries(initStoredQueriesState);
  };

  const setPartialStoredQueries = (partialStoredQueries: Partial<QueryState>) => {
    setStoredQueries({ ...storedQueries, ...partialStoredQueries });

    Object.entries(partialStoredQueries).forEach(([key, value]) =>
      window.localStorage.setItem(key, JSON.stringify(value)),
    );
  };

  useEffect(() => {
    resetQueryState();
  }, []);

  return (
    <QueryContext.Provider value={{ storedQueries, setQuery, setSortQuery, resetQueryState, setPartialStoredQueries }}>
      {children}
    </QueryContext.Provider>
  );
};
