import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Owner } from 'api/owners/owners.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { fetchGroupAssignedOwners, fetchGroupDetails } from 'api/groups/groups';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { GroupAssignedOwners } from './GroupAssignedOwners';

export const GroupAssignedOwnersContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: groupAssignedOwnersQuery },
  } = useLocaleStoreQuery();
  const { selectedGroup } = useSelectedGroup();
  const [groupCheckedAssignedOwners, setGroupCheckedAssignedOwners] = useState<Owner[]>([]);
  const { id } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS,
    });

  const { data: groupAssignedOwnersResponse, isLoading } = useQuery(
    [QueryKeyEnum.GROUP_ASSIGNED_OWNERS, storedQueryWithoutPagination, page, rowsPerPage, id],
    () => fetchGroupAssignedOwners(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        stanowisko: groupAssignedOwnersQuery?.stanowisko?.map(({ id }) => id),
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  const { data: groupDetails } = useQuery([QueryKeyEnum.GROUP_DETAILS, id], () => fetchGroupDetails(id), {
    enabled: !selectedGroup,
  });

  useEffect(() => {
    if (!groupAssignedOwnersResponse) return;

    const updatedCheckedOwners = groupAssignedOwnersResponse.data.filter((owner) =>
      groupCheckedAssignedOwners.some((groupAssignedOwner) => groupAssignedOwner.id === owner.id),
    );

    setGroupCheckedAssignedOwners(updatedCheckedOwners);
  }, [groupAssignedOwnersResponse]);

  return (
    <GroupAssignedOwners
      groupAssignedOwnersResponse={groupAssignedOwnersResponse}
      isLoading={isLoading}
      groupCheckedAssignedOwners={groupCheckedAssignedOwners}
      setGroupCheckedAssignedOwners={setGroupCheckedAssignedOwners}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      groupName={selectedGroup?.nazwa || groupDetails?.nazwa || ''}
    />
  );
};
