import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import {
  AnnouncementsHistoryQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { fetchServers } from 'api/servers/servers';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { fetchAnnouncementsHistory } from 'api/announcements/announcements';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { AnnouncementsHistory } from './AnnouncementsHistory';
import { useAnnouncementsHistorySearchQuery } from './hooks/useAnnouncementsHistorySearchQuery';

export const AnnouncementsHistoryContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY]: announcementsHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const {
    tkdSearchQuery,
    executorSearchQuery,
    ownerSearchQuery,
    setExecutorSearchQuery,
    setOwnerSearchQuery,
    setTkdSearchQuery,
    nameSearchQuery,
    setNameSearchQuery,
    clearSearchQueries,
  } = useAnnouncementsHistorySearchQuery();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY,
    resetPaginationDependencyArray: [tkdSearchQuery, executorSearchQuery],
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: announcementsHistoryResponse, isLoading } = useQuery(
    [
      QueryKeyEnum.ANNOUNCEMENTS_HISTORY,
      announcementsHistoryQuery,
      rowsPerPage,
      page,
      tkdSearchQuery,
      executorSearchQuery,
      ownerSearchQuery,
      nameSearchQuery,
    ],
    () => fetchAnnouncementsHistory(),
    {
      args: {
        ...storedQueryWithoutPagination,
        [AnnouncementsHistoryQueryKeysEnum.SERWER]: announcementsHistoryQuery.serwer?.value,
        [AnnouncementsHistoryQueryKeysEnum.CZYTNIK]: announcementsHistoryQuery.tkd?.value,
        [AnnouncementsHistoryQueryKeysEnum.QUERY]: announcementsHistoryQuery.query?.value,
        [AnnouncementsHistoryQueryKeysEnum.POSIADACZ]: announcementsHistoryQuery.posiadacz?.value,
        [AnnouncementsHistoryQueryKeysEnum.NAZWA]: announcementsHistoryQuery.nazwa?.value,
        [AnnouncementsHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [AnnouncementsHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  const onClearAnnouncementsHistoryQuery = () => {
    setQuery(QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY, {
      page: announcementsHistoryQuery.page,
      rowsPerPage: announcementsHistoryQuery.rowsPerPage,
    });
    clearSearchQueries();
  };

  return (
    <AnnouncementsHistory
      announcementsHistoryResponse={announcementsHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      ownerSearchQuery={ownerSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      setOwnerSearchQuery={setOwnerSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      nameSearchQuery={nameSearchQuery}
      setNameSearchQuery={setNameSearchQuery}
      onClearAnnouncementsHistoryQuery={onClearAnnouncementsHistoryQuery}
    />
  );
};
