"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipWrapper = exports.SelectPlaceholder = exports.FormHelperText = exports.Required = exports.Label = exports.FilledInput = exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const selectListElement = (0, styled_components_1.css)(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.primary.main};
    background-color: ${palette.grey.A200};
    border-radius: 0px;
  `);
exports.Select = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.Select, { ...props, classes: { select: className }, MenuProps: {
        ...props.MenuProps,
        classes: {
            root: className,
            paper: 'selectMenu',
            list: 'selectMenuList',
        },
    } })))(({ theme: { palette, shape } }) => (0, styled_components_1.css) `
    border-radius: ${shape.borderRadius}px;
    padding: 13.5px;

    &.Mui-disabled {
      cursor: not-allowed;
    }

    &:focus {
      background-color: ${palette.grey.A200};
      border-radius: ${shape.borderRadius}px;
    }

    & .selectMenu {
      background-color: ${palette.grey.A200};
      border: 1px solid ${palette.grey[500]};
      box-shadow: none;
    }

    & .selectMenuList {
      color: ${palette.grey[900]};
      padding: 0;
      max-height: 350px;

      & li {
        display: flex;
        justify-content: start;
        align-items: center;
        border-bottom: 1px solid ${palette.grey[500]};
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        padding: 16px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          color: ${palette.primary.main};
        }

        &:focus {
          ${selectListElement}
        }

        &.Mui-selected {
          ${selectListElement}
        }

        &.Mui-selected:hover {
          ${selectListElement}
        }

        &.Mui-selected:active {
          ${selectListElement}
        }

        &.Mui-selected:focus {
          ${selectListElement}
        }
      }
    }
  `);
exports.FilledInput = (0, styled_components_1.default)(material_1.Input)(({ theme: { palette, shape }, $isError }) => (0, styled_components_1.css) `
    background-color: ${$isError ? '#fef5f3' : palette.grey[100]};
    color: ${$isError ? palette.error.main : palette.text.primary};
    border-radius: ${shape.borderRadius}px;
    font-weight: 700;

    & .Mui-disabled {
      background-color: #fbfbfb;
      cursor: not-allowed;
      opacity: 0.6;
    }
  `);
exports.Label = (0, styled_components_1.default)(material_1.InputLabel)(({ $withTooltip }) => (0, styled_components_1.css) `
    margin-bottom: 8px;
    font-weight: 700;

    ${$withTooltip &&
    (0, styled_components_1.css) `
      display: flex;
      align-items: end;
    `}
  `);
exports.Required = styled_components_1.default.span(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.error.main};
  `);
exports.FormHelperText = (0, styled_components_1.default)(material_1.FormHelperText)(({ $isHelperText, $isError, theme: { palette } }) => (0, styled_components_1.css) `
    display: block;
    font-weight: 700;
    color: ${palette.error.main};
    color: ${$isHelperText && !$isError && palette.grey[800]};
  `);
exports.SelectPlaceholder = styled_components_1.default.span(({ $isError, theme: { palette } }) => (0, styled_components_1.css) `
    font-weight: 500;
    color: ${$isError ? palette.error.main : palette.text.primary};
  `);
exports.TooltipWrapper = styled_components_1.default.div `
  margin-left: 6px;
  height: 24px;
`;
