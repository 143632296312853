import { SequenceTypeEnum, SequenceTypeDescriptionEnum } from 'api/sequences/sequences.enum';
import {
  QueryKeyLocalStorageEnum,
  TerminalWithSequenceIdSortEnum,
  TerminalWithSequenceNameSortEnum,
  TerminalWithSequenceQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const TerminalsTableHeader = () => {
  const { formatMessage } = useLocale();

  const typeFilterOptions = Object.values(SequenceTypeEnum).map((option) => ({
    name: formatMessage({ id: SequenceTypeDescriptionEnum[option] }),
    filterBy: option,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE}
        sortBy={TerminalWithSequenceIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['common.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE}
        sortBy={TerminalWithSequenceNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['definitions.sequences.readerName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE}
        queryName={TerminalWithSequenceQueryKeysEnum.SEKWENCJA_TYP}
        filterItems={typeFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['definitions.sequences.sequence'] })}
        queryHeaderDescriptions={SequenceTypeDescriptionEnum}
      />
    </>
  );
};
