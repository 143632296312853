import { useParams } from 'react-router-dom';

import { fetchOwnerIndividualPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { OwnerIndividualTerminalsPermissions } from './OwnerIndividualTerminalsPermissions';
import { OwnerIndividualTerminalsPermissionsContainerProps } from './OwnerIndividualTerminalsPermissions.types';

export const OwnerIndividualTerminalsPermissionsContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: OwnerIndividualTerminalsPermissionsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const { ownerPermissionsIndividualQueryKey } = useOwnerQueryKeys();

  const { page, rowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: ownerPermissionsIndividualQueryKey,
  });

  const { data: permissionsResponse } = useQuery(
    [
      QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL,
      ownerId,
      expandedServer?.id,
      storedQueryWithoutPagination,
      page,
      rowsPerPage,
    ],
    () => fetchOwnerIndividualPermissions({ ownerId, serverId: expandedServer?.id || server.id }),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset: rowsPerPage * page },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <OwnerIndividualTerminalsPermissions
      server={server}
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
