import { useParams } from 'react-router-dom';

import { fetchServersForControl } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { RandomControlTerminals } from './RandomControlTerminals';

export const RandomControlTerminalsContainer = () => {
  const { id: controlId } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.RANDOM_CONTROL_OWNERS, controlId], () =>
    fetchServersForControl(controlId),
  );

  return (
    <RandomControlTerminals
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      terminalsCount={paginationCount}
      setTerminalsCount={setPaginationCount}
      controlId={controlId || ''}
    />
  );
};
