import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, InstantControlQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchControl } from 'api/control/control';
import { ControlTypeEnum } from 'api/control/control.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { InstantControl } from './InstantControl';
import { InstantControlContainerProps } from './InstantControl.types';

export const InstantControlContainer = ({ instantControlTableSearchQuery }: InstantControlContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.INSTANT_CONTROL]: instantControlQuery },
  } = useLocaleStoreQuery();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, offset, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.INSTANT_CONTROL,
      resetPaginationDependencyArray: [instantControlTableSearchQuery],
    });

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.INSTANT_CONTROL_OWNERS, instantControlQuery, instantControlTableSearchQuery],
    fetchControl,
    {
      args: {
        ...storedQueryWithoutPagination,
        [InstantControlQueryKeysEnum.LIMIT]: rowsPerPage,
        [InstantControlQueryKeysEnum.OFFSET]: offset,
        [InstantControlQueryKeysEnum.QUERY]: instantControlTableSearchQuery,
        [InstantControlQueryKeysEnum.TYP]: ControlTypeEnum.NATYCHMIASTOWA,
      },
      refetchInterval: 5000,
      onSuccess: (res) => {
        setPaginationCount(res.count);
      },
    },
  );

  return (
    <InstantControl
      ownersResponse={ownersResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
