import { ProblemIconWithTooltip } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { SequenceTypeDescriptionEnum } from 'api/sequences/sequences.enum';

import * as Styled from './SequencesTableRow.styles';
import { SequenceTableRowProps } from './SequencesTableRow.types';
import { DeleteSequenceContainer } from './deleteSequence/DeleteSequenceContainer';

export const SequencesTableRow = ({ sequence }: SequenceTableRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.RowItem>{sequence.tkd.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem>{sequence.tkd.nazwa}</Table.RowItem>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={sequence.problemTkd}
          tooltip={formatMessage({ id: AppMessages['sequences.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowItem>{formatMessage({ id: AppMessages[SequenceTypeDescriptionEnum[sequence.typ]] })}</Table.RowItem>

      <Table.RowItem>{sequence.kartaA.map((card) => card.numerKarty).join(', ')}</Table.RowItem>

      <Table.RowItem>{sequence.kartaB.numerKarty}</Table.RowItem>

      <Table.RowItem>
        <Styled.Wrapper>
          <DeleteSequenceContainer sequence={sequence} />
        </Styled.Wrapper>
      </Table.RowItem>
    </>
  );
};
