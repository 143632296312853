import { useParams } from 'react-router-dom';

import { fetchServersForOwnerEffectivePermissions } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { OwnerEffectivePermissions } from './OwnerEffectivePermissions';

export const OwnerEffectivePermissionsContainer = () => {
  const { id: ownerId } = useParams<{ id: string }>();
  const { ownerPermissionsEffectiveQueryKey } = useOwnerQueryKeys();

  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    storedQueryWithoutPagination,
    paginationCount,
    setPaginationCount,
  } = useSavedPagination({
    queryKey: ownerPermissionsEffectiveQueryKey,
  });

  const { data: serversResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_EFFECTIVE_SERVERS, ownerId, storedQueryWithoutPagination],
    () => fetchServersForOwnerEffectivePermissions(ownerId),
    {
      omitErrorCodes: [404],
    },
  );

  return (
    <OwnerEffectivePermissions
      servers={serversResponse?.data || []}
      isLoading={isLoading}
      permissionsCount={paginationCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setPermissionsCount={setPaginationCount}
    />
  );
};
