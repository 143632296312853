import { useQuery } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchTerminals } from 'api/terminals/terminals';

import { SequencesTableHeader } from './SequencesTableHeader';
import { SequencesTableHeaderContainerProps } from './SequencesTableHeader.types';

export const SequencesTableHeaderContainer = ({ expandedServer }: SequencesTableHeaderContainerProps) => {
  const { data: terminals } = useQuery(
    [QueryKeyEnum.TERMINALS, expandedServer?.id],
    () => fetchTerminals(expandedServer?.id),
    {
      enabled: !!expandedServer,
    },
  );

  return <SequencesTableHeader terminals={terminals?.data || []} />;
};
