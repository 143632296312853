import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { HistoryList } from 'ui/historyList/HistoryList';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';
import { GlcDetailsContainer } from '../glcDetails/GlcDetailsContainer';

import { GlcHistoryProps } from './GlcHistory.types';

export const GlcHistory = ({
  glc,
  isLoadingGlcDetails,
  glcHistoryResponse,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  isLoading,
  isFetching,
  onChangeActionFilter,
  onClearHistoryQuery,
  handleSetDates,
  glcHistoryQuery,
  searchQuery,
  setSearchQuery,
  readerSearchQuery,
  setReaderSearchQuery,
}: GlcHistoryProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['common.history'] })}
      withBackButton={{
        onClick: (navigate) => {
          navigate(-1);
          setTimeout(() => onClearHistoryQuery(), 400);
        },
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.EmptyBarWithLine />
          </TopBar>

          <HistoryList
            data={glcHistoryResponse?.data || []}
            isLoading={isLoading}
            filters={{
              gridTemplateColumns: '1fr 1fr 1fr',
              isClearButtonVisible:
                Object.values(filterQueryFromPagination(glcHistoryQuery)).some((query) => query) ||
                !!searchQuery ||
                !!readerSearchQuery,
              onClearFilters: onClearHistoryQuery,
              render: () => (
                <>
                  <HistoryDatepickerWithModal
                    dates={[
                      glcHistoryQuery.dataOd ? new Date(glcHistoryQuery.dataOd) : null,
                      glcHistoryQuery.dataDo ? new Date(glcHistoryQuery.dataDo) : null,
                    ]}
                    setDates={handleSetDates}
                  />

                  <HistoryActionAutocomplete
                    selectedAction={glcHistoryQuery.akcja}
                    onChangeAction={onChangeActionFilter}
                    historyType="groups"
                  />

                  <TableSearchContainer
                    withoutToggle
                    inputPlaceholder={formatMessage({ id: AppMessages['glc.history.search.reader'] })}
                    searchBy="terminals"
                    tableSearchQuery={readerSearchQuery}
                    setTableSearchQuery={setReaderSearchQuery}
                    inputSize="big"
                  />

                  <TableSearchContainer
                    withoutToggle
                    inputPlaceholder={formatMessage({ id: AppMessages['glc.history.search.executor'] })}
                    searchBy="users"
                    tableSearchQuery={searchQuery}
                    setTableSearchQuery={setSearchQuery}
                    inputSize="big"
                  />
                </>
              ),
            }}
            pagination={{
              page,
              setPage,
              rowsPerPage,
              setRowsPerPage,
              count: glcHistoryResponse?.count || 0,
            }}
            renderHistoryItemBody={(history) => (
              <>
                {history.wykonawca && !!Object.entries(history.wykonawca).length && (
                  <HistoryList.ActionBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.executor'] })}
                    value={`${history.wykonawca.nazwisko} ${history.wykonawca.imie}`}
                  />
                )}

                {history.powod && (
                  <HistoryList.ActionBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.reason'] })}
                    value={history.powod}
                  />
                )}

                {history.nazwa && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.name'] })}
                    oldValue={history.nazwa.old}
                    newValue={history.nazwa.new}
                  />
                )}

                {history.opis && (
                  <HistoryList.ActionModifiedLongFieldBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.description'] })}
                    oldValue={history.opis.old}
                    newValue={history.opis.new}
                  />
                )}

                {history.dodaneCzytniki && (
                  <HistoryList.ActionListdBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.addedReaders'] })}
                    items={history.dodaneCzytniki}
                    shouldScrollToTop={!!glcHistoryQuery.czytnik && !isFetching}
                    renderItemBody={(glc) => (
                      <HistoryList.RenderedActionListdBodyItem
                        numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                        number={glc.numerLogicznyKontrolera}
                        value={glc.nazwa}
                      />
                    )}
                  />
                )}

                {history.usunieteCzytniki && (
                  <HistoryList.ActionListdBodyItem
                    title={formatMessage({ id: AppMessages['glc.history.descriptionTitle.removedReaders'] })}
                    items={history.usunieteCzytniki}
                    shouldScrollToTop={!!glcHistoryQuery.czytnik && !isFetching}
                    renderItemBody={(glc) => (
                      <HistoryList.RenderedActionListdBodyItem
                        numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                        number={glc.numerLogicznyKontrolera}
                        value={glc.nazwa}
                      />
                    )}
                  />
                )}
              </>
            )}
          />
        </>
      )}
      renderDetails={() =>
        !isLoadingGlcDetails && glc ? (
          <DetailsDrawer isOpen header={formatMessage({ id: AppMessages['common.details'] })}>
            <GlcDetailsContainer selectedGlc={glc} />
          </DetailsDrawer>
        ) : (
          <HistoryList.DetailsLoader />
        )
      }
    />
  );
};
