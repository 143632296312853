import { AutocompleteRenderGroupParams } from '@mui/material';
import { Option } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import {
  CardNumberSortEnum,
  OwnerHistorySearchByEnum,
  UsersFullNameSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { fetchUsers } from 'api/users/users';
import { fetchCards } from 'api/cards/cards';
import * as Styled from '../TableSearch.styles';

export const useSearchByCardsAndUsers = (
  optionsQuery: string,
  isEnabled: boolean,
  tableSearchByQuery?: OwnerHistorySearchByEnum,
  setTableSearchByQuery?: (tableSearchByQuery: OwnerHistorySearchByEnum) => void,
) => {
  const { formatMessage } = useLocale();
  const isSearchByUsers = tableSearchByQuery === OwnerHistorySearchByEnum.USER;
  const isSearchByCards = tableSearchByQuery === OwnerHistorySearchByEnum.NUMER_KARTY;

  const { data: usersResponse, isInitialLoading: isLoadingUsers } = useQuery(
    [QueryKeyEnum.USERS, optionsQuery],
    fetchUsers,
    {
      enabled: isEnabled && isSearchByUsers,
      args: { query: optionsQuery, offset: 0, limit: 100, sort: [UsersFullNameSortEnum.ASC] },
    },
  );
  const users = usersResponse?.data || [];

  const { data: cardsResponse, isInitialLoading: isLoadingCards } = useQuery(
    [QueryKeyEnum.CARD_SEARCH, optionsQuery],
    fetchCards,
    {
      args: { query: optionsQuery, offset: 0, limit: 100, sort: [CardNumberSortEnum.ASC] },
      enabled: isEnabled && isSearchByCards,
    },
  );
  const cards = cardsResponse?.data || [];

  const createOptions = () => {
    return isSearchByUsers
      ? users.map((user) => ({
          label: `${user.nazwisko || ''} ${user.imie || ''}`,
          value: `${user.nazwisko || ''} ${user.imie || ''} `,
          groupBy: 'owner',
        }))
      : cards.map((card) => ({
          label: card.numerKarty,
          value: card.numerKarty,
          groupBy: 'cards',
        }));
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  const createPopupHeader = (withNotFound?: boolean) => {
    return (
      <>
        <Styled.PopupHeaderWrapper>
          <Styled.HeaderOption
            onClick={() => setTableSearchByQuery && setTableSearchByQuery(OwnerHistorySearchByEnum.USER)}
            $isActive={tableSearchByQuery === OwnerHistorySearchByEnum.USER}
          >
            {formatMessage({ id: AppMessages['tableSearch.header.user'] })}
          </Styled.HeaderOption>
          <Styled.HeaderOption
            onClick={() => setTableSearchByQuery && setTableSearchByQuery(OwnerHistorySearchByEnum.NUMER_KARTY)}
            $isActive={tableSearchByQuery === OwnerHistorySearchByEnum.NUMER_KARTY}
          >
            {formatMessage({ id: AppMessages['tableSearch.header.cardNumber'] })}
          </Styled.HeaderOption>
        </Styled.PopupHeaderWrapper>
        {withNotFound && (
          <Styled.NotFoundLabel>{formatMessage({ id: AppMessages['common.notFound'] })}</Styled.NotFoundLabel>
        )}
      </>
    );
  };

  const createGroup = (groupParams: AutocompleteRenderGroupParams) => {
    return (
      <>
        {createPopupHeader()}
        {groupParams.children}
      </>
    );
  };

  return {
    isLoading: isLoadingUsers || isLoadingCards,
    createOptions,
    createGroup,
    createOptionLabel,
    createPopupHeader,
  };
};
