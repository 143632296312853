import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Owner } from 'api/owners/owners.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';
import { fetchAnnouncementAssignedOwners, fetchAnnouncementDetails } from 'api/announcements/announcements';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { AnnouncementAssignedOwners } from './AnnouncementAssignedOwners';

export const AnnouncementAssignedOwnersContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]: annuncementAssignedOwnersQuery },
  } = useLocaleStoreQuery();
  const { selectedAnnouncement } = useSelectedAnnouncement();
  const [checkedAssignedOwners, setCheckedAssignedOwners] = useState<Owner[]>([]);
  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({ queryKey: QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS });
  const { id } = useParams<{ id: string }>();

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_ASSIGNED_OWNERS, annuncementAssignedOwnersQuery, page, rowsPerPage, id],
    () => fetchAnnouncementAssignedOwners(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        stanowisko: annuncementAssignedOwnersQuery?.stanowisko?.map(({ id }) => id),
      },
      onSuccess: (res) => {
        setPaginationCount(res.count);
      },
    },
  );

  const { data: announcemenDetails } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_DETAILS, id],
    () => fetchAnnouncementDetails(id),
    {
      enabled: !selectedAnnouncement,
    },
  );

  useEffect(() => {
    if (!ownersResponse) return;

    const updatedCheckedOwners = ownersResponse.data.filter((owner) =>
      checkedAssignedOwners.some((assignedOwner) => assignedOwner.id === owner.id),
    );

    setCheckedAssignedOwners(updatedCheckedOwners);
  }, [ownersResponse]);

  return (
    <AnnouncementAssignedOwners
      ownersResponse={ownersResponse}
      isLoading={isLoading}
      checkedAssignedOwners={checkedAssignedOwners}
      setCheckedAssignedOwners={setCheckedAssignedOwners}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      announcementName={selectedAnnouncement?.nazwa || announcemenDetails?.nazwa || ''}
    />
  );
};
