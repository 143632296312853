import { AutocompleteRenderGroupParams } from '@mui/material';
import { Option } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import * as Styled from '../TableSearch.styles';
import { AppMessages } from 'i18n/messages';
import { CardsHistorySearchByEnum, UsersFullNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchUsers } from 'api/users/users';

import { useSearchByOwners } from './useSearchByOwners';

export const useSearchByOwnersAndUsers = (
  optionsQuery: string,
  isEnabled: boolean,
  tableSearchByQuery?: CardsHistorySearchByEnum,
  setTableSearchByQuery?: (tableSearchByQuery: CardsHistorySearchByEnum) => void,
) => {
  const { formatMessage } = useLocale();
  const isSearchByUsers = tableSearchByQuery === CardsHistorySearchByEnum.USER;

  const { data: usersResponse, isInitialLoading } = useQuery([QueryKeyEnum.USERS, optionsQuery], fetchUsers, {
    enabled: isEnabled && isSearchByUsers,
    args: { query: optionsQuery, offset: 0, limit: 100, sort: [UsersFullNameSortEnum.ASC] },
  });

  const { createOptions: createOwnerOptions, createOptionLabel: createOwnerOptionLabel } = useSearchByOwners(
    optionsQuery,
    isEnabled && !isSearchByUsers,
    true,
  );

  const createOptions = () => {
    return isSearchByUsers
      ? usersResponse?.data.map((user) => ({
          label: `${user.nazwisko || ''} ${user.imie || ''}`,
          value: `${user.nazwisko || ''} ${user.imie || ''}`,
          groupBy: 'owner',
        })) || []
      : createOwnerOptions();
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  const createPopupHeader = (withNotFound?: boolean) => {
    return (
      <>
        <Styled.PopupHeaderWrapper>
          <Styled.HeaderOption
            onClick={() => setTableSearchByQuery && setTableSearchByQuery(CardsHistorySearchByEnum.PKD)}
            $isActive={tableSearchByQuery === CardsHistorySearchByEnum.PKD}
          >
            {formatMessage({ id: AppMessages['tableSearch.header.owner'] })}
          </Styled.HeaderOption>
          <Styled.HeaderOption
            onClick={() => setTableSearchByQuery && setTableSearchByQuery(CardsHistorySearchByEnum.USER)}
            $isActive={tableSearchByQuery === CardsHistorySearchByEnum.USER}
          >
            {formatMessage({ id: AppMessages['tableSearch.header.user'] })}
          </Styled.HeaderOption>
        </Styled.PopupHeaderWrapper>
        {withNotFound && (
          <Styled.NotFoundLabel>{formatMessage({ id: AppMessages['common.notFound'] })}</Styled.NotFoundLabel>
        )}
      </>
    );
  };

  const createGroup = (groupParams: AutocompleteRenderGroupParams) => {
    return (
      <>
        {createPopupHeader()}
        {groupParams.children}
      </>
    );
  };

  return {
    isLoading: isInitialLoading,
    createOptions,
    createGroup,
    createOptionLabel: isSearchByUsers ? createOptionLabel : createOwnerOptionLabel,
    createPopupHeader,
  };
};
