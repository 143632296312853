import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'shared-ui';

import { AppRoute } from 'routing/AppRoute.enum';

import { EventsExportFormatsContainer } from './eventsExportFormats/EventsExportFormatsContainer';
import { OrganizationContainer } from './organization/OrganizationContainer';
import { PositionsContainer } from './positions/PositionsContainer';
import { OwnersControlContainer } from './ownersControl/OwnersControlContainer';
import { DisplayContainer } from './display/DisplayContainer';
import { LogotypeContainer } from './logotype/LogotypeContainer';
import { SequencesTerminalsContainer } from './sequences/SequencesTerminalsContainer';

export const Definitions = () => {
  const location = useLocation();

  const currentPage = {
    [AppRoute.definitions]: <OrganizationContainer />,
    [AppRoute.positions]: <PositionsContainer />,
    [AppRoute.eventsExportFormats]: <EventsExportFormatsContainer />,
    [AppRoute.ownersControl]: <OwnersControlContainer />,
    [AppRoute.display]: <DisplayContainer />,
    [AppRoute.logotype]: <LogotypeContainer />,
    [AppRoute.sequencesTerminals]: <SequencesTerminalsContainer />,
  }[location.pathname];

  return <ErrorBoundary>{currentPage}</ErrorBoundary>;
};
