import { Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { ExpandableList } from 'ui/expandableList/ExpandableList';

import { OwnerEffectiveTerminalsPermissionsContainer } from './ownerEffectiveTerminalsPermissions/OwnerEffectiveTerminalsPermissionsContainer';
import { OwnerEffectivePermissionsProps } from './OwnerEffectivePermissions.types';

export const OwnerEffectivePermissions = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  permissionsCount,
  setPermissionsCount,
}: OwnerEffectivePermissionsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Pagination
        page={page}
        setPage={setPage}
        count={permissionsCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ExpandableList
        data={servers}
        isLoading={isLoading}
        nothingToShowText={formatMessage({ id: AppMessages['permissions.effective.nothingToShow'] })}
        title={(server) => server.nazwa}
        initiallyExpandedItem={findServerToExpand(servers || [])}
        toggleItemExpansion={(expandedServer) => {
          if (!expandedServer) setPermissionsCount(0);
        }}
        onClickExpandButton={() => {
          setPage(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <OwnerEffectiveTerminalsPermissionsContainer
            key={server.id}
            server={server}
            expandedServer={expandedServer}
            setPermissionsCount={setPermissionsCount}
          />
        )}
      />
    </>
  );
};
