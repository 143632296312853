import { useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { SequencesBarProps } from './SequencesBar.types';

export const SequencesBar = ({ searchQueryKey, setTableSearchQuery, addComponent }: SequencesBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(searchQueryKey, setTableSearchQuery);

  return (
    <TopBar gridTemplateColumns="auto auto 1fr auto">
      <TopBar.Link linkTo={AppRoute.sequences} isActive={location.pathname === AppRoute.sequences}>
        {formatMessage({ id: AppMessages['sequences.list'] })}
      </TopBar.Link>

      <TopBar.Link linkTo={AppRoute.sequencesHistory} isActive={location.pathname === AppRoute.sequencesHistory}>
        {formatMessage({ id: AppMessages['sequences.history'] })}
      </TopBar.Link>

      {setTableSearchQuery ? (
        <>
          <TopBar.Search>
            <TableSearchContainer
              searchIconTestId="page-search"
              inputPlaceholder={formatMessage({ id: AppMessages['sequences.search'] })}
              searchBy="cards"
              setTableSearchQuery={setTableSearchQuery}
              selectedValue={searchOption}
              setSelectedValue={setSelectedSearchOption}
            />
          </TopBar.Search>
        </>
      ) : (
        <TopBar.EmptyBarWithLine />
      )}
      {addComponent && <TopBar.ButtonsWrapper>{addComponent}</TopBar.ButtonsWrapper>}
    </TopBar>
  );
};
