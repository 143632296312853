import { FormHelperText } from '@mui/material';
import styled, { css } from 'styled-components';

export const ErrorMessage = styled(FormHelperText)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.error.main};
    height: 40px;
    max-width: 240px;
  `,
);
