import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';
import { fetchSchedulesExceptions } from 'api/schedules/schedules';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { SchedulesExceptionsTable } from './SchedulesExceptions';
import { SchedulesExceptionsTableContainerProps } from './SchedulesExceptions.types';

export const SchedulesExceptionsTableContainer = ({
  expandedServer,
  setSchedulesCount,
}: SchedulesExceptionsTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const { setExpandedServerId } = useExpandedServerId();

  const { page, rowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS,
  });

  const { data: schedulesExceptionsResponse } = useQuery(
    [QueryKeyEnum.SCHEDULES_EXCEPTIONS, serverId, storedQueryWithoutPagination, page, rowsPerPage],
    () => fetchSchedulesExceptions(serverId),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset: rowsPerPage * page },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setSchedulesCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return <SchedulesExceptionsTable schedulesExceptionsResponse={schedulesExceptionsResponse} />;
};
