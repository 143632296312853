import { useLocale } from 'shared-ui';
import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';

export const useAddTerminalsSequenceValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    idTkds: yup.array().min(1),
    typ: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
