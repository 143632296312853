import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchGroupPermissionHistory } from 'api/groups/groups';
import { GroupPermissionsHistoryQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useGroupHistoryQuery } from '../hooks/useGroupHistoryQuery';
import { fetchServers } from 'api/servers/servers';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { PermissionsHistory } from './PermissionsHistory';
import { PermissionHistoryContainerProps } from './PermissionHistory.types';

export const PermissionsHistoryContainer = ({ group }: PermissionHistoryContainerProps) => {
  const { id } = useParams<{ id: string }>();

  const { clearGroupHistoryQuery, query: groupHistoryQuery, queryKey } = useGroupHistoryQuery(false);

  const [tkdSearchQuery, setTkdSearchQuery] = useState(groupHistoryQuery?.tkd?.label || '');
  const [glcSearchQuery, setGlcSearchQuery] = useState(groupHistoryQuery?.glc?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(groupHistoryQuery?.wykonawca?.label || '');

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey,
      resetPaginationDependencyArray: [tkdSearchQuery, glcSearchQuery, executorSearchQuery],
    });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const {
    data: groupPermissionsHistoryResponse,
    isLoading,
    isFetching,
  } = useQuery(
    [QueryKeyEnum.GROUP_PERMISSIONS_HISTORY, id, storedQueryWithoutPagination, rowsPerPage, page, tkdSearchQuery],
    () => fetchGroupPermissionHistory(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        [GroupPermissionsHistoryQueryKeysEnum.SERWER]: groupHistoryQuery.serwer?.value,
        [GroupPermissionsHistoryQueryKeysEnum.CZYTNIK]: groupHistoryQuery.tkd?.value,
        [GroupPermissionsHistoryQueryKeysEnum.GLC]: groupHistoryQuery.glc?.value,
        [GroupPermissionsHistoryQueryKeysEnum.WYKONAWCA]: groupHistoryQuery.wykonawca?.value,
        [GroupPermissionsHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [GroupPermissionsHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  const onClearHistoryQuery = () => {
    clearGroupHistoryQuery();
    setTkdSearchQuery('');
  };

  return (
    <PermissionsHistory
      group={group}
      groupPermissionsHistoryResponse={groupPermissionsHistoryResponse}
      isLoading={isLoading}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      onClearHistoryQuery={onClearHistoryQuery}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      glcSearchQuery={glcSearchQuery}
      setGlcSearchQuery={setGlcSearchQuery}
    />
  );
};
