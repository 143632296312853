import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'shared-ui';

import { fetchGroupHistory } from 'api/groups/groups';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import {
  GroupHistoryQueryKeysEnum,
  OwnerRegistrationNumberSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { fetchOrganizations } from 'api/organizations/organizations';
import { Organization } from 'api/organizations/organizations.types';
import { Owner } from 'api/owners/owners.types';
import { fetchOwners } from 'api/owners/owners';
import { useGroupHistoryQuery } from '../hooks/useGroupHistoryQuery';
import { PaginatedResponse } from 'core/global.types';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { BaseHistory } from './BaseHistory';
import { BaseHistoryContainerProps } from './BaseHistory.types';

export const BaseHistoryContainer = ({ group }: BaseHistoryContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { clearGroupHistoryQuery, query: groupHistoryQuery, queryKey } = useGroupHistoryQuery();
  const [searchQuery, setSearchQuery] = useState(groupHistoryQuery?.query?.label || '');

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey,
      resetPaginationDependencyArray: [searchQuery],
    });

  const {
    data: groupHistoryResponse,
    isLoading,
    isFetching,
  } = useQuery(
    [QueryKeyEnum.GROUP_HISTORY, id, storedQueryWithoutPagination, rowsPerPage, page, searchQuery],
    () => fetchGroupHistory(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        [GroupHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [GroupHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [GroupHistoryQueryKeysEnum.QUERY]: searchQuery,
      },
    },
  );

  const organizations = queryClient.getQueryData<Organization[]>([QueryKeyEnum.ORGANIZATIONS]);
  const { data: organizationsResponse } = useQuery([QueryKeyEnum.ORGANIZATIONS], fetchOrganizations, {
    enabled: !organizations,
  });

  const owners = queryClient.getQueryData<PaginatedResponse<Owner>>([QueryKeyEnum.OWNERS_SEARCH]);
  const { data: ownersResponse } = useQuery([QueryKeyEnum.OWNERS_SEARCH, groupHistoryQuery.posiadacz], fetchOwners, {
    args: {
      posiadacz: groupHistoryQuery.posiadacz,
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
    },
    onSuccess: (response) => {
      setPaginationCount(response.count);
    },
    enabled: !owners,
  });

  const onClearHistoryQuery = () => {
    clearGroupHistoryQuery();
    setSearchQuery('');
  };

  return (
    <BaseHistory
      group={group}
      groupHistoryResponse={groupHistoryResponse}
      isLoading={isLoading}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      onClearHistoryQuery={onClearHistoryQuery}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      organizations={organizations || organizationsResponse}
      owners={owners?.data || ownersResponse?.data || []}
    />
  );
};
