import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'debounce';
import { Modal, Button, Autocomplete, AutocompleteOption, Datepicker } from 'shared-ui';

import { EventModeEnum, EventModEnum } from 'api/events/events.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { AppMessages } from 'i18n/messages';
import { Form } from 'ui/form/Form';
import { RcpEnum } from '../eventsFilters/EventsFilters.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AddEventFormBody, AddEventProps } from './AddEvent.types';
import * as Styled from './AddEvent.styles';

export const AddEvent = ({
  onSubmit,
  isModalOpen,
  onModalOpen,
  onModalClose,
  cardsWithOwner,
  query,
  setQuery,
  isLoading,
  setSelectedCardWithOwner,
  isLoadingAddEvent,
}: AddEventProps) => {
  const { formatMessage } = useLocale();
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useFormContext<AddEventFormBody>();
  const { modOptions, modeOptions, rcpOptions } = useAppSelectOptions();

  const handleCardWithOwnerSearch = debounce((search: string) => {
    setQuery(search);
  }, 300);

  const cardsWithOwnerOptions = cardsWithOwner
    ? cardsWithOwner.map((card) => ({
        label: card.posiadacz ? `${card.posiadacz.nazwisko} ${card.posiadacz.imie}` : '',
        value: card.id,
      }))
    : [];

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['events.newEventButton'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['events.addEvent.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoadingAddEvent}
      >
        <>
          <Styled.AutocompleteWrapper>
            <Styled.AutocompleteLabel variant="h5">
              {formatMessage({ id: AppMessages['events.addEvent.choose.title'] })}
            </Styled.AutocompleteLabel>

            <Autocomplete
              onChange={(_, option) =>
                setSelectedCardWithOwner(cardsWithOwner?.find((card) => card.id === option?.value))
              }
              value={cardsWithOwnerOptions.find(({ value }) => query === value)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={cardsWithOwnerOptions}
              renderOption={(props, option) => (
                <AutocompleteOption
                  id={cardsWithOwner?.find((card) => card.id === option?.value)?.numerKarty || ''}
                  idPrefix={formatMessage({ id: AppMessages['events.addEvent.choose.cardNumber'] })}
                  label={option.label}
                  props={props}
                />
              )}
              filterOptions={(x) => x}
              loading={isLoading}
              loadingText={formatMessage({ id: AppMessages['events.addEvent.choose.loading'] })}
              noOptionsText={formatMessage({ id: AppMessages['events.addEvent.choose.notFound'] })}
              onInputChange={(_, value, reason) => {
                if (reason === 'clear') {
                  setQuery('');
                  reset({
                    numerKarty: '',
                    posiadacz: '',
                    mod: EventModEnum.WEJSCIE,
                    tryb: EventModeEnum.ZWYKLE,
                    rcp: RcpEnum.FALSE,
                    data: getValues().data,
                  });
                  return;
                }

                handleCardWithOwnerSearch(value);
              }}
              required
              placeholder={formatMessage({ id: AppMessages['events.addEvent.choose.placeholder'] })}
              inputVariant="outlined"
            />
          </Styled.AutocompleteWrapper>

          <Styled.SeparatingLine></Styled.SeparatingLine>

          <Form.Grid>
            <Controller
              name="numerKarty"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={formatMessage({ id: AppMessages['events.addEvent.card.placeholder'] })}
                  label={formatMessage({ id: AppMessages['events.addEvent.card.label'] })}
                  errorMessage={errors?.numerKarty?.message}
                  required
                  disabled
                />
              )}
            />

            <Form.ControlledSelect
              name="mod"
              control={control}
              options={modOptions}
              label={formatMessage({ id: AppMessages['events.addEvent.mod.label'] })}
              required
            />

            <Controller
              name="posiadacz"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={formatMessage({ id: AppMessages['events.addEvent.owner.placeholder'] })}
                  label={formatMessage({ id: AppMessages['events.addEvent.owner.label'] })}
                  errorMessage={errors?.posiadacz?.message}
                  required
                  disabled
                />
              )}
            />

            <Form.ControlledSelect
              name="tryb"
              control={control}
              options={modeOptions}
              label={formatMessage({ id: AppMessages['events.addEvent.mode.label'] })}
              required
            />

            <Controller
              name="data"
              control={control}
              defaultValue={new Date().toString()}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  label={formatMessage({ id: AppMessages['events.addEvent.date.label'] })}
                  maxDate={new Date()}
                  withCalendarIcon
                  withDatepickerPopover
                  isClickable
                  required
                  errorMessage={errors?.data?.message}
                  popoverOrigin={{
                    vertical: -80,
                    horizontal: 60,
                  }}
                />
              )}
            />

            <Form.ControlledSelect
              name="rcp"
              control={control}
              options={rcpOptions}
              label={formatMessage({ id: AppMessages['events.addEvent.rcp.label'] })}
              required
            />
          </Form.Grid>
        </>
      </Modal>
    </>
  );
};
