import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { ModifyWantedInformationBody } from 'api/wanted/wanted.types';
import * as Styled from '../WantedTableRow.styles';

import { ModifyWantedInformationProps } from './ModifyWantedInformation.types';

export const ModifyWantedInformation = ({
  owner,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: ModifyWantedInformationProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<ModifyWantedInformationBody>();

  return (
    <>
      <Styled.InformationIcon onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['wanted.modify.information.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.save'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['wanted.modify.information.subtitle'] })}
            {`${owner.nazwisko || ''} ${owner.imie || ''}`}
          </ActionModal.Subtitle>

          <Controller
            name="informacja"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['wanted.modify.information.label'] })}
                placeholder={formatMessage({ id: AppMessages['wanted.modify.information.placeholder'] })}
                required
                errorMessage={error?.message}
              />
            )}
          />
        </>
      </ActionModal>
    </>
  );
};
