import { format, formatISO } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { DateFormat, HistoryActionEnum } from 'core/global.enum';
import { CardBlockadeDescriptionEnum, CardUnblockDescriptionEnum } from 'api/cards/cards.enum';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { OwnerDocumentDescriptionEnum } from 'app/owners/Owners.enum';
import { useOwnerHistoryQuery } from '../hooks/useOwnerHistoryQuery';

import { BaseOwnerHistoryProps } from './BaseOwnerHistory.types';

export const BaseOwnerHistory = ({
  ownerHistoryResponse,
  selectedOwner,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  onClearOwnerHistoryQuery,
  tableSearchQuery,
  setTableSearchQuery,
  tableSearchByQuery,
  setTableSearchByQuery,
  ownerHistoryQueryKey,
}: BaseOwnerHistoryProps) => {
  const { formatMessage } = useLocale();

  const { query: ownerHistoryQuery, setOwnerHistoryQuery } = useOwnerHistoryQuery(ownerHistoryQueryKey);

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    selectedOwner?.archiwalny ? SearchQueryKeysEnum.ARCHIVED_OWNER_HISTORY : SearchQueryKeysEnum.ACTIVE_OWNER_HISTORY,
    setTableSearchQuery,
    tableSearchByQuery,
  );

  const isClearFiltersButtonVisible = Object.values(ownerHistoryQuery).some((query) => query) || !!tableSearchQuery;

  return (
    <HistoryList
      data={ownerHistoryResponse?.data || []}
      isLoading={isLoading}
      ownerActionDescriptions
      filters={{
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: () => {
          setSelectedSearchOption(null);
          onClearOwnerHistoryQuery();
        },
        render: () => (
          <>
            <HistoryDatepickerWithModal
              dates={[
                ownerHistoryQuery.dataOd ? new Date(ownerHistoryQuery.dataOd) : null,
                ownerHistoryQuery.dataDo ? new Date(ownerHistoryQuery.dataDo) : null,
              ]}
              setDates={(dates) =>
                setOwnerHistoryQuery({
                  dataOd: dates[0] ? formatISO(dates[0]) : undefined,
                  dataDo: dates[1] ? formatISO(dates[1]) : undefined,
                })
              }
            />

            <HistoryActionAutocomplete
              selectedAction={ownerHistoryQuery.akcja}
              onChangeAction={(action) => setOwnerHistoryQuery({ akcja: action })}
              historyType="owners"
            />

            <TableSearchContainer
              withoutToggle
              inputSize="big"
              searchBy="cardsAndUsers"
              tableSearchQuery={tableSearchQuery}
              setTableSearchQuery={setTableSearchQuery}
              inputPlaceholder={formatMessage({ id: AppMessages['owner.history.filters.serach.placeholder'] })}
              setTableSearchByQuery={setTableSearchByQuery}
              tableSearchByQuery={tableSearchByQuery}
              selectedValue={searchOption}
              setSelectedValue={setSelectedSearchOption}
            />
          </>
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: ownerHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.kad && history.kad.numerKarty && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['owner.history.descriptionTitle.cardNumber'] })}
              value={history.kad.numerKarty}
            />
          )}

          {history.blokada && (
            <HistoryList.ActionBodyItem
              title={
                history.akcja === HistoryActionEnum.ODBLOKOWANO
                  ? formatMessage({ id: AppMessages['cards.history.descriptionTitle.unlocking'] })
                  : formatMessage({ id: AppMessages['cards.history.descriptionTitle.blockade'] })
              }
              value={
                history.akcja === HistoryActionEnum.ODBLOKOWANO
                  ? formatMessage({ id: CardUnblockDescriptionEnum[history.blokada] })
                  : formatMessage({ id: CardBlockadeDescriptionEnum[history.blokada] })
              }
            />
          )}

          {history.powodBlokady && (
            <HistoryList.ActionBodyItem
              title={
                history.akcja === HistoryActionEnum.ODBLOKOWANO
                  ? formatMessage({ id: AppMessages['cards.history.descriptionTitle.unblockReason'] })
                  : formatMessage({ id: AppMessages['cards.history.descriptionTitle.blockadeReason'] })
              }
              value={history.powodBlokady}
            />
          )}

          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.executor'] })}
              value={`${history.wykonawca.nazwisko} ${history.wykonawca.imie}`}
            />
          )}

          {history.posiadacz && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.owner'] })}
              value={`${history.posiadacz.nazwisko} ${history.posiadacz.imie}, ${history.posiadacz.id}`}
            />
          )}

          {history.zdjecieUrl && (
            <HistoryList.ActionModifiedContainerPhotoBodyItem
              historyElementId={history.id}
              title={formatMessage({ id: AppMessages['owner.details.basic.photo'] })}
              oldValue={history.zdjecieUrl.old}
              newValue={history.zdjecieUrl.new}
            />
          )}

          {history.imie && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.name'] })}
              oldValue={history.imie.old}
              newValue={history.imie.new}
            />
          )}

          {history.nazwisko && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.lastName'] })}
              oldValue={history.nazwisko.old}
              newValue={history.nazwisko.new}
            />
          )}

          {history.drugieImie && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.secondName'] })}
              oldValue={history.drugieImie.old}
              newValue={history.drugieImie.new}
            />
          )}

          {history.firma && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.company'] })}
              oldValue={history.firma.old}
              newValue={history.firma.new}
            />
          )}

          {history.komorka && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.cell'] })}
              oldValue={history.komorka.old}
              newValue={history.komorka.new}
            />
          )}

          {history.stanowisko && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.basic.position'] })}
              oldValue={history.stanowisko.old}
              newValue={history.stanowisko.new}
            />
          )}

          {history.dokumentTozsamosci && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.document'] })}
              oldValue={
                history.dokumentTozsamosci.old &&
                formatMessage({ id: OwnerDocumentDescriptionEnum[history.dokumentTozsamosci.old] })
              }
              newValue={
                history.dokumentTozsamosci.new &&
                formatMessage({ id: OwnerDocumentDescriptionEnum[history.dokumentTozsamosci.new] })
              }
            />
          )}

          {history.numerDokumentu && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.documentNumber'] })}
              oldValue={history.numerDokumentu.old}
              newValue={history.numerDokumentu.new}
            />
          )}

          {history.dataUrodzenia && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.birthDate'] })}
              oldValue={history.dataUrodzenia.old && format(new Date(history.dataUrodzenia.old), DateFormat.DATE)}
              newValue={history.dataUrodzenia.new && format(new Date(history.dataUrodzenia.new), DateFormat.DATE)}
            />
          )}

          {history.pesel && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.pesel'] })}
              oldValue={history.pesel.old}
              newValue={history.pesel.new}
            />
          )}

          {history.email && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.email'] })}
              oldValue={history.email.old}
              newValue={history.email.new}
            />
          )}

          {history.telefon && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.phone'] })}
              oldValue={history.telefon.old}
              newValue={history.telefon.new}
            />
          )}

          {history.nrEwidencyjny && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.system.number'] })}
              oldValue={history.nrEwidencyjny.old}
              newValue={history.nrEwidencyjny.new}
            />
          )}

          {history.nrEwidencyjnyZewnetrzny && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.system.internalNumber'] })}
              oldValue={history.nrEwidencyjnyZewnetrzny.old}
              newValue={history.nrEwidencyjnyZewnetrzny.new}
            />
          )}

          {history.rcp && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.system.rcp'] })}
              oldValue={
                history.rcp.old
                  ? formatMessage({ id: AppMessages['owner.details.system.rcp.true'] })
                  : formatMessage({ id: AppMessages['owner.details.system.rcp.false'] })
              }
              newValue={
                history.rcp.new
                  ? formatMessage({ id: AppMessages['owner.details.system.rcp.true'] })
                  : formatMessage({ id: AppMessages['owner.details.system.rcp.false'] })
              }
            />
          )}

          {history.dataOd && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.system.validFrom'] })}
              oldValue={history.dataOd.old && format(new Date(history.dataOd.old), DateFormat.FULL)}
              newValue={history.dataOd.new && format(new Date(history.dataOd.new), DateFormat.FULL)}
            />
          )}

          {history.dataDo && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.system.validTo'] })}
              oldValue={history.dataDo.old && format(new Date(history.dataDo.old), DateFormat.FULL)}
              newValue={history.dataDo.new && format(new Date(history.dataDo.new), DateFormat.FULL)}
            />
          )}

          {history.opis && (
            <HistoryList.ActionModifiedLongFieldBodyItem
              title={formatMessage({ id: AppMessages['owner.details.personal.description'] })}
              oldValue={history.opis.old}
              newValue={history.opis.new}
            />
          )}
        </>
      )}
    />
  );
};
