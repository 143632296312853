import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import {
  InstantControlTerminalsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { fetchControlOwnerTerminals } from 'api/control/control';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { InstantControlTerminalsRows } from './InstantControlTerminalsRows';
import { InstantControlTerminalsRowsContainerProps } from './InstantControlTerminalsRows.types';

export const InstantControlTerminalsRowsContainer = ({
  expandedServer,
  setTerminalsCount,
}: InstantControlTerminalsRowsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const controlId = id || '';
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS]: instantControlTerminalsQuery },
  } = useLocaleStoreQuery();

  const { rowsPerPage, storedQueryWithoutPagination, offset } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS,
  });

  const { data: terminalsResponse } = useQuery(
    [QueryKeyEnum.INSTANT_CONTROL_TERMINALS, controlId, expandedServer?.id, instantControlTerminalsQuery],
    () => fetchControlOwnerTerminals({ controlId, serverId }),
    {
      args: {
        ...storedQueryWithoutPagination,
        [InstantControlTerminalsQueryKeysEnum.LIMIT]: rowsPerPage,
        [InstantControlTerminalsQueryKeysEnum.OFFSET]: offset,
      },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setTerminalsCount(response.count);
      },
    },
  );

  return <InstantControlTerminalsRows terminalsResponse={terminalsResponse} />;
};
