import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, AutocompleteOption } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import {
  AddAnnouncementAssignedOwnersProps,
  AddOwnersToAnnouncementFormBody,
} from './AddAnnouncementAssignedOwners.types';
import * as Styled from './AddAnnouncementAssignedOwners.styles';

export const AddPosition = ({
  onSubmit,
  isModalOpen,
  onModalClose,
  isLoading,
  onModalOpen,
  owners,
  announcement,
  setSelectedOwnersGroup,
}: AddAnnouncementAssignedOwnersProps) => {
  const { formatMessage } = useLocale();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddOwnersToAnnouncementFormBody>();

  const ownersOptions = owners.map((owner) => ({
    id: owner.id,
    nazwa: `${owner.nazwisko} ${owner.imie}`,
    kod: owner.nrEwidencyjny,
  }));

  return (
    <>
      <Button onClick={onModalOpen} variant="contained" color="primary">
        {formatMessage({ id: AppMessages['announcement.addOwner.button'] })}
      </Button>
      <Modal
        header={formatMessage({ id: AppMessages['announcement.addOwner.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
      >
        <Styled.Wrapper>
          <Styled.GroupName>{announcement?.nazwa}</Styled.GroupName>

          <Controller
            control={control}
            name="ids"
            render={({ field }) => (
              <OrganizationsTreeSelect
                {...field}
                multiple
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                label={formatMessage({ id: AppMessages['announcement.add.chooseOwners'] })}
                extraGroupProps={{
                  options: ownersOptions,
                  label: formatMessage({ id: AppMessages['announcement.add.owner'] }),
                  onGroupSelect: setSelectedOwnersGroup,
                  renderOption: (option) => (
                    <AutocompleteOption
                      key={option.id}
                      idPrefix={formatMessage({ id: AppMessages['tableSearch.owner.number'] })}
                      id={option.kod || ''}
                      label={option.nazwa}
                    />
                  ),
                }}
              />
            )}
          />
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
