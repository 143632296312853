import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchControlOwnerTerminals } from 'api/control/control';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { RandomControlTerminalsRows } from './RandomControlTerminalsRows';
import { RandomControlTerminalsRowsContainerProps } from './RandomControlTerminalsRows.types';

export const RandomControlTerminalsRowsContainer = ({
  expandedServer,
  setTerminalsCount,
  checkedTerminals,
  setCheckedTerminals,
}: RandomControlTerminalsRowsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const controlId = id || '';
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS]: randomControlTerminalsQuery },
  } = useLocaleStoreQuery();

  const { rowsPerPage, storedQueryWithoutPagination, offset } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS,
  });

  const { data: terminalsResponse } = useQuery(
    [QueryKeyEnum.RANDOM_CONTROL_TERMINALS, controlId, expandedServer?.id, randomControlTerminalsQuery],
    () => fetchControlOwnerTerminals({ controlId, serverId }),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset: offset,
      },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setTerminalsCount(response.count);
      },
    },
  );

  return (
    <RandomControlTerminalsRows
      checkedTerminals={checkedTerminals}
      terminalsResponse={terminalsResponse}
      setCheckedTerminals={setCheckedTerminals}
    />
  );
};
