import { useParams } from 'react-router-dom';

import { fetchGroupTerminalsPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { GroupTerminals } from './GroupTerminals';
import { GroupTerminalsContainerProps } from './GroupTerminals.types';

export const GroupTerminalsContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: GroupTerminalsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';

  const { page, rowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS,
  });

  const { data: permissionsResponse } = useQuery(
    [
      QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS,
      groupId,
      expandedServer?.id,
      storedQueryWithoutPagination,
      page,
      rowsPerPage,
    ],
    () => fetchGroupTerminalsPermissions({ groupId, serverId: expandedServer?.id || server.id }),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset: rowsPerPage * page },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <GroupTerminals
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
