import { useParams } from 'react-router-dom';

import { fetchGroupGlcPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { GroupGlc } from './GroupGlc';
import { GroupGlcContainerProps } from './GroupGlc.types';

export const GroupGlcContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: GroupGlcContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';

  const { page, rowsPerPage, offset, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS,
  });

  const { data: permissionsResponse } = useQuery(
    [QueryKeyEnum.GROUP_GLC_PERMISSIONS, groupId, expandedServer?.id, page, rowsPerPage, storedQueryWithoutPagination],
    () => fetchGroupGlcPermissions({ groupId, serverId: expandedServer?.id || server.id }),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <GroupGlc
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
