import { useState } from 'react';
import { ErrorBoundary } from 'shared-ui';

import { fetchServers } from 'api/servers/servers';
import { Terminal } from 'api/terminals/terminals.types';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { useQuery } from 'hooks/useQuery/useQuery';

import { Devices } from './Devices';

export const DevicesContainer = () => {
  const [checkedTerminals, setCheckedTerminals] = useState<Terminal[]>([]);

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.TERMINALS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers, {
    refetchInterval: 5000,
  });

  return (
    <ErrorBoundary>
      <Devices
        servers={serversResponse?.data}
        isLoading={isLoading}
        checkedTerminals={checkedTerminals}
        setCheckedTerminals={setCheckedTerminals}
        terminalsCount={paginationCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setTerminalsCount={setPaginationCount}
      />
    </ErrorBoundary>
  );
};
