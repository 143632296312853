import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';
import { SequenceTypeDescriptionEnum } from 'api/sequences/sequences.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { SequencesHistoryFilters } from './sequencesHistoryFilters/SequencesHistoryFilters';
import { SequencesHistoryProps } from './SequencesHistory.types';

export const SequencesHistory = ({
  sequencesHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  cardSearchQuery,
  setCardSearchQuery,
  onClearHistoryQuery,
}: SequencesHistoryProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SEQUENCES_HISTORY]: sequencesHistoryQuery },
  } = useLocaleStoreQuery();

  const isClearFiltersButtonVisible =
    Object.values(filterQueryFromPagination(sequencesHistoryQuery)).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery ||
    !!cardSearchQuery;

  return (
    <HistoryList
      data={sequencesHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearHistoryQuery,
        render: () => (
          <SequencesHistoryFilters
            servers={servers}
            tkdSearchQuery={tkdSearchQuery}
            executorSearchQuery={executorSearchQuery}
            cardSearchQuery={cardSearchQuery}
            setExecutorSearchQuery={setExecutorSearchQuery}
            setCardSearchQuery={setCardSearchQuery}
            setTkdSearchQuery={setTkdSearchQuery}
          />
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: sequencesHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.executor'] })}
              value={`${history.wykonawca.nazwisko} ${history.wykonawca.imie}`}
            />
          )}

          {history.typ && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.type'] })}
              value={formatMessage({ id: SequenceTypeDescriptionEnum[history.typ] })}
            />
          )}

          {history.tkds && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.server'] })}
              value={history.tkds ? history.tkds[0].server.nazwa : ''}
            />
          )}

          {history.tkds && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['sequences.history.label.readers'] },
                { count: history.tkds.length },
              )}
              items={history.tkds}
              shouldScrollToTop={!!sequencesHistoryQuery.tkd && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.kartaA && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.cardA'] })}
              value={history.kartaA}
            />
          )}

          {history.kartaB && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.cardB'] })}
              value={history.kartaB}
            />
          )}

          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['sequences.history.label.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
