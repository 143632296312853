import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useOwnerHistoryQuery } from '../hooks/useOwnerHistoryQuery';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';

import { OwnerEffectivePermissionsHistoryProps } from './OwnerEffectivePermissionsHistory.types';

export const OwnerEffectivePermissionsHistory = ({
  ownerHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  tkdSearchQuery,
  setTkdSearchQuery,
  executorSearchQuery,
  setExecutorSearchQuery,
  onClearOwnerHistoryQuery,
}: OwnerEffectivePermissionsHistoryProps) => {
  const { formatMessage } = useLocale();

  const { query: ownerHistoryQuery, setOwnerHistoryQuery } = useOwnerHistoryQuery(
    QueryKeyLocalStorageEnum.OWNER_EFFECTIVE_PERMISSIONS_HISTORY,
  );

  const serversOptions =
    servers?.map((server) => ({
      label: `${server.nazwa}`,
      value: server.id,
    })) || [];

  const isClearFiltersButtonVisible =
    Object.values(filterQueryFromPagination(ownerHistoryQuery)).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery;

  return (
    <HistoryList
      data={ownerHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearOwnerHistoryQuery,
        render: () => (
          <>
            <HistoryDatepickerWithModal
              dates={[
                ownerHistoryQuery.dataOd ? new Date(ownerHistoryQuery.dataOd) : null,
                ownerHistoryQuery.dataDo ? new Date(ownerHistoryQuery.dataDo) : null,
              ]}
              setDates={(dates) =>
                setOwnerHistoryQuery({
                  dataOd: dates[0] ? formatISO(dates[0]) : undefined,
                  dataDo: dates[1] ? formatISO(dates[1]) : undefined,
                })
              }
            />

            <HistoryActionAutocomplete
              selectedAction={ownerHistoryQuery.akcja}
              onChangeAction={(action) => setOwnerHistoryQuery({ akcja: action })}
              historyType="ownersEffectivePermissions"
            />

            <Autocomplete
              onChange={(_, option) => setOwnerHistoryQuery({ serwer: option || undefined, tkd: undefined })}
              value={serversOptions.find((server) => ownerHistoryQuery.serwer?.value === server.value) || null}
              options={serversOptions}
              loadingText={formatMessage({ id: AppMessages['common.loading'] })}
              noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
              placeholder={formatMessage({ id: AppMessages['group.history.search.server'] })}
              inputVariant="outlined"
              inputSize="big"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              stopRotatePopupIndicator
            />

            <TableSearchContainer
              withoutToggle
              inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.reader'] })}
              searchBy="terminals"
              tableSearchQuery={tkdSearchQuery}
              setTableSearchQuery={setTkdSearchQuery}
              selectedValue={ownerHistoryQuery.tkd}
              setSelectedValue={(option) => setOwnerHistoryQuery({ tkd: option || undefined })}
              inputSize="big"
              isDisabled={!ownerHistoryQuery.serwer}
              serverId={ownerHistoryQuery.serwer?.value as string}
            />

            <TableSearchContainer
              withoutToggle
              inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.executor'] })}
              searchBy="users"
              tableSearchQuery={executorSearchQuery}
              setTableSearchQuery={setExecutorSearchQuery}
              selectedValue={ownerHistoryQuery.wykonawca}
              setSelectedValue={(option) => setOwnerHistoryQuery({ wykonawca: option || undefined })}
              inputSize="big"
            />
          </>
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: ownerHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.tkds && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.serwer'] })}
              value={history.tkds[0].server.nazwa}
            />
          )}

          {history.tkds && (
            <HistoryList.ActionExpandablePermissionsBodyItem
              action={history.akcja}
              title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.reader'] })}
              shouldScrollToTop={!!ownerHistoryQuery.tkd && !isLoading}
              data={history.tkds.map((tkd) => ({
                ...tkd,
                numer: tkd.numer,
              }))}
            />
          )}

          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.executor'] })}
              value={`${history.wykonawca.nazwisko} ${history.wykonawca.imie}`}
            />
          )}

          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
