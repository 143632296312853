import { useParams } from 'react-router-dom';

import { fetchServersForGroupGlcPermissions } from 'api/servers/servers';
import { fetchGroupDetails } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { GroupGlcPermissions } from './GroupGlcPermissions';

export const GroupGlcPermissionsContainer = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { selectedGroup } = useSelectedGroup();
  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.GROUP_GLC_PERMISSIONS_SERVERS, groupId], () =>
    fetchServersForGroupGlcPermissions(groupId),
  );

  const { data: groupDetails } = useQuery([QueryKeyEnum.GROUP_DETAILS, groupId], () => fetchGroupDetails(groupId), {
    enabled: !selectedGroup,
  });

  return (
    <GroupGlcPermissions
      servers={serversResponse?.data}
      isLoading={isLoading}
      permissionsCount={paginationCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      groupName={selectedGroup?.nazwa || groupDetails?.nazwa || ''}
      setPermissionsCount={setPaginationCount}
    />
  );
};
