import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteWantedProps } from './DeleteWanted.types';
import * as Styled from './DeleteWanted.styles';

export const DeleteWanted = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  owner,
}: DeleteWantedProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.TrashIcon onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['wanted.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['wanted.delete.subtitle'] }, { name: `${owner.nazwisko} ${owner.imie}` })}
        </ActionModal.Subtitle>
        <ActionModal.Text>{formatMessage({ id: AppMessages['wanted.delete.text'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
