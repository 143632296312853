import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteSequence } from 'api/sequences/sequences';

import { DeleteSequence } from './DeleteSequence';
import { DeleteSequenceContainerProps } from './DeleteSequence.types';

export const DeleteSequenceContainer = ({ sequence }: DeleteSequenceContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteSequence, isLoading } = useMutation(deleteSequence, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SEQUENCES]);
      toast.dark(formatMessage({ id: AppMessages['sequences.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteSequence(sequence.id);
  };

  return (
    <DeleteSequence
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
