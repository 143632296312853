import { DrawerLayout, UsersIcon, useLocale } from 'shared-ui';

import { ReactComponent as DevicesIcon } from 'assets/icons/devices.svg';
import { ReactComponent as DevicesActiveIcon } from 'assets/icons/devices-active.svg';
import { ReactComponent as CardsIcon } from 'assets/icons/cards.svg';
import { ReactComponent as CardsActiveIcon } from 'assets/icons/cards-active-drawer.svg';
import { ReactComponent as OwnersIcon } from 'assets/icons/owners.svg';
import { ReactComponent as OwnersActiveIcon } from 'assets/icons/owners-active.svg';
import { ReactComponent as EventsIcon } from 'assets/icons/events.svg';
import { ReactComponent as EventsActiveIcon } from 'assets/icons/events-active.svg';
import { ReactComponent as DefinitionsActiveIcon } from 'assets/icons/definitions-active.svg';
import { ReactComponent as DefinitionsIcon } from 'assets/icons/definitions.svg';
import { ReactComponent as GroupsActiveIcon } from 'assets/icons/groups-active.svg';
import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import { ReactComponent as GlcIcon } from 'assets/icons/logic-group.svg';
import { ReactComponent as GlcActiveIcon } from 'assets/icons/logic-group-active.svg';
import { ReactComponent as ControlActiveIcon } from 'assets/icons/control-active.svg';
import { ReactComponent as ControlIcon } from 'assets/icons/control.svg';
import { ReactComponent as WantedActiveIcon } from 'assets/icons/wanted-active.svg';
import { ReactComponent as WantedIcon } from 'assets/icons/wanted.svg';
import { ReactComponent as AnnouncementsActiveIcon } from 'assets/icons/announcements-active.svg';
import { ReactComponent as AnnouncementsIcon } from 'assets/icons/announcements.svg';
import { ReactComponent as SchedulesActiveIcon } from 'assets/icons/schedules-active.svg';
import { ReactComponent as SchedulesIcon } from 'assets/icons/schedules.svg';
import { ReactComponent as SequencesActiveIcon } from 'assets/icons/sequences-active.svg';
import { ReactComponent as SequencesIcon } from 'assets/icons/sequences.svg';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useResetSelected } from 'hooks/useResetSelected/useResetSelected';
import { AppRoute } from 'routing/AppRoute.enum';
import { LogoContainer } from 'ui/logo/LogoContainer';
import { AppMessages } from 'i18n/messages';

export const Navbar = () => {
  const { user, isAdmin } = useAuth();
  const { resetQueryState } = useLocaleStoreQuery();
  const { resetAllSelected } = useResetSelected();
  const { formatMessage } = useLocale();

  const drawerRoutes = [
    {
      icon: <DevicesIcon />,
      activeIcon: <DevicesActiveIcon />,
      to: AppRoute.devices,
      title: formatMessage({ id: AppMessages['nav.devices'] }),
      isVisible: true,
    },
    {
      icon: <CardsIcon />,
      activeIcon: <CardsActiveIcon />,
      to: AppRoute.cards,
      title: formatMessage({ id: AppMessages['nav.cards'] }),
      isVisible: true,
    },
    {
      icon: <OwnersIcon />,
      activeIcon: <OwnersActiveIcon />,
      to: AppRoute.owners,
      title: formatMessage({ id: AppMessages['nav.owners'] }),
      isVisible: true,
    },
    {
      icon: <WantedIcon />,
      activeIcon: <WantedActiveIcon />,
      to: AppRoute.wanted,
      title: formatMessage({ id: AppMessages['nav.wanted'] }),
      isVisible: true,
    },
    {
      icon: <AnnouncementsIcon />,
      activeIcon: <AnnouncementsActiveIcon />,
      to: AppRoute.announcements,
      title: formatMessage({ id: AppMessages['nav.announcements'] }),
      isVisible: true,
    },
    {
      icon: <ControlIcon />,
      activeIcon: <ControlActiveIcon />,
      to: AppRoute.controlRandom,
      title: formatMessage({ id: AppMessages['nav.control'] }),
      isVisible: true,
    },
    {
      icon: <SchedulesIcon />,
      activeIcon: <SchedulesActiveIcon />,
      to: AppRoute.schedules,
      title: formatMessage({ id: AppMessages['nav.schedules'] }),
      isVisible: true,
    },
    {
      icon: <EventsIcon />,
      activeIcon: <EventsActiveIcon />,
      to: AppRoute.events,
      title: formatMessage({ id: AppMessages['nav.events'] }),
      isVisible: true,
    },
    {
      icon: <DefinitionsIcon />,
      activeIcon: <DefinitionsActiveIcon />,
      to: AppRoute.definitions,
      title: formatMessage({ id: AppMessages['nav.definitions'] }),
      isVisible: true,
    },
    {
      icon: <SequencesIcon />,
      activeIcon: <SequencesActiveIcon />,
      to: AppRoute.sequences,
      title: formatMessage({ id: AppMessages['nav.sequences'] }),
      isVisible: true,
    },
    {
      icon: <GroupsIcon />,
      activeIcon: <GroupsActiveIcon />,
      to: AppRoute.groups,
      title: formatMessage({ id: AppMessages['nav.groups'] }),
      isVisible: true,
    },
    {
      icon: <GlcIcon />,
      activeIcon: <GlcActiveIcon />,
      to: AppRoute.glc,
      title: formatMessage({ id: AppMessages['nav.glc'] }),
      isVisible: true,
    },
    {
      icon: <UsersIcon />,
      activeIcon: <UsersIcon active />,
      to: AppRoute.users,
      title: formatMessage({ id: AppMessages['nav.users'] }),
      isVisible: isAdmin,
    },
  ];

  return (
    <DrawerLayout
      logo={<LogoContainer />}
      drawerRoutes={drawerRoutes}
      profileRoute={{ to: AppRoute.profile, title: user?.username || '' }}
      onClickRoute={() => {
        resetQueryState();
        resetAllSelected();
      }}
    />
  );
};
