import { useMemo } from 'react';
import isEqual from 'lodash.isequal';

import { ReactComponent as ArrowUp } from 'assets/icons/sort-arrow-up.svg';
import { ReactComponent as ArrowUpPrimary } from 'assets/icons/sort-arrow-up-primary.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as ArrowDownPrimary } from 'assets/icons/sort-arrow-down-primary.svg';
import { ReactComponent as InactiveSort } from 'assets/icons/inactive-sort.svg';
import { IS_SORT_DIRTY, SortKeysWithValues } from 'context/query/queryContext/QueryContext.types';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import * as Styled from '../Table.styles';

import { HeaderSortItemProps } from './HeaderSortItem.types';

const HeaderSortItem = <T extends keyof SortKeysWithValues>({
  queryHeader,
  queryKey,
  sortBy,
}: HeaderSortItemProps<T>) => {
  const {
    storedQueries: { [queryKey]: storedQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const sortQuery = Object.keys(storedQuery).length
    ? (storedQuery['sort'] as unknown as SortKeysWithValues[keyof SortKeysWithValues])
    : undefined;

  const isSortActive = sortQuery?.includes(sortBy.ASC) || sortQuery?.includes(sortBy.DESC);

  const isSorted = sortQuery?.includes(sortBy.ASC);

  const isSortedByDefault = !storedQuery[IS_SORT_DIRTY] && isEqual(sortQuery, DEFAULT_QUERY_STATE[queryKey]['sort']);

  const onClickSort = () => {
    setQuery(queryKey, { ...storedQuery, [IS_SORT_DIRTY]: true, sort: [isSorted ? sortBy.DESC : sortBy.ASC] });
  };

  const sortIcon = useMemo(() => {
    if (isSortActive) {
      if (isSortedByDefault) {
        return isSorted ? <ArrowUp /> : <ArrowDown />;
      } else {
        return isSorted ? <ArrowUpPrimary /> : <ArrowDownPrimary />;
      }
    } else {
      return <InactiveSort />;
    }
  }, [isSortActive, isSorted, isSortedByDefault]);

  return (
    <Styled.TableHeaderItem>
      <Styled.TableHeaderItemSortable data-testid={`sort-${sortBy.ASC}`} onClick={onClickSort}>
        <Styled.TableHeaderItemText>{queryHeader}</Styled.TableHeaderItemText>
        {sortIcon}
      </Styled.TableHeaderItemSortable>
    </Styled.TableHeaderItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default HeaderSortItem;
