import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServersForSchedule } from 'api/schedules/schedules';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { ScheduleTerminals } from './ScheduleTerminals';

export const ScheduleTerminalsContainer = () => {
  const { id: scheduleId } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULE_TERMINALS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SCHEDULE_TERMINALS, scheduleId], () =>
    fetchServersForSchedule(scheduleId),
  );

  return (
    <ScheduleTerminals
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      terminalsCount={paginationCount}
      setTerminalsCount={setPaginationCount}
      scheduleId={scheduleId || ''}
    />
  );
};
