import { useParams } from 'react-router-dom';

import { fetchServersForOwnerIndividualPermissions } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { OwnerIndividualPermissions } from './OwnerIndividualPermissions';

export const OwnerIndividualPermissionsContainer = () => {
  const { id: ownerId } = useParams<{ id: string }>();
  const { ownerPermissionsIndividualQueryKey } = useOwnerQueryKeys();

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: ownerPermissionsIndividualQueryKey,
  });

  const { data: serversResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS, ownerId],
    () => fetchServersForOwnerIndividualPermissions(ownerId),
  );

  return (
    <OwnerIndividualPermissions
      servers={serversResponse?.data || []}
      isLoading={isLoading}
      permissionsCount={paginationCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setPermissionsCount={setPaginationCount}
    />
  );
};
