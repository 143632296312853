import { useNavigate, useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { AddPositionContainer } from '../positions/addPosition/AddPositionContainer';
import { AddEventExportFormatPageModeEnum } from '../eventsExportFormats/addEventExportFormat/AddEventExportFormat.enum';
import { AddTerminalsDisplayContainer } from '../display/addTerminalsDisplay/AddTerminalsDisplayContainer';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchOrganizations } from 'api/organizations/organizations';
import { QueryKeyEnum } from 'core/global.enum';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { AddTerminalsSequenceContainer } from '../sequences/addTerminalsSequence/AddTerminalsSequenceContainer';

import { DefinitionsBarProps } from './DefinitionsBar.types';

export const DefinitionsBar = ({
  setPositionsTableSearchQuery,
  searchOrganizationId,
  setSearchOrganizationId,
}: DefinitionsBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const { data: organizationResponse } = useQuery([QueryKeyEnum.ORGANIZATIONS], () => fetchOrganizations());

  const organization = organizationResponse ? organizationResponse[organizationResponse.length - 1] : undefined;
  const selectedOrganization =
    organization && searchOrganizationId
      ? getObjectInNestedObjectByKey(organization, 'id', searchOrganizationId)
      : undefined;

  const currentPageBarComponents = {
    [AppRoute.definitions]: (
      <TopBar.TableSearchWrapper>
        <OrganizationsTreeSelect
          isSearchType
          onChange={(val) => setSearchOrganizationId && setSearchOrganizationId(val?.id)}
          value={selectedOrganization ? selectedOrganization.object : undefined}
          placeholder={formatMessage({ id: AppMessages['definitions.organization.search'] })}
        />
      </TopBar.TableSearchWrapper>
    ),
    [AppRoute.positions]: (
      <>
        {setPositionsTableSearchQuery && (
          <TopBar.Search>
            <TableSearchContainer
              searchIconTestId="page-search"
              inputPlaceholder={formatMessage({ id: AppMessages['definitions.positions.search'] })}
              searchBy="positions"
              setTableSearchQuery={setPositionsTableSearchQuery}
            />
          </TopBar.Search>
        )}
        <TopBar.ButtonsWrapper>
          <AddPositionContainer />
        </TopBar.ButtonsWrapper>
      </>
    ),
    [AppRoute.eventsExportFormats]: (
      <TopBar.Button
        onClick={() =>
          navigate(AppRouteWithParams.eventsExportFormats.get({ mode: AddEventExportFormatPageModeEnum.ADD }))
        }
        variant="contained"
        color="primary"
      >
        {formatMessage({ id: AppMessages['definitions.events.addExportFormat.title'] })}
      </TopBar.Button>
    ),
    [AppRoute.ownersControl]: <TopBar.EmptyBarWithLine />,
    [AppRoute.display]: (
      <TopBar.ButtonsWrapper>
        <AddTerminalsDisplayContainer />
      </TopBar.ButtonsWrapper>
    ),
    [AppRoute.logotype]: <TopBar.EmptyBarWithLine />,
    [AppRoute.sequencesTerminals]: (
      <TopBar.ButtonsWrapper>
        <AddTerminalsSequenceContainer />
      </TopBar.ButtonsWrapper>
    ),
  }[location.pathname];

  return (
    <>
      <TopBar gridTemplateColumns="auto auto auto auto auto auto auto 1fr auto">
        <TopBar.Link linkTo={AppRoute.definitions} isActive={location.pathname === AppRoute.definitions}>
          {formatMessage({ id: AppMessages['definitions.organization'] })}
        </TopBar.Link>

        <TopBar.Link linkTo={AppRoute.positions} isActive={location.pathname === AppRoute.positions}>
          {formatMessage({ id: AppMessages['definitions.positions'] })}
        </TopBar.Link>

        {isAdmin ? (
          <TopBar.Link
            linkTo={AppRoute.eventsExportFormats}
            isActive={location.pathname === AppRoute.eventsExportFormats}
          >
            {formatMessage({ id: AppMessages['definitions.events'] })}
          </TopBar.Link>
        ) : (
          <TopBar.Header />
        )}

        <TopBar.Link linkTo={AppRoute.ownersControl} isActive={location.pathname === AppRoute.ownersControl}>
          {formatMessage({ id: AppMessages['definitions.control'] })}
        </TopBar.Link>

        <TopBar.Link linkTo={AppRoute.display} isActive={location.pathname === AppRoute.display}>
          {formatMessage({ id: AppMessages['definitions.display.title'] })}
        </TopBar.Link>

        <TopBar.Link linkTo={AppRoute.logotype} isActive={location.pathname === AppRoute.logotype}>
          {formatMessage({ id: AppMessages['definitions.logotype.title'] })}
        </TopBar.Link>

        <TopBar.Link linkTo={AppRoute.sequencesTerminals} isActive={location.pathname === AppRoute.sequencesTerminals}>
          {formatMessage({ id: AppMessages['definitions.sequences.title'] })}
        </TopBar.Link>

        {currentPageBarComponents}
      </TopBar>
    </>
  );
};
