import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServers } from 'api/servers/servers';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { SchedulesList } from './SchedulesList';
import { SchedulesListContainerProps } from './SchedulesList.types';

export const SchedulesListContainer = ({ schedulesTableSearchQuery }: SchedulesListContainerProps) => {
  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULES,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  return (
    <SchedulesList
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      schedulesCount={paginationCount}
      setSchedulesCount={setPaginationCount}
      schedulesTableSearchQuery={schedulesTableSearchQuery}
    />
  );
};
