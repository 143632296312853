"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const Tooltip_1 = require("../tooltip/Tooltip");
const InfoIcon_1 = require("../../assets/icons/InfoIcon");
const Styled = __importStar(require("./Select.styles"));
exports.Select = (0, react_1.forwardRef)(({ label, required, isError, withHelperText, errorMessage, helperText, variant = 'filled', placeholder, testId, options, tooltipContent, ...props }, ref) => {
    return ((0, jsx_runtime_1.jsxs)("div", { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.Label, { "$withTooltip": !!tooltipContent, children: [label, " ", required && (0, jsx_runtime_1.jsx)(Styled.Required, { children: "*" }), tooltipContent && ((0, jsx_runtime_1.jsx)(Styled.TooltipWrapper, { children: (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, { title: tooltipContent, placement: "bottom-start", children: (0, jsx_runtime_1.jsx)(InfoIcon_1.InfoIcon, {}) }) }))] })), (0, jsx_runtime_1.jsx)(Styled.Select, { inputRef: ref, variant: variant, size: "small", input: variant === 'outlined' ? (0, jsx_runtime_1.jsx)(material_1.OutlinedInput, {}) : (0, jsx_runtime_1.jsx)(Styled.FilledInput, { disableUnderline: true }), MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    disableScrollLock: true,
                }, inputProps: {
                    'data-testid': testId,
                }, fullWidth: true, "$isError": isError, displayEmpty: !!placeholder, renderValue: !!props.value
                    ? undefined
                    : () => (0, jsx_runtime_1.jsx)(Styled.SelectPlaceholder, { "$isError": !!errorMessage, children: placeholder }), ...props, value: props.value || '', children: options
                    ? options.map((option) => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: option.value, children: option.label }, option.value)))
                    : props.children }), withHelperText && ((0, jsx_runtime_1.jsx)(Styled.FormHelperText, { "$isError": !!errorMessage, "$isHelperText": !!helperText, children: errorMessage ? errorMessage : helperText ? helperText : ' ' }))] }));
});
