import { useFormContext } from 'react-hook-form';
import { Modal, Button, Option } from 'shared-ui';

import { CardThumbnail } from 'ui/cardThumbnail/CardThumbnail';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchOwners } from 'api/owners/owners';
import { ExpiryDateFields } from 'reusable/form/expiryDateFields/ExpiryDateFields';
import { PinCodesFields } from 'reusable/form/pinFields/PinCodesFields';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';

import { AssignCardOwnerFormBody, OwnerAssignProps } from './OwnerAssign.types';
import * as Styled from './OwnerAssign.styles';

export const OwnerAssign = ({
  handleFormSubmit,
  isLoadingAssignOwner,
  isAssignModalOpen,
  onOpenAssignModal,
  onCloseAssignModal,
  selectedCard,
  isCardBlockedManually,
  isFormOwnerArchived,
}: OwnerAssignProps) => {
  const { formatMessage } = useLocale();
  const { handleSubmit, formState } = useFormContext<AssignCardOwnerFormBody>();
  const isCardBlocked = selectedCard.blokada !== CardBlockTypeEnum.ODBLOKOWANA;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
    }

    return handleSubmit(async (values) => handleFormSubmit(values))(event);
  };

  return (
    <>
      <Button
        disabled={selectedCard.archiwalna}
        onClick={onOpenAssignModal}
        size="small"
        variant="outlined"
        color="primary"
      >
        {formatMessage({ id: AppMessages['card.details.form.button.owner'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['card.assign.title'] })}
        open={isAssignModalOpen}
        onClose={onCloseAssignModal}
      >
        <Styled.AssignForm onSubmit={onSubmit}>
          <Styled.InputsWrapper>
            <FormAutocompleteContainer
              formFieldName="pkd"
              queryKey={QueryKeyEnum.OWNERS}
              queryFunction={fetchOwners}
              args={{
                sort: 'nrEwidencyjny',
              }}
              createOption={(owner) => {
                return owner.id
                  ? {
                      label: `${owner.nazwisko || ''} ${owner.imie || ''}`,
                      value: owner.id,
                      archiwalny: owner.archiwalny,
                    }
                  : (owner as unknown as Option);
              }}
              handleOptionSelect={(option) => option}
              renderCustomOption={(props, option, owners) => (
                <li {...props} key={`${option.label}-${option.value}`}>
                  <Styled.OptionNumber>
                    {formatMessage({ id: AppMessages['card.assign.owner.number'] })}{' '}
                    {owners ? owners.find((owner) => owner.id === option.value)?.nrEwidencyjny || 0 : ''}
                  </Styled.OptionNumber>

                  <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
                </li>
              )}
              label={formatMessage({ id: AppMessages['card.assign.owner.label'] })}
              placeholder={formatMessage({ id: AppMessages['card.assign.owner.placeholder'] })}
              required
              withPopupSearchIcon
            />
            <Styled.OwnerStatus $isVisible={!!isFormOwnerArchived}>
              {formatMessage({
                id: AppMessages['card.assign.owner.archived'],
              })}
            </Styled.OwnerStatus>

            <Styled.ExpiryDateFieldsWrapper $isBlocked={isCardBlockedManually || isFormOwnerArchived}>
              <ExpiryDateFields isBlocked={isCardBlockedManually || isFormOwnerArchived} />
            </Styled.ExpiryDateFieldsWrapper>

            <PinCodesFields isDisabled={isCardBlocked || isFormOwnerArchived} />

            {isCardBlockedManually && (
              <Styled.BlockInfo>
                {formatMessage({
                  id: AppMessages['card.assign.block.info'],
                })}
              </Styled.BlockInfo>
            )}
          </Styled.InputsWrapper>

          <Styled.Thumbnail>
            <CardThumbnail
              cardType={selectedCard.typKarty}
              status={selectedCard.status}
              blockTime={selectedCard.czasBlokady}
              createdAt={selectedCard.createdAt}
              cardNumber={selectedCard.numerKarty}
              blocked={isCardBlockedManually}
              isTkdProblem={selectedCard.problemTkd}
              archived={selectedCard.archiwalna}
              issuedTime={selectedCard.czasWydania}
              archiveTime={selectedCard.czasArchiwizacji}
            />
          </Styled.Thumbnail>

          <Styled.ButtonsWrapper>
            <Button variant="text" color="primary" size="small" onClick={onCloseAssignModal}>
              {formatMessage({ id: AppMessages['card.assign.cancel'] })}
            </Button>

            <Button
              data-testid="assign-owner-submit"
              isLoading={isLoadingAssignOwner}
              disabled={!formState.isValid || isFormOwnerArchived}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              {formatMessage({ id: AppMessages['card.assign.confirm'] })}
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.AssignForm>
      </Modal>
    </>
  );
};
