import { useParams } from 'react-router-dom';

import { fetchGroupsForSpecificOwner } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { OwnerGroups } from './OwnerGroups';
import { OwnerGroupsContainerProps } from './OwnerGroups.types';

export const OwnerGroupsContainer = ({ ownerGroupsTableSearchQuery }: OwnerGroupsContainerProps) => {
  const { id: ownerId } = useParams<{ id: string }>();
  const { ownerGroupsQueryKey } = useOwnerQueryKeys();
  const {
    storedQueries: { [ownerGroupsQueryKey]: ownerGroupsQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage, setPaginationCount, storedQueryWithoutPagination } =
    useSavedPagination({
      queryKey: ownerGroupsQueryKey,
      resetPaginationDependencyArray: [ownerGroupsTableSearchQuery],
    });

  const { data: groupsResponse } = useQuery(
    [QueryKeyEnum.OWNER_GROUPS, ownerId, ownerGroupsQuery, storedQueryWithoutPagination, page, rowsPerPage],
    () => fetchGroupsForSpecificOwner(ownerId),
    {
      args: {
        ...storedQueryWithoutPagination,
        query: ownerGroupsTableSearchQuery,
        queryById: true,
        offset: rowsPerPage * page,
        limit: rowsPerPage,
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  return (
    <OwnerGroups
      groupsResponse={groupsResponse}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
