import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteTerminalProps } from './DeleteTerminal.types';
import * as Styled from './DeleteTerminal.styles';

export const DeleteTerminal = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  cannotDelete,
}: DeleteTerminalProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.TrashIcon onClick={onModalOpen} style={{ cursor: 'pointer' }} />

      <Modal
        header={formatMessage({
          id: AppMessages['definitions.sequences.delete.title'],
        })}
        open={isModalOpen}
        onClose={onModalClose}
        submitButtonDisabled={isLoading}
        onSubmit={cannotDelete ? onModalClose : onSubmit}
        submitButtonText={formatMessage({ id: AppMessages[cannotDelete ? 'common.ok' : 'common.delete'] })}
        size="medium"
        type={cannotDelete ? 'info' : 'form'}
      >
        {cannotDelete ? (
          <>
            <Styled.CannotDeleteErrorText>
              {formatMessage({ id: AppMessages['definitions.sequences.delete.error.cannotDelete'] })}
            </Styled.CannotDeleteErrorText>

            <Styled.CannotDeleteErrorText>
              {formatMessage({ id: AppMessages['definitions.sequences.delete.error.message'] })}
            </Styled.CannotDeleteErrorText>
          </>
        ) : (
          <>
            <ActionModal.Text>
              {formatMessage({ id: AppMessages['definitions.sequences.delete.text'] })}
            </ActionModal.Text>
          </>
        )}
      </Modal>
    </>
  );
};
