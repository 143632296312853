import { EmptyList, useLocale } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, SequencesQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';

import { SequencesTableProps } from './SequencesTable.types';
import { SequencesTableRow } from './sequencesTableRow/SequencesTableRow';
import { SequencesTableHeaderContainer } from './sequencesTableHeader/SequencesTableHeaderContainer';

export const SequencesTable = ({
  expandedServer,
  sequencesResponse,
  sequencesTableSearchQuery,
}: SequencesTableProps) => {
  const { formatMessage } = useLocale();
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SEQUENCES]: sequencesQuery },
  } = useLocaleStoreQuery();

  const areSomeFiltersSelected = Object.entries(sequencesQuery).some(
    ([key, value]) =>
      ![SequencesQueryKeysEnum.SORT, SequencesQueryKeysEnum.LIMIT, SequencesQueryKeysEnum.OFFSET].includes(
        key as SequencesQueryKeysEnum,
      ) && !!value,
  );

  return (
    <Table
      gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 140px"
      data={sequencesResponse?.data || []}
      nothingToShowText={
        areSomeFiltersSelected || !!sequencesTableSearchQuery ? (
          <EmptyList
            iconType="loop"
            mainText={formatMessage({ id: AppMessages['sequences.noResults.mainText'] })}
            helperText={formatMessage({ id: AppMessages['sequences.noResults.helperText'] })}
          />
        ) : (
          formatMessage({ id: AppMessages['sequences.noResults.nothingToShowText'] })
        )
      }
      renderHeader={() => <SequencesTableHeaderContainer expandedServer={expandedServer} />}
      renderRow={(sequence) => <SequencesTableRow sequence={sequence} />}
      customTableHeight={() => undefined}
    />
  );
};
