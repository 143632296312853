import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServers } from 'api/servers/servers';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { AnnouncementsList } from './AnnouncementsList';
import { AnnouncementsListContainerProps } from './AnnouncementsList.types';

export const AnnouncementsListContainer = ({ announcementsTableSearchQuery }: AnnouncementsListContainerProps) => {
  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.ANNOUNCEMENTS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  return (
    <AnnouncementsList
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      announcementsCount={paginationCount}
      setAnnouncementsCount={setPaginationCount}
      announcementsTableSearchQuery={announcementsTableSearchQuery}
    />
  );
};
