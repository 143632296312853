import { useParams } from 'react-router-dom';

import { fetchServersForGroupTerminalsPermissions } from 'api/servers/servers';
import { fetchGroupDetails } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { GroupTerminalsPermissions } from './GroupTerminalsPermissions';

export const GroupTerminalsPermissionsContainer = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { selectedGroup } = useSelectedGroup();

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS,
  });

  const { data: serversResponse, isLoading } = useQuery(
    [QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS, groupId],
    () => fetchServersForGroupTerminalsPermissions(groupId),
  );

  const { data: groupDetails } = useQuery([QueryKeyEnum.GROUP_DETAILS, groupId], () => fetchGroupDetails(groupId), {
    enabled: !selectedGroup,
  });

  return (
    <GroupTerminalsPermissions
      servers={serversResponse?.data}
      isLoading={isLoading}
      permissionsCount={paginationCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      groupName={selectedGroup?.nazwa || groupDetails?.nazwa || ''}
      setPermissionsCount={setPaginationCount}
    />
  );
};
