import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchOwnerHistory } from 'api/owners/owners';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import {
  OwnerHistoryQueryKeysEnum,
  OwnerHistorySearchByEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useOwnerHistoryQuery } from '../hooks/useOwnerHistoryQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { BaseOwnerHistory } from './BaseOwnerHistory';
import { BaseOwnerHistoryContainerProps } from './BaseOwnerHistory.types';

export const BaseOwnerHistoryContainer = ({ selectedOwner }: BaseOwnerHistoryContainerProps) => {
  const { id } = useParams<{ id: string }>();

  const ownerHistoryQueryKey = selectedOwner?.archiwalny
    ? QueryKeyLocalStorageEnum.ARCHIVED_OWNER_HISTORY
    : QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY;

  const { query: ownerHistoryQuery, clearOwnerHistoryQuery } = useOwnerHistoryQuery(ownerHistoryQueryKey);

  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const [tableSearchByQuery, setTableSearchByQuery] = useState<OwnerHistorySearchByEnum>(OwnerHistorySearchByEnum.USER);
  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey: ownerHistoryQueryKey,
      resetPaginationDependencyArray: [tableSearchQuery],
    });

  const { data: ownerHistoryResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNER_HISTORY, id, ownerHistoryQuery, rowsPerPage, page, tableSearchQuery],
    () => fetchOwnerHistory(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        [OwnerHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [OwnerHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [OwnerHistoryQueryKeysEnum.QUERY]: tableSearchQuery,
      },
      onSuccess: (res) => {
        setPaginationCount(res.count);
      },
    },
  );

  const onClearOwnerHistoryQuery = () => {
    clearOwnerHistoryQuery();
    setTableSearchQuery('');
  };

  return (
    <BaseOwnerHistory
      ownerHistoryResponse={ownerHistoryResponse}
      selectedOwner={selectedOwner}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      onClearOwnerHistoryQuery={onClearOwnerHistoryQuery}
      tableSearchQuery={tableSearchQuery}
      setTableSearchQuery={setTableSearchQuery}
      tableSearchByQuery={tableSearchByQuery}
      setTableSearchByQuery={setTableSearchByQuery}
      ownerHistoryQueryKey={ownerHistoryQueryKey}
    />
  );
};
