import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Terminal } from 'api/terminals/terminals.types';
import { fetchGlcTerminals } from 'api/glc/glc';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { GlcTerminals } from './GlcTerminals';

export const GlcTerminalsContainer = () => {
  const [checkedGlcTerminals, setCheckedGlcTerminals] = useState<Terminal[]>([]);
  const { page, rowsPerPage, setPage, setRowsPerPage, offset, setPaginationCount, storedQueryWithoutPagination } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.GLC_TERMINALS,
    });
  const { id } = useParams<{ id: string }>();

  const { data: glcTerminalsResponse, isLoading } = useQuery(
    [QueryKeyEnum.GLC_TERMINALS, storedQueryWithoutPagination, page, rowsPerPage, id],
    () => fetchGlcTerminals(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset,
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  return (
    <GlcTerminals
      glcTerminalsResponse={glcTerminalsResponse}
      isLoading={isLoading}
      checkedGlcTerminals={checkedGlcTerminals}
      setCheckedGlcTerminals={setCheckedGlcTerminals}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      glcId={id || ''}
    />
  );
};
