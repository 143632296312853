import { useParams } from 'react-router-dom';

import { fetchOwnerEffectivePermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { OwnerEffectiveTerminalsPermissions } from './OwnerEffectiveTerminalsPermissions';
import { OwnerEffectiveTerminalsPermissionsContainerProps } from './OwnerEffectiveTerminalsPermissions.types';

export const OwnerEffectiveTerminalsPermissionsContainer = ({
  server,
  expandedServer,
  setPermissionsCount,
}: OwnerEffectiveTerminalsPermissionsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const { ownerPermissionsEffectiveQueryKey } = useOwnerQueryKeys();

  const {
    storedQueries: { [ownerPermissionsEffectiveQueryKey]: ownerPermissionsEffectiveQuery },
  } = useLocaleStoreQuery();

  const { rowsPerPage, offset, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: ownerPermissionsEffectiveQueryKey,
  });

  const { data: permissionsResponse } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_EFFECTIVE, ownerId, expandedServer?.id, ownerPermissionsEffectiveQuery],
    () => fetchOwnerEffectivePermissions({ ownerId, serverId: expandedServer?.id || server.id }),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset },
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
      enabled: !!expandedServer,
    },
  );

  return <OwnerEffectiveTerminalsPermissions permissions={permissionsResponse?.data || []} />;
};
