import { Pagination } from 'shared-ui';

import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';

import { SchedulesTableContainer } from './schedulesTable/SchedulesTableContainer';
import { SchedulesListProps } from './SchedulesList.types';

export const SchedulesList = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  schedulesCount,
  setSchedulesCount,
  schedulesTableSearchQuery,
}: SchedulesListProps) => {
  return (
    <>
      <Pagination
        page={page}
        setPage={setPage}
        count={schedulesCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ExpandableList
        data={servers || []}
        isLoading={isLoading}
        title={(server) => server.nazwa}
        initiallyExpandedItem={findServerToExpand(servers || [])}
        toggleItemExpansion={(expandedServer) => {
          if (!expandedServer) setSchedulesCount(0);
        }}
        onClickExpandButton={() => {
          setPage(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <SchedulesTableContainer
            key={server.id}
            expandedServer={expandedServer}
            setSchedulesCount={setSchedulesCount}
            schedulesTableSearchQuery={schedulesTableSearchQuery}
          />
        )}
      />
    </>
  );
};
