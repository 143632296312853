import { createBrowserRouter } from 'react-router-dom';

import { App } from 'app/App';
import { AuthorizedRoute } from 'routing/authorizedRoute/AuthorizedRoute';
import { DevicesContainer } from 'app/devices/DevicesContainer';
import { CardHistoryContainer } from 'app/cards/cardHistory/CardHistoryContainer';
import { CardsContainer } from 'app/cards/CardsContainer';
import { Definitions } from 'app/definitions/Definitions';
import { AddEventExportFormatContainer } from 'app/definitions/eventsExportFormats/addEventExportFormat/AddEventExportFormatContainer';
import { EventsContainer } from 'app/events/EventsContainer';
import { GroupAssignedOwnersContainer } from 'app/groups/groupAssignedOwners/GroupAssignedOwnersContainer';
import { GroupsContainer } from 'app/groups/GroupsContainer';
import { GroupTerminalsPermissionsContainer } from 'app/groups/groupTerminalsPermissions/GroupTerminalsPermissionsContainer';
import { OwnerPermissionsContainer } from 'app/owners/ownerPermissions/OwnerPermissionsContainer';
import { OwnersContainer } from 'app/owners/OwnersContainer';
import { ProfileContainer } from 'app/profile/ProfileContainer';
import { UsersContainer } from 'app/users/UsersContainer';
import { GlcContainer } from 'app/glc/GlcContainer';
import { GlcHistoryContainer } from 'app/glc/glcHistory/GlcHistoryContainer';
import { GlcTerminalsContainer } from 'app/glc/glcTerminals/GlcTerminalsContainer';
import { GroupGlcPermissionsContainer } from 'app/groups/groupGlcPermissions/GroupGlcPermissionsContainer';
import { GroupHistoryContainer } from 'app/groups/groupHistory/GroupHistoryContainer';
import { OwnerHistory } from 'app/owners/ownerHistory/OwnerHistory';
import { OwnersControlContainer } from 'app/definitions/ownersControl/OwnersControlContainer';
import { Schedules } from 'app/schedules/Schedules';
import { ScheduleDetailsContainer } from 'app/schedules/scheduleDetails/ScheduleDetailsContainer';
import { Control } from 'app/control/Control';
import { DisplayContainer } from 'app/definitions/display/DisplayContainer';
import { InstantControlTerminalsContainer } from 'app/control/instantControl/instantControlTerminals/InstantControlTerminalsContainer';
import { RandomControlTerminalsContainer } from 'app/control/randomControl/randomControlTerminals/RandomControlTerminalsContainer';
import { Wanted } from 'app/wanted/Wanted';
import { WantedTerminalsContainer } from 'app/wanted/wantedTerminals/WantedTerminalsContainer';
import { Announcements } from 'app/announcements/Announcements';
import { AnnouncementAssignedOwnersContainer } from 'app/announcements/announcementAssignedOwners/AnnouncementAssignedOwnersContainer';
import { LoginContainer } from 'app/login/LoginContainer';
import { LogotypeContainer } from 'app/definitions/logotype/LogotypeContainer';
import { Navbar } from 'app/navbar/Navbar';
import { ScheduleTerminalsContainer } from 'app/schedules/schedulesTerminals/ScheduleTerminalsContainer';
import { ExceptionTerminalsContainer } from 'app/schedules/exceptionTerminals/ExceptionTerminalsContainer';
import { Sequences } from 'app/sequences/Sequences';

import { AppRoute, AppRouteWithParams } from './AppRoute.enum';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <LoginContainer />,
      },
      {
        path: '*',
        element: <LoginContainer />,
      },
      {
        path: 'login',
        element: <LoginContainer />,
      },
      {
        path: 'main/',
        element: (
          <AuthorizedRoute>
            <Navbar />
          </AuthorizedRoute>
        ),
        children: [
          {
            path: AppRoute.devices,
            element: <DevicesContainer />,
          },
          {
            path: AppRoute.cards,
            element: <CardsContainer />,
          },
          {
            path: AppRoute.cardsArchived,
            element: <CardsContainer />,
          },
          {
            path: AppRouteWithParams.cardHistory.path,
            element: <CardHistoryContainer />,
          },
          {
            path: AppRoute.owners,
            element: <OwnersContainer />,
          },
          {
            path: AppRoute.ownersArchived,
            element: <OwnersContainer />,
          },
          {
            path: AppRouteWithParams.ownerHistory.path,
            element: <OwnerHistory />,
          },
          {
            path: AppRouteWithParams.ownerPermissionsEffectiveHistory.path,
            element: <OwnerHistory />,
          },
          {
            path: AppRouteWithParams.ownerPermissionsEffective.path,
            element: <OwnerPermissionsContainer />,
          },
          {
            path: AppRouteWithParams.ownerPermissionsIndividual.path,
            element: <OwnerPermissionsContainer />,
          },
          {
            path: AppRouteWithParams.ownerPermissionsGroup.path,
            element: <OwnerPermissionsContainer />,
          },
          {
            path: AppRoute.controlRandom,
            element: <Control />,
          },
          {
            path: AppRoute.controlInstant,
            element: <Control />,
          },
          {
            path: AppRouteWithParams.controlInstantReaders.path,
            element: <InstantControlTerminalsContainer />,
          },
          {
            path: AppRouteWithParams.controlRandomReaders.path,
            element: <RandomControlTerminalsContainer />,
          },
          {
            path: AppRoute.controlHistory,
            element: <Control />,
          },
          {
            path: AppRoute.wanted,
            element: <Wanted />,
          },
          {
            path: AppRoute.wantedHistory,
            element: <Wanted />,
          },
          {
            path: AppRoute.announcements,
            element: <Announcements />,
          },
          {
            path: AppRoute.announcementsHistory,
            element: <Announcements />,
          },
          {
            path: AppRouteWithParams.announcementOwners.path,
            element: <AnnouncementAssignedOwnersContainer />,
          },
          {
            path: AppRouteWithParams.wantedReaders.path,
            element: <WantedTerminalsContainer />,
          },
          {
            path: AppRoute.events,
            element: <EventsContainer />,
          },
          {
            path: AppRoute.eventsHistory,
            element: <EventsContainer />,
          },
          {
            path: AppRoute.schedules,
            element: <Schedules />,
          },
          {
            path: AppRoute.schedulesExceptions,
            element: <Schedules />,
          },
          {
            path: AppRoute.schedulesHistory,
            element: <Schedules />,
          },
          {
            path: AppRouteWithParams.scheduleReaders.path,
            element: <ScheduleTerminalsContainer />,
          },
          {
            path: AppRouteWithParams.scheduleDetails.path,
            element: <ScheduleDetailsContainer />,
          },
          {
            path: AppRouteWithParams.exceptionReaders.path,
            element: <ExceptionTerminalsContainer />,
          },
          {
            path: AppRoute.sequences,
            element: <Sequences />,
          },
          {
            path: AppRoute.sequencesHistory,
            element: <Sequences />,
          },
          {
            path: AppRoute.definitions,
            element: <Definitions />,
          },
          {
            path: AppRoute.positions,
            element: <Definitions />,
          },
          {
            path: AppRoute.eventsExportFormats,
            element: <Definitions />,
          },
          {
            path: AppRoute.sequencesTerminals,
            element: <Definitions />,
          },
          {
            path: AppRoute.ownersControl,
            element: <OwnersControlContainer />,
          },
          {
            path: AppRouteWithParams.eventsExportFormats.path,
            element: <AddEventExportFormatContainer />,
          },
          {
            path: AppRoute.display,
            element: <DisplayContainer />,
          },
          {
            path: AppRoute.logotype,
            element: <LogotypeContainer />,
          },
          {
            path: AppRoute.groups,
            element: <GroupsContainer />,
          },
          {
            path: AppRouteWithParams.groupsHistory.path,
            element: <GroupHistoryContainer />,
          },
          {
            path: AppRouteWithParams.groupsPermissionsHistory.path,
            element: <GroupHistoryContainer />,
          },
          {
            path: AppRoute.groupsArchived,
            element: <GroupsContainer />,
          },
          {
            path: AppRouteWithParams.groupReadersPermissions.path,
            element: <GroupTerminalsPermissionsContainer />,
          },
          {
            path: AppRouteWithParams.groupGlcPermissions.path,
            element: <GroupGlcPermissionsContainer />,
          },
          {
            path: AppRouteWithParams.groupOwners.path,
            element: <GroupAssignedOwnersContainer />,
          },
          {
            path: AppRoute.glc,
            element: <GlcContainer />,
          },
          {
            path: AppRoute.glcArchived,
            element: <GlcContainer />,
          },
          {
            path: AppRouteWithParams.glcHistory.path,
            element: <GlcHistoryContainer />,
          },
          {
            path: AppRouteWithParams.glcTerminals.path,
            element: <GlcTerminalsContainer />,
          },
          {
            path: AppRoute.users,
            element: (
              <AuthorizedRoute adminRoute>
                <UsersContainer />
              </AuthorizedRoute>
            ),
          },
          {
            path: AppRoute.profile,
            element: <ProfileContainer />,
          },
        ],
      },
    ],
  },
]);
