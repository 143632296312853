import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import { SequenceTooltipContent } from 'reusable/sequenceTooltipContent/SequenceTooltipContent';

import { AddTerminalsSequenceFormBody, AddTerminalsSequenceProps } from './AddTerminalsSequence.types';

export const AddTerminalsSequence = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  isLoadingTerminals,
  terminals,
  selectedServerId,
  setSelectedServerId,
  servers,
}: AddTerminalsSequenceProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();
  const { serverOptions, sequenceTypeOptions } = useAppSelectOptions({ servers });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useFormContext<AddTerminalsSequenceFormBody>();

  const isTkdNotSelected = !watch('idTkds')?.length;

  const getOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['definitions.sequences.add.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['definitions.sequences.add.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isTkdNotSelected || isLoading || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Controller
            name="typ"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['definitions.sequences.add.type.label'] })}
                placeholder={formatMessage({ id: AppMessages['definitions.sequences.add.type.placeholder'] })}
                options={sequenceTypeOptions}
                required
                errorMessage={error?.message}
                withHelperText
                tooltipContent={<SequenceTooltipContent />}
              />
            )}
          />
          <Select
            value={selectedServerId}
            required
            onChange={(e) => {
              setValue('idTkds', []);
              setSelectedServerId(e.target.value as string);
            }}
            label={formatMessage({ id: AppMessages['definitions.sequences.add.server.label'] })}
            withHelperText
            options={serverOptions}
          />
          <Controller
            name="idTkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getOptions()}
                loading={isLoadingTerminals}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['definitions.sequences.add.terminal.label'] })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({ id: AppMessages['definitions.sequences.add.terminal.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Form.Grid>
      </Modal>
    </>
  );
};
