import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ArchiveOwnerProps } from './ArchiveOwner.types';

export const ArchiveOwner = ({
  selectedOwner,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: ArchiveOwnerProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="archive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['owner.archive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owner.archive.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['owner.archive.text'] })}{' '}
          {`${selectedOwner.nazwisko || ''} ${selectedOwner.imie || ''}`}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['owner.archive.confirm'] })}</ActionModal.Text>

        <br />

        <ActionModal.Text>{formatMessage({ id: AppMessages['owner.archive.description'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
