import { PaginationQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryState } from 'context/query/queryContext/QueryContext.types';

export const filterQueryFromPagination = (
  storedQuery: QueryState[keyof QueryState],
  additionalKeyToFilter: string[] = [],
) => {
  return Object.fromEntries(
    Object.entries(storedQuery).filter(
      ([key]) =>
        !(
          [PaginationQueryKeysEnum.PAGE, PaginationQueryKeysEnum.ROWS_PER_PAGE, ...additionalKeyToFilter] as string[]
        ).includes(key),
    ),
  );
};
