import { useState, useEffect } from 'react';
import { PageLayout, TopBar, DetailsDrawer, ShowUp } from 'shared-ui';
import { useDeepCompareEffect } from 'react-use';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { QueryKeyLocalStorageEnum, SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { Table } from 'ui/table/Table';
import { Glc as GlcType } from 'api/glc/glc.types';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';

import { AddGlcContainer } from './addGlc/AddGlcContainer';
import { GlcTableHeader } from './glcTableHeader/GlcTableHeader';
import { GlcProps } from './Glc.types';
import { GlcTableRow } from './glcTableRow/GlcTableRow';
import { GlcDetailsContainer } from './glcDetails/GlcDetailsContainer';
import { GlcMultiActions } from './glcMultiActions/GlcMultiActions';

export const Glc = ({
  glcResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isArchivedView,
  glcQueryLocalStorageName,
  glcQuery,
  setTableSearchQuery,
}: GlcProps) => {
  const { selectedGlc, setSelectedGlc, selectedGlcId, lastArchivedSelectedGlcId, lastActiveSelectedGlcId } =
    useSelectedGlc();
  const [checkedGlc, setCheckedGlc] = useState<GlcType[]>([]);
  const { formatMessage } = useLocale();
  const { setPartialStoredQueries, storedQueries } = useLocaleStoreQuery();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    isArchivedView ? SearchQueryKeysEnum.ARCHIVED_GLC : SearchQueryKeysEnum.ACTIVE_GLC,
    setTableSearchQuery,
    isArchivedView,
  );

  useEffect(() => {
    if (selectedGlc && glcResponse?.data) {
      const updatedGlc = glcResponse.data.find((glc) => selectedGlc.id === glc.id);

      updatedGlc && setSelectedGlc(updatedGlc);
    }
  }, [glcResponse?.data]);

  useDeepCompareEffect(() => {
    resetCheckedGlc();
  }, [filterQueryFromPagination(glcQuery)]);

  const resetCheckedGlc = () => {
    setCheckedGlc([]);
  };

  const clearSelectedQueries = () => {
    const glcSearchQueryKey = isArchivedView ? SearchQueryKeysEnum.ARCHIVED_GLC : SearchQueryKeysEnum.ACTIVE_GLC;

    setPartialStoredQueries({
      [QueryKeyLocalStorageEnum.GLC_TERMINALS]: DEFAULT_QUERY_STATE[QueryKeyLocalStorageEnum.GLC_TERMINALS],
      [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: {
        ...storedQueries?.selectedSearchOptions,
        [glcSearchQueryKey]: undefined,
      },
    });
  };

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['glc.title'] })}
      renderContent={() => (
        <>
          <TopBar>
            <TopBar.Link linkTo={AppRoute.glc} onClick={resetCheckedGlc} isActive={!isArchivedView}>
              {formatMessage({ id: AppMessages['glc.active'] })}
            </TopBar.Link>

            <TopBar.Link linkTo={AppRoute.glcArchived} onClick={resetCheckedGlc} isActive={isArchivedView}>
              {formatMessage({ id: AppMessages['glc.archived'] })}
            </TopBar.Link>

            <TopBar.Search>
              <TableSearchContainer
                searchIconTestId="page-search"
                inputPlaceholder={formatMessage({ id: AppMessages['glc.search'] })}
                searchBy="glc"
                setTableSearchQuery={setTableSearchQuery}
                selectedValue={searchOption}
                setSelectedValue={setSelectedSearchOption}
              />
            </TopBar.Search>

            <TopBar.ButtonsWrapper>
              <AddGlcContainer />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoading}
            renderMultiActions={() => (
              <ShowUp show={checkedGlc.length > 1}>
                <GlcMultiActions checkedGlc={checkedGlc} setCheckedGlc={setCheckedGlc} />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: glcResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            testIdKey="idGlc"
            gridTemplateColumns="0.5fr 1.7fr 1fr 1fr"
            data={glcResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['glc.nothingToShow'] })}
            activeRowId={selectedGlcId}
            onClickRow={(glc) => {
              clearSelectedQueries();
              setSelectedGlc(selectedGlc?.id === glc.id ? undefined : glc);
            }}
            checkboxSelection={{
              checked: checkedGlc,
              toggleChecked: setCheckedGlc,
              customGridTemplateColumnValue: 'minmax(60px, 0.3fr)',
            }}
            renderHeader={() => <GlcTableHeader glcQueryLocalStorageName={glcQueryLocalStorageName} />}
            renderRow={(glc) => <GlcTableRow glc={glc} />}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={
            !!selectedGlcId &&
            !!selectedGlc &&
            (isArchivedView ? !!lastArchivedSelectedGlcId : !!lastActiveSelectedGlcId)
          }
          header={formatMessage({ id: AppMessages['glc.details.title'] })}
        >
          {selectedGlcId && selectedGlc && <GlcDetailsContainer key={selectedGlc.id} selectedGlc={selectedGlc} />}
        </DetailsDrawer>
      )}
    />
  );
};
