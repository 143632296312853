import { useState } from 'react';
import { Typography } from '@mui/material';
import { PageLayout, Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';

import { TerminalsContainer } from './terminals/TerminalsContainer';
import { SequencesTerminalsProps } from './SequencesTerminals.types';

export const SequencesTerminals = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: SequencesTerminalsProps) => {
  const { formatMessage } = useLocale();
  const [terminalsCount, setTerminalsCount] = useState(0);

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['definitions.title'] })}
      renderContent={() => (
        <>
          <DefinitionsBar />

          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['devices.nothingToShow'] })}
            title={(server) => <Typography variant="h6">{server.nazwa}</Typography>}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              if (!expandedServer) setTerminalsCount(0);
            }}
            onClickExpandButton={() => {
              setPage(0);
            }}
            renderExpandableItemBody={(_, expandedServer) => (
              <TerminalsContainer expandedServer={expandedServer} setTerminalsCount={setTerminalsCount} />
            )}
          />
        </>
      )}
    />
  );
};
