import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchScheduleExceptionTerminals } from 'api/schedules/schedules';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { ExceptionTerminals } from './ExceptionTerminals';

export const ExceptionTerminalsContainer = () => {
  const { id } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.SCHEDULE_EXCEPTION_TERMINALS,
    });

  const { data: terminalsResponse, isLoading } = useQuery(
    [QueryKeyEnum.SCHEDULE_EXCEPTION_TERMINALS, storedQueryWithoutPagination, page, rowsPerPage, id],
    () => fetchScheduleExceptionTerminals(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      },
      onSuccess: (response) => {
        setPaginationCount(response.count);
      },
    },
  );

  return (
    <ExceptionTerminals
      terminalsResponse={terminalsResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
