import styled from 'styled-components';
import { Typography, IconButton } from '@mui/material';
import { CloseGrayIcon } from 'shared-ui';

export const RemoveIconWrapper = styled(IconButton)`
  cursor: pointer;
  pointer-events: initial;
  padding: 4px;
  margin: 0;

  &.MuiSelect-icon {
    top: 9px;
  }

  &.MuiSelect-iconOpen {
    top: 10px;
    right: 8px;
  }
`;

export const SelectIconWrapper = styled.div`
  &.MuiSelect-icon {
    top: 12px;
  }

  &.MuiSelect-iconOpen {
    top: 8px;
  }
`;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 100px;
  gap: 10px;
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) repeat(4, minmax(100px, 0.7fr));
  gap: 16px;
`;

export const ClearFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const ClearFiltersButton = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  justify-self: flex-end;
`;

export const ClearFiltersIcon = styled(CloseGrayIcon)`
  margin-left: 10px;
  margin-bottom: 4px;
`;
