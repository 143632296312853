import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchScheduleDetailsTerminals } from 'api/schedules/schedules';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { ScheduleTerminalsRows } from './ScheduleTerminalsRows';
import { ScheduleTerminalsRowsContainerProps } from './ScheduleTerminalsRows.types';

export const ScheduleTerminalsRowsContainer = ({
  expandedServer,
  setTerminalsCount,
  checkedTerminals,
  setCheckedTerminals,
}: ScheduleTerminalsRowsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const scheduleId = id || '';
  const serverId = expandedServer?.id || '';

  const { page, rowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULE_TERMINALS,
  });

  const { data: terminalsResponse } = useQuery(
    [QueryKeyEnum.SCHEDULE_TERMINALS, scheduleId, expandedServer?.id, storedQueryWithoutPagination, page, rowsPerPage],
    () => fetchScheduleDetailsTerminals({ scheduleId, serverId }),
    {
      args: { ...storedQueryWithoutPagination, limit: rowsPerPage, offset: rowsPerPage * page },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setTerminalsCount(response.count);
      },
    },
  );

  return (
    <ScheduleTerminalsRows
      checkedTerminals={checkedTerminals}
      terminalsResponse={terminalsResponse}
      setCheckedTerminals={setCheckedTerminals}
    />
  );
};
