import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';
import { Card } from 'api/cards/cards.types';

import { Sequence, SequencesHistory, AddSequenceBody, CardsAQuery, CardsBQuery } from './sequences.types';

export const fetchSequences: UseQueryFn<string, PaginatedResponse<Sequence>> = (id) => {
  return {
    endpoint: `/sekwencja/rcp/${id}`,
  };
};

export const deleteSequence: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/sekwencja/${id}`,
    method: 'DELETE',
  };
};

export const fetchSequencesHistory: UseQueryFn<void, PaginatedResponse<SequencesHistory>> = () => {
  return {
    endpoint: `/sekwencja/historia`,
  };
};

export const addSequence: MutationFn<AddSequenceBody, void> = (body) => {
  return {
    endpoint: '/sekwencja',
    method: 'POST',
    body,
  };
};

export const searchCardA: UseQueryFn<CardsAQuery, PaginatedResponse<Card>> = () => {
  return {
    endpoint: `/sekwencja/kartaA`,
  };
};

export const searchCardB: UseQueryFn<CardsBQuery, PaginatedResponse<Card>> = () => {
  return {
    endpoint: `/sekwencja/kartaB`,
  };
};
