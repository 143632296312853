import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServers } from 'api/servers/servers';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { SchedulesExceptions } from './SchedulesExceptions';

export const SchedulesExceptionsContainer = () => {
  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SCHEDULES_EXCEPTIONS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  return (
    <SchedulesExceptions
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      schedulesExceptionsCount={paginationCount}
      setSchedulesExceptionsCount={setPaginationCount}
    />
  );
};
