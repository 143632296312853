import { useNavigate } from 'react-router';
import { DoubleTextElipsisWithTooltip, ProblemIconWithTooltip, useObserver } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import { DeleteRandomControlContainer } from './deleteInstantControl/DeleteInstantControlContainer';
import { InsantControlTableRowProps } from './InstantControlTableRow.types';
import * as Styled from './InstantControlTableRow.styles';

export const InstantControlTableRow = ({ owner }: InsantControlTableRowProps) => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const { tableWidth } = useObserver();

  return (
    <>
      <Table.RowItem>{owner.skdId}</Table.RowItem>
      <Table.RowItem>
        <DoubleTextElipsisWithTooltip
          fullText={`${owner.nazwisko} ${owner.imie}`}
          firstLineText={owner?.nazwisko}
          secondLineText={owner?.imie}
          width={tableWidth}
        />
      </Table.RowItem>
      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={owner.problemTkd}
          tooltip={formatMessage({ id: AppMessages['control.notUpdated.info'] })}
        />
      </Table.RowItem>
      <Table.RowCellsItem cells={owner.komorka} />
      <Table.RowItem>{owner.nrEwidencyjny}</Table.RowItem>
      <Table.RowItem>
        <Styled.Wrapper>
          <Styled.TerminalsIcon
            onClick={() => navigate(AppRouteWithParams.controlInstantReaders.get({ id: owner.id }))}
          />
          <DeleteRandomControlContainer owner={owner} />
        </Styled.Wrapper>
      </Table.RowItem>
    </>
  );
};
