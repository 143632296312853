import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { SequencesTerminals } from './SequencesTerminals';

export const SequencesTerminalsContainer = () => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  return (
    <SequencesTerminals
      servers={serversResponse?.data || []}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
