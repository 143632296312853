import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum, SequencesHistoryQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchServers } from 'api/servers/servers';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';
import { fetchSequencesHistory } from 'api/sequences/sequences';

import { useSequencesHistorySearchQuery } from './hooks/useSequencesHistorySearchQuery';
import { SequencesHistory } from './SequencesHistory';

export const SequencesHistoryContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SEQUENCES_HISTORY]: sequenceHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const {
    tkdSearchQuery,
    executorSearchQuery,
    cardSearchQuery,
    setExecutorSearchQuery,
    setCardSearchQuery,
    setTkdSearchQuery,
    clearSearchQueries,
  } = useSequencesHistorySearchQuery();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.SEQUENCES_HISTORY,
    resetPaginationDependencyArray: [tkdSearchQuery, executorSearchQuery],
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: sequencesHistoryResponse, isLoading } = useQuery(
    [
      QueryKeyEnum.SEQUENCES_HISTORY,
      storedQueryWithoutPagination,
      rowsPerPage,
      page,
      tkdSearchQuery,
      executorSearchQuery,
      cardSearchQuery,
    ],
    () => fetchSequencesHistory(),
    {
      args: {
        ...storedQueryWithoutPagination,
        [SequencesHistoryQueryKeysEnum.SERWER]: sequenceHistoryQuery.serwer?.value,
        [SequencesHistoryQueryKeysEnum.CZYTNIK]: sequenceHistoryQuery.tkd?.value,
        [SequencesHistoryQueryKeysEnum.QUERY]: sequenceHistoryQuery.query?.value,
        [SequencesHistoryQueryKeysEnum.NUMER_KARTY]: sequenceHistoryQuery.numerKarty?.value,
        [SequencesHistoryQueryKeysEnum.TYP]: sequenceHistoryQuery.typ,
        [SequencesHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [SequencesHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  const onClearHistoryQuery = () => {
    setQuery(QueryKeyLocalStorageEnum.SEQUENCES_HISTORY, {
      page,
      rowsPerPage,
    });
    clearSearchQueries();
  };

  return (
    <SequencesHistory
      sequencesHistoryResponse={sequencesHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      cardSearchQuery={cardSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      setCardSearchQuery={setCardSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      onClearHistoryQuery={onClearHistoryQuery}
    />
  );
};
