import { Typography } from '@mui/material';
import { useLocale } from 'shared-ui';

import { AppMessages } from 'i18n/messages';

import * as Styled from './SequenceTooltipContent.styles';

export const SequenceTooltipContent = () => {
  const { formatMessage } = useLocale();

  return (
    <Styled.TooltipContentWrapper>
      <Typography>
        {formatMessage(
          { id: AppMessages['sequences.tooltip.vault.description'] },
          {
            title: (
              <Styled.TooltipLabel>
                {formatMessage({ id: AppMessages['sequences.tooltip.vault.title'] })}
              </Styled.TooltipLabel>
            ),
          },
        )}
      </Typography>
      <Typography>
        {formatMessage(
          { id: AppMessages['sequences.tooltip.passageway.description'] },
          {
            title: (
              <Styled.TooltipLabel>
                {formatMessage({ id: AppMessages['sequences.tooltip.passageway.title'] })}
              </Styled.TooltipLabel>
            ),
          },
        )}
      </Typography>
    </Styled.TooltipContentWrapper>
  );
};
