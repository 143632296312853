import { useLocale } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { SequenceTypeDescriptionEnum } from 'api/sequences/sequences.enum';
import { AppMessages } from 'i18n/messages';

import { TerminalsTableRowProps } from './TerminalsTableRow.types';
import { DeleteTerminalContainer } from './deleteTerminal/DeleteTerminalContainer';

export const TerminalsTableRow = ({ terminal }: TerminalsTableRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>
      <Table.RowItem>{terminal.nazwa}</Table.RowItem>
      <Table.RowItem>
        {terminal.sekwencja
          ? formatMessage({ id: AppMessages[SequenceTypeDescriptionEnum[terminal.sekwencja]] })
          : null}
      </Table.RowItem>
      <DeleteTerminalContainer selectedTerminal={terminal} />
    </>
  );
};
