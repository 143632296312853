import styled from 'styled-components';

export const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TooltipLabel = styled.span`
  font-weight: 700;
`;
