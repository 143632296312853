import { useState } from 'react';
import { PageLayout, ShowUp, TopBar, Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute } from 'routing/AppRoute.enum';
import { Terminal } from 'api/terminals/terminals.types';

import { ScheduleTerminalsRowsContainer } from './scheduleTerminalsRows/ScheduleTerminalsRowsContainer';
import { ScheduleTerminalsProps } from './ScheduleTerminals.types';
import { ScheduleTerminalsMultiActions } from './scheduleTerminalsMultiActions/ScheduleTerminalsMultiActions';
import { AddScheduleTerminalsContainer } from './addScheduleTerminals/AddScheduleTerminalsContainer';

export const ScheduleTerminals = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  terminalsCount,
  setTerminalsCount,
  scheduleId,
}: ScheduleTerminalsProps) => {
  const { formatMessage } = useLocale();
  const [checkedTerminals, setCheckedTerminals] = useState<Terminal[]>([]);

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['schedule.terminals.title'] })}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.schedules),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddScheduleTerminalsContainer scheduleId={scheduleId || ''} />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <ShowUp show={!!checkedTerminals.length}>
            <ScheduleTerminalsMultiActions
              scheduleId={scheduleId}
              checkedTerminals={checkedTerminals}
              setCheckedTerminals={setCheckedTerminals}
              terminalsCount={terminalsCount}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['schedule.terminals.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              if (!expandedServer) setTerminalsCount(0);
            }}
            onClickExpandButton={() => {
              setPage(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <ScheduleTerminalsRowsContainer
                key={server.id}
                expandedServer={expandedServer}
                setTerminalsCount={setTerminalsCount}
                checkedTerminals={checkedTerminals}
                setCheckedTerminals={setCheckedTerminals}
              />
            )}
          />
        </>
      )}
    />
  );
};
