import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'debounce';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { AddGroupFormBody } from '../AddGroup.types';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { GlcValueEnum } from '../AddGroup.enum';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AddGroupDefinitionFormProps } from './AddGroupDefinitionForm.types';
import * as Styled from './AddGroupDefinitionForm.styles';

export const AddGroupDefinitionForm = ({
  owners,
  isLoadingOwners,
  setOwnersQuery,
  ownersQuery,
}: AddGroupDefinitionFormProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption({
    idPrefix: formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.owner.optionLabel'] }),
  });

  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<AddGroupFormBody>();

  const getOptions = () => {
    return owners.map((owner) => ({
      label: `${owner.nazwisko} ${owner.imie}`,
      value: owner.id,
      number: owner.nrEwidencyjny,
    }));
  };

  const { groupTypeOptions, glcOptions } = useAppSelectOptions();

  const handleOwnerSearch = debounce((search: string) => {
    setOwnersQuery(search);
  }, 300);

  const resetSelectedValues = () => {
    setValue('tkds', []);
    setValue('idGlcs', []);
  };

  return (
    <>
      <Input
        {...register('idGrupy')}
        required
        label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.id.label'] })}
        placeholder={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.id.placeholder'] })}
        errorMessage={errors?.idGrupy?.message}
      />

      <Input
        {...register('nazwa')}
        required
        label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.name.label'] })}
        placeholder={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.name.placeholder'] })}
        errorMessage={errors?.nazwa?.message}
      />

      <Form.ControlledSelect
        control={control}
        name="typ"
        required
        label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.type.label'] })}
        withHelperText
        errorMessage={errors?.typ?.message}
        placeholder={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.type.placeholder'] })}
        testId="typeSelect"
        options={groupTypeOptions}
      />

      {watch('typ') === GroupTypeEnum.DYNAMICZNA && (
        <Controller
          control={control}
          name="komorki"
          render={({ field }) => (
            <OrganizationsTreeSelect
              {...field}
              multiple
              placeholder={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.cell.placeholder'] })}
              label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.cell.label'] })}
            />
          )}
        />
      )}

      {watch('typ') === GroupTypeEnum.STATYCZNA && (
        <Controller
          name="pkds"
          control={control}
          render={({ field }) => (
            <MultiAutocomplete
              {...field}
              stopRotatePopupIndicator
              inputSize="big"
              limitTags={1}
              onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
              inputValue={ownersQuery}
              onInputChange={(e, value) => {
                e && handleOwnerSearch(value);
              }}
              options={getOptions()}
              loading={isLoadingOwners}
              loadingText={formatMessage({ id: AppMessages['common.loading'] })}
              label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.owner.label'] })}
              placeholder={formatMessage({
                id: AppMessages['groups.addGroup.groupDefinition.field.owner.placeholder'],
              })}
              noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
              renderOption={renderMultiAutocompleteOption}
              withLimitedTags
            />
          )}
        />
      )}

      <Form.ControlledSelect
        name="glc"
        control={control}
        onChange={(e) => {
          resetSelectedValues();
          setValue('glc', e.target.value as GlcValueEnum, { shouldDirty: true });
        }}
        label={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.glc.label'] })}
        withHelperText
        testId="glcSelect"
        required
        options={glcOptions}
      />

      <Styled.DescriptionInputWrapper>
        <Input
          {...register('opis')}
          label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.description.label'] })}
          placeholder={formatMessage({
            id: AppMessages['groups.addGroup.groupDefinition.field.description.placeholder'],
          })}
          errorMessage={errors?.opis?.message}
        />
      </Styled.DescriptionInputWrapper>
    </>
  );
};
