import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { useQuery } from 'hooks/useQuery/useQuery';

import { EventsFilters } from './EventsFilters';
import { EventsFiltersContainerProps } from './EventsFilters.types';

export const EventsFiltersContainer = ({
  setTableSearchQuery,
  tableSearchQuery,
  archivedSearchSelectedValue,
  setArchivedSearchSelectedValue,
  tkdSearchQuery,
  setTkdSearchQuery,
}: EventsFiltersContainerProps) => {
  const { data: servers } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  return (
    <EventsFilters
      tableSearchQuery={tableSearchQuery}
      setTableSearchQuery={setTableSearchQuery}
      servers={servers?.data || []}
      archivedSearchSelectedValue={archivedSearchSelectedValue}
      setArchivedSearchSelectedValue={setArchivedSearchSelectedValue}
      tkdSearchQuery={tkdSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
    />
  );
};
