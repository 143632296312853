import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { PaginationQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { filterQueryFromPagination } from 'helpers/filterQueryFromPagination';
import { QueryState } from 'context/query/queryContext/QueryContext.types';

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 25;

export const useSavedPagination = <T extends QueryKeyLocalStorageEnum>({
  queryKey,
  resetPaginationDependencyArray = [],
}: {
  queryKey: T;
  resetPaginationDependencyArray?: unknown[];
}) => {
  const [paginationCount, setPaginationCount] = useState(0);

  const {
    storedQueries: { [queryKey]: storedQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setRowsPerPage = (rowsPerPage: number) => {
    const queryUpdate = {
      ...storedQuery,
      rowsPerPage,
    };

    setQuery(queryKey, queryUpdate);
  };

  const setPage = (page: number) => {
    const queryUpdate = {
      ...storedQuery,
      page,
    };

    setQuery(queryKey, queryUpdate);
  };

  const rowsPerPage =
    PaginationQueryKeysEnum.ROWS_PER_PAGE in storedQuery && storedQuery.rowsPerPage
      ? storedQuery.rowsPerPage
      : DEFAULT_ROWS_PER_PAGE;

  const page = PaginationQueryKeysEnum.PAGE in storedQuery && storedQuery.page ? storedQuery.page : DEFAULT_PAGE;

  const offset = rowsPerPage * page;

  const storedQueryWithoutPagination: QueryState[T] = filterQueryFromPagination(storedQuery);

  useUpdateEffect(() => {
    if (resetPaginationDependencyArray.length) {
      setPage(DEFAULT_PAGE);
    }
  }, [...resetPaginationDependencyArray]);

  useUpdateEffect(() => {
    if (offset && offset > 0 && offset >= paginationCount) {
      const lastPage = Math.max(0, Math.ceil(paginationCount / rowsPerPage) - 1);

      setPage(lastPage);
    }
  }, [paginationCount]);

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    storedQueryWithoutPagination,
    offset,
    paginationCount,
    setPaginationCount,
  };
};
