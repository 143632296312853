import { useState } from 'react';
import { Popover } from 'shared-ui';

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import * as Styled from '../Table.styles';

import { HeaderFilterItemProps } from './HeaderFilterItem.types';

const HeaderFilterItem = <T extends string>({
  filterItems,
  queryKey,
  queryName,
  queryHeader,
  queryHeaderDescriptions,
  notCloseOnClickFilter,
  headerWrapper,
}: HeaderFilterItemProps<T>) => {
  const { formatMessage } = useLocale();
  const [headerFilterEl, setHeaderFilterEl] = useState<HTMLDivElement | undefined>(undefined);

  const {
    storedQueries: { [queryKey]: storedQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const header =
    storedQuery[queryName as keyof typeof storedQuery] && queryHeaderDescriptions
      ? formatMessage({ id: queryHeaderDescriptions[storedQuery[queryName as keyof typeof storedQuery]] })
      : queryHeader;

  return (
    <Styled.TableHeaderItem>
      <Styled.TableHeaderItemSortable
        data-testid={`filter-${queryName}`}
        $isActive={!!headerFilterEl}
        onClick={(e) => setHeaderFilterEl(e.currentTarget)}
      >
        <Styled.TableHeaderItemTextMaxWidth noWrap variant="body2">
          {headerWrapper ? headerWrapper(header) : header}
        </Styled.TableHeaderItemTextMaxWidth>

        {storedQuery[queryName as keyof typeof storedQuery] ? (
          <RemoveIcon
            onClick={(e) => {
              e.stopPropagation();

              const queryUpdate = {
                ...storedQuery,
                [queryName]: undefined,
              };

              if ('sort' in queryUpdate) {
                queryUpdate.sort = [];
              }

              if ('page' in queryUpdate) {
                queryUpdate.page = 0;
              }

              setQuery(queryKey, queryUpdate);
            }}
          />
        ) : (
          <DropdownIcon />
        )}
      </Styled.TableHeaderItemSortable>

      <Popover
        type="filter"
        anchorEl={headerFilterEl}
        onClose={() => setHeaderFilterEl(undefined)}
        filterItems={filterItems}
        onClickFilter={(filter) => {
          const queryUpdate = {
            ...storedQuery,
            [queryName as keyof typeof storedQuery]:
              filter === storedQuery[queryName as keyof typeof storedQuery] ? undefined : filter,
          };

          if ('sort' in queryUpdate) {
            queryUpdate.sort = [];
          }

          if ('page' in queryUpdate) {
            queryUpdate.page = 0;
          }

          setQuery(queryKey, queryUpdate);

          if (!notCloseOnClickFilter) {
            setHeaderFilterEl(undefined);
          }
        }}
      />
    </Styled.TableHeaderItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default HeaderFilterItem;
