import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from '@mui/lab';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { fetchGlcDetails, fetchGlcHistory } from 'api/glc/glc';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { HistoryActionEnum, QueryKeyEnum } from 'core/global.enum';
import { GlcHistoryQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { GlcHistory } from './GlcHistory';

export const GlcHistoryContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedGlc } = useSelectedGlc();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.GLC_HISTORY]: glcHistoryQuery },
    setQuery: setGlcHistoryQuery,
  } = useLocaleStoreQuery();
  const [readerSearchQuery, setReaderSearchQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { data: glcDetails, isInitialLoading: isLoadingGlcDetails } = useQuery(
    [QueryKeyEnum.GLC_DETAILS, id],
    () => fetchGlcDetails(id),
    {
      enabled: !selectedGlc,
    },
  );

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, offset, setPaginationCount } =
    useSavedPagination({
      queryKey: QueryKeyLocalStorageEnum.GLC_HISTORY,
      resetPaginationDependencyArray: [searchQuery],
    });

  const {
    data: glcHistoryResponse,
    isLoading,
    isFetching,
  } = useQuery(
    [QueryKeyEnum.GLC_HISTORY, id, storedQueryWithoutPagination, rowsPerPage, page, searchQuery, readerSearchQuery],
    () => fetchGlcHistory(id),
    {
      args: {
        ...storedQueryWithoutPagination,
        [GlcHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [GlcHistoryQueryKeysEnum.OFFSET]: offset,
        [GlcHistoryQueryKeysEnum.QUERY]: searchQuery,
        [GlcHistoryQueryKeysEnum.CZYTNIK]: readerSearchQuery,
      },
      onSuccess: (res) => {
        setPaginationCount(res.count);
      },
    },
  );

  const onClearHistoryQuery = () => {
    setGlcHistoryQuery(QueryKeyLocalStorageEnum.GLC_HISTORY, {
      ...glcHistoryQuery,
      dataOd: undefined,
      dataDo: undefined,
      akcja: undefined,
      query: undefined,
    });
    setSearchQuery('');
    setReaderSearchQuery('');
  };

  const handleSetDates = (dates: DateRange<Date>) => {
    setGlcHistoryQuery(QueryKeyLocalStorageEnum.GLC_HISTORY, {
      ...glcHistoryQuery,
      dataOd: dates[0] ? formatISO(startOfDay(dates[0])) : undefined,
      dataDo: dates[1] ? formatISO(endOfDay(dates[1])) : undefined,
    });
  };

  const onChangeActionFilter = (action: HistoryActionEnum | undefined) => {
    setGlcHistoryQuery(QueryKeyLocalStorageEnum.GLC_HISTORY, {
      ...glcHistoryQuery,
      akcja: action,
    });
  };

  return (
    <GlcHistory
      glc={selectedGlc || glcDetails}
      isLoadingGlcDetails={isLoadingGlcDetails}
      glcHistoryResponse={glcHistoryResponse}
      isLoading={isLoading}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleSetDates={handleSetDates}
      onClearHistoryQuery={onClearHistoryQuery}
      onChangeActionFilter={onChangeActionFilter}
      glcHistoryQuery={glcHistoryQuery}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      readerSearchQuery={readerSearchQuery}
      setReaderSearchQuery={setReaderSearchQuery}
    />
  );
};
