import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { PaginatedResponse } from 'core/global.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addTerminalsSequence, fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { TerminalIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';
import { Server } from 'api/servers/servers.types';

import { useAddTerminalsSequenceValidation } from './AddTerminalsSequence.validation';
import { AddTerminalsSequenceFormBody } from './AddTerminalsSequence.types';
import { AddTerminalsSequence } from './AddTerminalsSequence';

export const AddTerminalsSequenceContainer = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddTerminalsSequenceValidation();
  const [selectedServerId, setSelectedServerId] = useState('');

  const servers = queryClient.getQueryData<PaginatedResponse<Server>>([QueryKeyEnum.SERVERS])?.data || [];

  useEffect(() => {
    servers.length && setSelectedServerId(servers[0].id);
  }, [servers]);

  const methods = useForm<AddTerminalsSequenceFormBody>({
    defaultValues: {
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS_WITH_SEQUENCE, selectedServerId],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [TerminalIdSortEnum.ASC],
        sekwencja: false,
      },
      enabled: !!selectedServerId,
    },
  );

  const { mutate: onUpdateTerminals, isLoading } = useMutation(addTerminalsSequence, {
    onSuccess: (_, { idTkds }) => {
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS_WITH_SEQUENCE]);

      idTkds?.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['definitions.sequences.add.terminal.success.multi'] }))
        : toast.dark(formatMessage({ id: AppMessages['definitions.sequences.add.terminal.success'] }));

      onModalClose();
    },
  });

  const onSubmit = (body: AddTerminalsSequenceFormBody) => {
    onUpdateTerminals({
      idTkds: body.idTkds.map(({ value }) => `${value}`),
      typ: body.typ,
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddTerminalsSequence
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        servers={servers}
      />
    </FormProvider>
  );
};
