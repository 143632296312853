import { useState } from 'react';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useSequencesHistorySearchQuery = () => {
  const {
    storedQueries: { controlHistory: sequencesHistoryQuery },
  } = useLocaleStoreQuery();

  const [tkdSearchQuery, setTkdSearchQuery] = useState(sequencesHistoryQuery?.tkd?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(sequencesHistoryQuery?.query?.label || '');
  const [cardSearchQuery, setCardSearchQuery] = useState(sequencesHistoryQuery?.posiadacz?.label || '');

  const clearSearchQueries = () => {
    setTkdSearchQuery('');
    setExecutorSearchQuery('');
    setCardSearchQuery('');
  };

  return {
    tkdSearchQuery,
    setTkdSearchQuery,
    executorSearchQuery,
    setExecutorSearchQuery,
    cardSearchQuery,
    setCardSearchQuery,
    clearSearchQueries,
  };
};
