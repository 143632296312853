import { FormHelperText, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  grid-column: 1/-1;
`;

export const FieldWrapper = styled.div`
  margin-top: 22px;
`;

export const ErrorMessage = styled(FormHelperText)(
  ({ theme: { palette } }) => css`
    display: block;
    font-weight: 700;
    color: ${palette.error.main};
    height: 24px;
  `,
);

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 6px 0px;
`;

export const InfoMessage = styled(FormHelperText)`
  display: block;
  font-weight: 700;
  color: #a6a6a6;
  height: 24px;
`;
