import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { Input } from 'ui/input/Input';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { AddWantedForm, AddWantedProps } from './AddWanted.types';
import * as Styled from './AddWanted.styles';

export const AddWanted = ({
  servers,
  terminals,
  owners,
  isLoadingTerminals,
  isLoadingOwners,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
  isLoadingAddWanted,
}: AddWantedProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption({
    idPrefix: formatMessage({ id: AppMessages['wanted.addOwner.owners.optionLabel'] }),
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddWantedForm>();

  const terminalsOptions = terminals.map((terminal) => ({
    label: terminal.nazwa,
    value: terminal.id,
    number: terminal.numerLogicznyKontrolera,
  }));

  const ownersOptions = owners.map((owner) => ({
    label: `${owner.nazwisko} ${owner.imie}`,
    value: owner.id,
    number: owner.nrEwidencyjny,
    idPrefix: owner.nrEwidencyjny,
  }));

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['control.addOwner'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['control.addOwner'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid}
        isLoading={isLoadingAddWanted}
      >
        <Styled.Grid>
          <Controller
            name="idPkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={ownersOptions}
                loading={isLoadingOwners}
                label={formatMessage({ id: AppMessages['wanted.addOwner.owners.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholderWithSearch'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />

          <Form.ControlledSelect
            name="serwerId"
            control={control}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['wanted.addOwner.server.label'] })}
            withHelperText
            required
            options={serverOptions}
          />

          <div>
            <Controller
              name="tkds"
              control={control}
              render={({ field }) => (
                <MultiAutocomplete
                  {...field}
                  required
                  withLimitedTags
                  stopRotatePopupIndicator
                  inputSize="big"
                  limitTags={1}
                  onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                  options={terminalsOptions}
                  loading={isLoadingTerminals}
                  label={formatMessage({ id: AppMessages['wanted.addOwner.reader.label'] })}
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholderWithSearch'] })}
                  renderOption={renderMultiAutocompleteOption}
                />
              )}
            />
            <Styled.ErrorMessage>
              {!terminals.length && formatMessage({ id: AppMessages['wanted.addOwner.reader.error'] })}
            </Styled.ErrorMessage>
          </div>

          <Styled.InformationInputWrapper>
            <Controller
              name="informacja"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['wanted.addOwner.information.label'] })}
                  placeholder={formatMessage({ id: AppMessages['wanted.addOwner.information.placeholder'] })}
                  errorMessage={error?.message}
                  required
                />
              )}
            />
          </Styled.InformationInputWrapper>
        </Styled.Grid>
      </Modal>
    </>
  );
};
