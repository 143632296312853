import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { GroupHistoryQuery, GroupPermissionsHistoryQuery } from 'context/query/queryContext/QueryContext.types';

type GroupHistoryQueryType<T extends GroupHistoryQuery | GroupPermissionsHistoryQuery> = {
  setGroupHistoryQuery: (query: GroupHistoryQuery | GroupPermissionsHistoryQuery) => void;
  clearGroupHistoryQuery: () => void;
  query: T;
  queryKey: QueryKeyLocalStorageEnum;
};

export function useGroupHistoryQuery(isBaseView?: true): GroupHistoryQueryType<GroupHistoryQuery>;
export function useGroupHistoryQuery(isBaseView?: false): GroupHistoryQueryType<GroupPermissionsHistoryQuery>;
export function useGroupHistoryQuery(isBaseView = true) {
  const queryKey = isBaseView
    ? QueryKeyLocalStorageEnum.GROUP_HISTORY
    : QueryKeyLocalStorageEnum.GROUP_PERMISSIONS_HISTORY;

  const {
    storedQueries: {
      [QueryKeyLocalStorageEnum.GROUP_HISTORY]: groupHistoryQuery,
      [QueryKeyLocalStorageEnum.GROUP_PERMISSIONS_HISTORY]: groupHistoryPermissionQuery,
    },
    setQuery,
  } = useLocaleStoreQuery();

  const setGroupHistoryQuery = (query: GroupHistoryQuery | GroupPermissionsHistoryQuery) => {
    setQuery(queryKey, {
      ...(isBaseView ? groupHistoryQuery : groupHistoryPermissionQuery),
      ...query,
    });
  };

  const clearGroupHistoryQuery = () => {
    setQuery(queryKey, {
      page: isBaseView ? groupHistoryQuery.page : groupHistoryPermissionQuery.page,
      rowsPerPage: isBaseView ? groupHistoryQuery.rowsPerPage : groupHistoryPermissionQuery.rowsPerPage,
    });
  };

  return {
    setGroupHistoryQuery: setGroupHistoryQuery,
    clearGroupHistoryQuery: clearGroupHistoryQuery,
    query: isBaseView ? groupHistoryQuery : groupHistoryPermissionQuery,
    queryKey,
  };
}
