import { useState, useEffect } from 'react';
import { ErrorBoundary } from 'shared-ui';

import { fetchCards } from 'api/cards/cards';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { CardsQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSearchQuery } from 'hooks/useSearchQuery/useSearchQuery';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { OWNERS_QUERY_PREFIX } from 'reusable/tableSearch/hooks/useSearchByOwners';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { Cards } from './Cards';

export const CardsContainer = () => {
  const { setSelectedCardId, setSelectedCard } = useSelectedCard();
  const isArchivedView = useIsExactPath(AppRoute.cardsArchived);

  const cardsQueryLocalStorageName = isArchivedView
    ? QueryKeyLocalStorageEnum.ARCHIVED_CARDS
    : QueryKeyLocalStorageEnum.CARDS;

  const {
    storedQueries: { [cardsQueryLocalStorageName]: cardsQuery },
  } = useLocaleStoreQuery();

  const searchQuery = useSearchQuery();
  const redirectedCardId = searchQuery.get(CardsQueryKeysEnum.ID);
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination, offset, setPaginationCount } =
    useSavedPagination({
      queryKey: cardsQueryLocalStorageName,
      resetPaginationDependencyArray: [tableSearchQuery],
    });

  const { data: cardsResponse, isLoading } = useQuery(
    [QueryKeyEnum.CARDS, page, rowsPerPage, isArchivedView, cardsQuery, redirectedCardId, tableSearchQuery],
    fetchCards,
    {
      args: {
        [CardsQueryKeysEnum.LIMIT]: rowsPerPage,
        [CardsQueryKeysEnum.ARCHIVED]: !!isArchivedView,
        [CardsQueryKeysEnum.OFFSET]: offset,
        [CardsQueryKeysEnum.ID]: redirectedCardId,
        ...(tableSearchQuery.includes(OWNERS_QUERY_PREFIX)
          ? { [OWNERS_QUERY_PREFIX]: tableSearchQuery.split(OWNERS_QUERY_PREFIX)[1] }
          : { [CardsQueryKeysEnum.QUERY]: tableSearchQuery }),
        ...storedQueryWithoutPagination,
      },
      refetchInterval: 5000,
      onSuccess: (data) => {
        setPaginationCount(data.count);
      },
    },
  );

  useEffect(() => {
    if (redirectedCardId) {
      setSelectedCardId(redirectedCardId);

      const redirectedCard = cardsResponse?.data?.find((card) => card.id === redirectedCardId);

      if (redirectedCard) {
        setSelectedCard(redirectedCard);
      }
    }
  }, [redirectedCardId, cardsResponse]);

  return (
    <ErrorBoundary>
      <Cards
        cardsResponse={cardsResponse}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isArchivedView={!!isArchivedView}
        cardsQueryLocalStorageName={cardsQueryLocalStorageName}
        cardsQuery={cardsQuery}
        redirectedCardId={redirectedCardId}
        setTableSearchQuery={setTableSearchQuery}
      />
    </ErrorBoundary>
  );
};
