import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { ControlHistoryQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchServers } from 'api/servers/servers';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { fetchControlHistory } from 'api/control/control';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { useControlHistorySearchQuery } from './hooks/useControlHistorySearchQuery';
import { ControlHistory } from './ControlHistory';

export const ControlHistoryContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.CONTROL_HISTORY]: controlHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const {
    tkdSearchQuery,
    executorSearchQuery,
    ownerSearchQuery,
    setExecutorSearchQuery,
    setOwnerSearchQuery,
    setTkdSearchQuery,
    clearSearchQueries,
  } = useControlHistorySearchQuery();

  const { page, rowsPerPage, setPage, setRowsPerPage, storedQueryWithoutPagination } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.CONTROL_HISTORY,
    resetPaginationDependencyArray: [tkdSearchQuery, executorSearchQuery],
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: controlHistoryResponse, isLoading } = useQuery(
    [
      QueryKeyEnum.CONTROL_HISTORY,
      storedQueryWithoutPagination,
      rowsPerPage,
      page,
      tkdSearchQuery,
      executorSearchQuery,
      ownerSearchQuery,
    ],
    () => fetchControlHistory(),
    {
      args: {
        ...storedQueryWithoutPagination,
        [ControlHistoryQueryKeysEnum.SERWER]: controlHistoryQuery.serwer?.value,
        [ControlHistoryQueryKeysEnum.CZYTNIK]: controlHistoryQuery.tkd?.value,
        [ControlHistoryQueryKeysEnum.QUERY]: controlHistoryQuery.query?.value,
        [ControlHistoryQueryKeysEnum.POSIADACZ]: controlHistoryQuery.posiadacz?.value,
        [ControlHistoryQueryKeysEnum.TYP]: controlHistoryQuery.typ,
        [ControlHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [ControlHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  const onClearControlHistoryQuery = () => {
    setQuery(QueryKeyLocalStorageEnum.CONTROL_HISTORY, {
      page,
      rowsPerPage,
    });
    clearSearchQueries();
  };

  return (
    <ControlHistory
      controlHistoryResponse={controlHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      ownerSearchQuery={ownerSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      setOwnerSearchQuery={setOwnerSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      onClearControlHistoryQuery={onClearControlHistoryQuery}
    />
  );
};
