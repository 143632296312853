import { useState, useEffect } from 'react';
import { formatISO, sub } from 'date-fns';
import { ErrorBoundary } from 'shared-ui';

import { AppRoute } from 'routing/AppRoute.enum';
import { fetchEvents } from 'api/events/events';
import { QueryKeyEnum } from 'core/global.enum';
import {
  EventDateSortEnum,
  EventsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { fetchEventsExportFormats } from 'api/eventsExportFormat/eventsExportFormats';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { RefetchInterval } from './Events.types';
import { Events } from './Events';

export const EventsContainer = () => {
  const isHistoryView = useIsExactPath(AppRoute.eventsHistory);
  const [refetchInterval, setRefetchInterval] = useState<RefetchInterval>(15000);
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.EVENTS_HISTORY]: eventsHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const [tkdSearchQuery, setTkdSearchQuery] = useState('');

  const { page, rowsPerPage, setPage, setRowsPerPage } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.EVENTS_HISTORY,
    resetPaginationDependencyArray: [
      tableSearchQuery,
      eventsHistoryQuery.rewizja,
      eventsHistoryQuery.dataOd,
      eventsHistoryQuery.dataDo,
      eventsHistoryQuery.mod,
      eventsHistoryQuery.tryb,
      eventsHistoryQuery.poszukiwany,
      eventsHistoryQuery.zdarzenie,
      eventsHistoryQuery.tkd,
    ],
  });

  const { data: eventsResponse, isLoading } = useQuery(
    [QueryKeyEnum.EVENTS, page, rowsPerPage, isHistoryView, eventsHistoryQuery, tableSearchQuery],
    fetchEvents,
    {
      args: {
        [EventsQueryKeysEnum.OFFSET]: isHistoryView ? page * rowsPerPage : 0,
        [EventsQueryKeysEnum.LIMIT]: rowsPerPage,
        [EventsQueryKeysEnum.QUERY]: isHistoryView ? tableSearchQuery : '',

        ...(isHistoryView
          ? { ...eventsHistoryQuery, [EventsQueryKeysEnum.CZYTNIK]: eventsHistoryQuery.tkd?.value }
          : {
              [EventsQueryKeysEnum.SORT]: [EventDateSortEnum.DESC],
              [EventsQueryKeysEnum.DATA_OD]: formatISO(sub(new Date(), { days: 1 })),
              [EventsQueryKeysEnum.DATA_DO]: formatISO(new Date()),
            }),
      },
      refetchInterval: isHistoryView ? false : refetchInterval,
    },
  );

  const { data: eventsExportFormats } = useQuery(
    [QueryKeyEnum.EVENTS_EXPORT_FORMAT],
    () => fetchEventsExportFormats(),
    { args: { limit: 100 } },
  );

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.EVENTS_HISTORY, DEFAULT_QUERY_STATE.eventsHistory);
  }, []);

  return (
    <ErrorBoundary>
      <Events
        eventsResponse={eventsResponse}
        isLoading={isLoading}
        refetchInterval={refetchInterval}
        setRefetchInterval={setRefetchInterval}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        isHistoryView={!!isHistoryView}
        setTableSearchQuery={setTableSearchQuery}
        tableSearchQuery={tableSearchQuery}
        tkdSearchQuery={tkdSearchQuery}
        setTkdSearchQuery={setTkdSearchQuery}
        isExportAvailable={!!eventsExportFormats?.data?.length}
      />
    </ErrorBoundary>
  );
};
