import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip, Modal, Button, Option, AutocompleteOption } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { fetchOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AddControlForm, AddControlProps } from './AddControl.types';

export const AddControl = ({
  servers,
  terminals,
  owners,
  isLoadingTerminals,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
  isLoadingAddControl,
  setSelectedOwnersGroup,
  isLoadingOwnersControl,
  isAddControlDisabled,
  isRandomControl,
}: AddControlProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddControlForm>();

  const getTerminalsOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const ownersOptions = owners.map((owner) => ({
    id: owner.id,
    nazwa: `${owner.nazwisko} ${owner.imie}`,
    kod: owner.nrEwidencyjny,
  }));

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Tooltip
        placement="bottom-start"
        disableHoverListener={!isAddControlDisabled}
        title={formatMessage({ id: AppMessages['control.addOwner.tooltip'] })}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onModalOpen}
          isLoading={isLoadingOwnersControl}
          disabled={isAddControlDisabled}
        >
          {formatMessage({ id: AppMessages['control.addOwner'] })}
        </Button>
      </Tooltip>

      <Modal
        header={formatMessage({ id: AppMessages['control.addOwner'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoadingAddControl}
      >
        <Form.Grid>
          {isRandomControl ? (
            <Controller
              control={control}
              name="ids"
              render={({ field }) => (
                <OrganizationsTreeSelect
                  {...field}
                  required
                  multiple
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                  label={formatMessage({ id: AppMessages['control.addOwner.chooseOwners'] })}
                  extraGroupProps={{
                    options: ownersOptions,
                    label: formatMessage({ id: AppMessages['control.addOwner.owner'] }),
                    onGroupSelect: setSelectedOwnersGroup,
                    renderOption: (option) => (
                      <AutocompleteOption
                        key={option.id}
                        idPrefix={formatMessage({ id: AppMessages['tableSearch.owner.number'] })}
                        id={option.kod || ''}
                        label={option.nazwa}
                      />
                    ),
                  }}
                />
              )}
            />
          ) : (
            <FormAutocompleteContainer
              formFieldName="ids"
              queryKey={QueryKeyEnum.OWNERS}
              queryFunction={fetchOwners}
              args={{
                sort: 'nrEwidencyjny',
                ...(!isRandomControl && { karta: true, kontrolaNatychmiastowa: false }),
              }}
              createOption={(owner) => {
                return owner.id
                  ? {
                      label: `${owner.imie || ''} ${owner.nazwisko || ''}`,
                      value: owner.id,
                      archiwalny: owner.archiwalny,
                    }
                  : (owner as unknown as Option);
              }}
              handleOptionSelect={(option) => option.value}
              renderCustomOption={(props, option, owners) => (
                <AutocompleteOption
                  props={props}
                  key={option.id}
                  idPrefix={formatMessage({ id: AppMessages['card.assign.owner.number'] })}
                  id={owners ? owners.find((owner) => owner.id === option.value)?.nrEwidencyjny || 0 : ''}
                  label={option.label}
                />
              )}
              label={formatMessage({ id: AppMessages['control.instant.addOwner.owner.label'] })}
              placeholder={formatMessage({ id: AppMessages['control.instant.addOwner.owner.placeholder'] })}
              required
              withPopupSearchIcon
            />
          )}

          <Form.ControlledSelect
            name="serwerId"
            control={control}
            options={serverOptions}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['control.addOwner.server.label'] })}
            withHelperText
            required
          />

          <Controller
            name="tkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getTerminalsOptions()}
                loading={isLoadingTerminals}
                label={formatMessage({ id: AppMessages['control.addOwner.readers.label'] })}
                placeholder={formatMessage({ id: AppMessages['control.addOwner.readers.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Form.Grid>
      </Modal>
    </>
  );
};
