import { useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { RcpEnum } from '../eventsFilters/EventsFilters.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { EventDetailsProps } from './EventDetails.types';
import { EventEntryStateDescriptionEnum } from './EventDetails.enum';
import { EventDetailsEditForm } from './EventDetails.types';
import * as Styled from './EventDetails.styles';

export const EventDetails = ({ selectedEvent, isLoading, onSubmit }: EventDetailsProps) => {
  const { formatMessage } = useLocale();
  const { modOptions, modeOptions, rcpOptions } = useAppSelectOptions();
  const isHistoryView = useIsExactPath(AppRoute.eventsHistory);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<EventDetailsEditForm>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {selectedEvent.nazwaTKD && (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.name'] })}</Styled.DetailName>
          <Styled.DetailText> {selectedEvent.nazwaTKD} </Styled.DetailText>
        </Styled.DetailsItemWrapper>
      )}
      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.serverName'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedEvent.nazwaRcp} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.owner'] })}</Styled.DetailName>
        <Styled.DetailText>
          {selectedEvent.posiadacz.nazwisko || ''} {selectedEvent.posiadacz.imie || ''}
        </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      {isHistoryView && (
        <>
          <Styled.DetailsItemWrapper>
            <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.mod'] })}</Styled.DetailName>

            <Form.ControlledSelect name="mod" control={control} options={modOptions} />
          </Styled.DetailsItemWrapper>
          <Styled.DetailsItemWrapper>
            <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.mode'] })}</Styled.DetailName>

            <Form.ControlledSelect name="tryb" control={control} options={modeOptions} />
          </Styled.DetailsItemWrapper>
        </>
      )}
      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.entryState'] })}</Styled.DetailName>
        <Styled.DetailTextBold>
          {formatMessage({ id: AppMessages[EventEntryStateDescriptionEnum[selectedEvent.stanWejscia]] })}{' '}
        </Styled.DetailTextBold>
      </Styled.DetailsItemWrapper>
      {isHistoryView && (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.rcp'] })}</Styled.DetailName>

          <Form.ControlledSelect name="rcp" control={control} options={rcpOptions} />
        </Styled.DetailsItemWrapper>
      )}
      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.zone'] })}</Styled.DetailName>
        <Styled.DetailTextBold> {selectedEvent.strefa} </Styled.DetailTextBold>
      </Styled.DetailsItemWrapper>
      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.seek'] })}</Styled.DetailName>
        <Styled.DetailTextBold>
          {selectedEvent.poszukiwany
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.DetailTextBold>
      </Styled.DetailsItemWrapper>
      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.control'] })}</Styled.DetailName>
        <Styled.DetailTextBold>
          {selectedEvent.rewizja
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.DetailTextBold>
      </Styled.DetailsItemWrapper>
      {selectedEvent.ktoZedytowal && (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['event.details.changedBy'] })}</Styled.DetailName>
          <Styled.DetailText>{selectedEvent.ktoZedytowal || ''}</Styled.DetailText>
        </Styled.DetailsItemWrapper>
      )}
      {isHistoryView && isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset({ ...selectedEvent, rcp: selectedEvent.rcp ? RcpEnum.TRUE : RcpEnum.FALSE });
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </form>
  );
};
