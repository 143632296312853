import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchServersForWanted } from 'api/wanted/wanted';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { WantedTerminals } from './WantedTerminals';

export const WantedTerminalsContainer = () => {
  const { id: wantedId } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage, paginationCount, setPaginationCount } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.WANTED_TERMINALS,
  });

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.WANTED_TERMINALS, wantedId], () =>
    fetchServersForWanted(wantedId),
  );

  return (
    <WantedTerminals
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      terminalsCount={paginationCount}
      setTerminalsCount={setPaginationCount}
      wantedId={wantedId || ''}
    />
  );
};
