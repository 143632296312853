import { UseQueryFn, MutationFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import {
  AddTerminalsSequenceBody,
  Terminal,
  UpdateTerminalBody,
  UpdateTerminalSequenceBody,
  UpdateTerminalsBody,
} from './terminals.types';

export const fetchTerminals: UseQueryFn<string, PaginatedResponse<Terminal>> = (id) => {
  return {
    endpoint: `/rcp/${id}/tkd`,
  };
};

export const reloadTerminal: MutationFn<string, Terminal> = (id) => {
  return {
    endpoint: `/tkd/${id}/przeladuj`,
    method: 'POST',
  };
};

export const acceptTerminal: MutationFn<string, Terminal> = (id) => {
  return {
    endpoint: `/tkd/${id}/akceptuj`,
    method: 'POST',
  };
};

export const reloadManyTerminals: MutationFn<string[], Terminal> = (uuids) => {
  return {
    endpoint: '/batch/tkd/przeladuj',
    method: 'POST',
    body: {
      uuids,
    },
  };
};

export const acceptManyTerminals: MutationFn<string[], Terminal> = (uuids) => {
  return {
    endpoint: '/batch/tkd/akceptuj',
    method: 'POST',
    body: {
      uuids,
    },
  };
};

export const fetchAllTerminals: UseQueryFn<unknown, PaginatedResponse<Terminal>> = () => {
  return {
    endpoint: `/tkd`,
  };
};

export const updateTerminals: MutationFn<UpdateTerminalsBody, Terminal> = (body) => {
  return {
    endpoint: 'batch/tkd',
    method: 'PUT',
    body,
  };
};

export const updateTerminal: MutationFn<UpdateTerminalBody, Terminal> = ({ id, body }) => {
  return {
    endpoint: `tkd/${id}`,
    method: 'PUT',
    body,
  };
};

export const updateTerminalSequence: MutationFn<UpdateTerminalSequenceBody, Terminal> = ({ id, body }) => {
  return {
    endpoint: `tkd/${id}/sekwencja`,
    method: 'PUT',
    body,
  };
};

export const fetchAvailableTerminalsForSchedule: UseQueryFn<string, PaginatedResponse<Terminal>> = (id) => {
  return {
    endpoint: `/rcp/${id}/harmonogram/tkd`,
  };
};

export const checkAvailableTerminalsForSchedule: UseQueryFn<string, { isCreateHarmonogramNotAvailable: boolean }> = (
  id,
) => {
  return {
    endpoint: `/rcp/${id}/harmonogram/tkd/check`,
  };
};

export const fetchAvailableTerminalsForException: UseQueryFn<string, PaginatedResponse<Terminal>> = (id) => {
  return {
    endpoint: `/rcp/${id}/wyjatek/tkd`,
  };
};

export const addTerminalsSequence: MutationFn<AddTerminalsSequenceBody, Terminal> = (body) => {
  return {
    endpoint: 'tkd/sekwencja',
    method: 'POST',
    body,
  };
};
