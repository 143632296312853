export enum QueryKeyLocalStorageEnum {
  CARDS = 'cards',
  ARCHIVED_CARDS = 'archivedCards',
  TERMINALS = 'terminals',
  ACTIVE_CARD_HISTORY = 'activeCardHistory',
  ARCHIVED_CARD_HISTORY = 'archivedCardHistory',
  EVENTS_HISTORY = 'eventsHistory',
  OWNERS = 'owners',
  ARCHIVED_OWNERS = 'archivedOwners',
  ACTIVE_OWNER_HISTORY = 'activeOwnerHistory',
  ACTIVE_OWNER_PERMISSIONS_EFFECTIVE = 'activeOwnerPermissionsEffective',
  ACTIVE_OWNER_PERMISSIONS_INDIVIDUAL = 'activeOwnerPermissionsIndividual',
  ACTIVE_OWNER_GROUPS = 'activeOwnerGroups',
  ARCHIVED_OWNER_HISTORY = 'archivedOwnerHistory',
  ARCHIVED_OWNER_PERMISSIONS_EFFECTIVE = 'archivedOwnerPermissionsEffective',
  ARCHIVED_OWNER_PERMISSIONS_INDIVIDUAL = 'archivedOwnerPermissionsIndividual',
  ARCHIVED_OWNER_GROUPS = 'archivedOwnerGroups',
  POSITIONS = 'positions',
  EVENTS_EXPORT_FORMAT = 'eventsExportFormat',
  GROUPS = 'groups',
  GROUP_HISTORY = 'groupHistory',
  GROUP_PERMISSIONS_HISTORY = 'groupPermissionsHistory',
  ARCHIVED_GROUPS = 'archivedGroups',
  GROUP_TERMINALS_PERMISSIONS = 'groupTerminalsPermissions',
  GROUP_GLC_PERMISSIONS = 'groupGlcPermissions',
  GROUP_ASSIGNED_OWNERS = 'groupAssignedOwners',
  ACTIVE_GLC = 'activeGlc',
  ARCHIVED_GLC = 'archivedGlc',
  GLC_HISTORY = 'glcHistory',
  GLC_TERMINALS = 'glcTerminals',
  USERS = 'users',
  SELECTED_SEARCH_OPTIONS = 'selectedSearchOptions',
  OWNER_EFFECTIVE_PERMISSIONS_HISTORY = 'ownerEffectivePermissionsHistory',
  TERMINALS_WITH_DISPLAY = 'terminalsWithDisplay',
  TERMINALS_WITH_SEQUENCE = 'terminalsWithSequence',
  CONTROL_HISTORY = 'controlHistory',
  INSTANT_CONTROL = 'instantControl',
  INSTANT_CONTROL_TERMINALS = 'instantControlTerminals',
  RANDOM_CONTROL = 'randomControl',
  RANDOM_CONTROL_TERMINALS = 'randomControlTerminals',
  WANTED = 'wanted',
  WANTED_HISTORY = 'wantedHistory',
  WANTED_TERMINALS = 'wantedTerminals',
  ANNOUNCEMENTS = 'announcements',
  ANNOUNCEMENTS_HISTORY = 'announcementsHistory',
  ANNOUNCEMENT_ASSIGNED_OWNERS = 'announcementAssignedOwners',
  SCHEDULES = 'schedules',
  SCHEDULES_EXCEPTIONS = 'schedulesExceptions',
  SCHEDULES_HISTORY = 'schedulesHistory',
  SCHEDULE_TERMINALS = 'scheduleTerminals',
  SCHEDULE_EXCEPTION_TERMINALS = 'scheduleExceptionTerminals',
  SEQUENCES = 'sequences',
  SEQUENCES_HISTORY = 'sequencesHistory',
}

export enum PaginationQueryKeysEnum {
  PAGE = 'page',
  ROWS_PER_PAGE = 'rowsPerPage',
}

export enum CardsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  TYPE = 'typKarty',
  STATUS = 'status',
  BLOCKADE = 'blokada',
  SORT = 'sort',
  ARCHIVED = 'archiwalna',
  ID = 'id',
  QUERY = 'query',
  PROBLEM_TKD = 'problemTkd',
  PAGE = PaginationQueryKeysEnum.PAGE,
  ROWS_PER_PAGE = PaginationQueryKeysEnum.ROWS_PER_PAGE,
}

export enum TerminalsQueryKeysEnum {
  STATUS = 'status',
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  GLC_IDS = 'glcIds',
}

export enum TerminalIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum TerminalGlcIdSortEnum {
  ASC = 'idGlc',
  DESC = '-idGlc',
}

export enum CardNumberSortEnum {
  ASC = 'numerKarty',
  DESC = '-numerKarty',
}

export enum CardOwnerSortEnum {
  ASC = 'posiadacz',
  DESC = '-posiadacz',
}

export enum CardHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  UZYTKOWNIK = 'uzytkownik',
  BY = 'by',
  QUERY = 'query',
  PAGE = PaginationQueryKeysEnum.PAGE,
  ROWS_PER_PAGE = PaginationQueryKeysEnum.ROWS_PER_PAGE,
}

export enum EventsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ZDARZENIE = 'zdarzenie',
  MOD = 'mod',
  TRYB = 'tryb',
  RCP = 'rcp',
  POSZUKIWANY = 'poszukiwany',
  REWIZJA = 'rewizja',
  SERVER = 'serwer',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  QUERY = 'query',
  CZYTNIK = 'tkd',
}

export enum IdTkdSortEnum {
  ASC = 'idTkd',
  DESC = '-idTkd',
}

export enum OwnerSortEnum {
  ASC = 'posiadacz',
  DESC = '-posiadacz',
}

export enum EventDateSortEnum {
  ASC = 'data',
  DESC = '-data',
}

export enum OwnerIdSortEnum {
  ASC = 'skdId',
  DESC = '-skdId',
}

export enum OwnerRegistrationNumberSortEnum {
  ASC = 'nrEwidencyjny',
  DESC = '-nrEwidencyjny',
}

export enum OwnerNameSortEnum {
  ASC = 'nazwisko',
  DESC = '-nazwisko',
}

export enum OwnersQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  STANOWISKO = 'stanowisko',
  ARCHIWALNY = 'archiwalny',
  KARTA = 'karta',
  PAGE = PaginationQueryKeysEnum.PAGE,
  ROWS_PER_PAGE = PaginationQueryKeysEnum.ROWS_PER_PAGE,
}

export enum CardsHistorySearchByEnum {
  USER = 'user',
  PKD = 'pkd',
}

export enum OwnerPermissionsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  REJESTRUJ_ODBICIE = 'rejestrujOdbicie',
  OTWIERAJ_ZAMEK = 'otwierajZamek',
  REJESTRUJ_RCP = 'rejestrujRcp',
}

export enum OwnerPermissionsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum OwnerPermissionsReaderNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum OwnerGroupsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  RODZAJ_GRUPY = 'rodzaj',
  GLC = 'glc',
}

export enum OwnerGroupIdSortEnum {
  ASC = 'idGrupy',
  DESC = '-idGrupy',
}

export enum OwnerGroupNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum OwnerHistorySearchByEnum {
  USER = 'user',
  NUMER_KARTY = 'numerKarty',
}

export enum OwnerHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  UZYTKOWNIK = 'uzytkownik',
  BY = 'by',
  QUERY = 'query',
}

export enum PositionQueryKeysEnum {
  SORT = 'sort',
}

export enum PositionNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum EventExportFormatQueryKeysEnum {
  SORT = 'sort',
}

export enum EventExportFormatNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum GroupsIdSortEnum {
  ASC = 'idGrupy',
  DESC = '-idGrupy',
}

export enum GroupsNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum GroupsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  RODZAJ_GRUPY = 'rodzaj',
  KOMORKA = 'komorka',
  POSIADACZ = 'posiadacz',
  GLC = 'glc',
  QUERY = 'query',
  ARCHIWALNA = 'archiwalna',
}

export enum GroupTerminalsPermissionsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  REJESTRUJ_ODBICIE = 'rejestrujOdbicie',
  OTWIERAJ_ZAMEK = 'otwierajZamek',
  REJESTRUJ_RCP = 'rejestrujRcp',
}

export enum GroupTerminalsPermissionsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum GroupTerminalsPermissionsReaderNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum GroupGlcPermissionsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  REJESTRUJ_ODBICIE = 'rejestrujOdbicie',
  OTWIERAJ_ZAMEK = 'otwierajZamek',
  REJESTRUJ_RCP = 'rejestrujRcp',
}

export enum GroupGlcPermissionsIdSortEnum {
  ASC = 'idGlc',
  DESC = '-idGlc',
}

export enum GroupGlcPermissionsGlcNameSortEnum {
  ASC = 'nazwaGlc',
  DESC = '-nazwaGlc',
}

export enum GroupAssignedOwnersQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  STANOWISKO = 'stanowisko',
}

export enum UsersUsernameSortEnum {
  ASC = 'username',
  DESC = '-username',
}

export enum UsersFullNameSortEnum {
  ASC = 'nazwisko',
  DESC = '-nazwisko',
}

export enum UsersQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  TYP = 'typ',
  STATUS = 'status',
  QUERY = 'query',
}

export enum GlcTerminalsQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
}

export enum GlcTerminalsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum GlcTerminalsNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum GlcQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  QUERY = 'query',
  ARCHIVED = 'archiwalna',
  READER = 'czytnik',
  GROUP_PERMISSIONS = 'uprGrupowe',
  ID = 'id',
  WITHOUT_DIRECT_GROUP_PERMISSION = 'withoutDirectGroupPermission',
  SERVER_ID = 'serwerId',
}

export enum GlcNumberSortEnum {
  ASC = 'idGlc',
  DESC = '-idGlc',
}

export enum GlcNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum SearchQueryKeysEnum {
  CARDS_ACTIVE = 'cardsActive',
  CARDS_ARCHIVED = 'cardsArchived',
  ACTIVE_CARD_HISTORY = 'activeCardHistory',
  ARCHIVED_CARD_HISTORY = 'archivedCardHistory',
  OWNERS_ACTIVE = 'ownersActive',
  OWNERS_ARCHIVED = 'ownersArchived',
  ACTIVE_OWNER_HISTORY = 'activeOwnerHistory',
  ARCHIVED_OWNER_HISTORY = 'archivedOwnerHistory',
  ACTIVE_OWNER_PERMISSIONS_GROUP = 'activeOwnerPermisionsGroup',
  ARCHIVED_OWNER_PERMISSIONS_GROUP = 'archivedOwnerPermisionsGroup',
  GROUPS_ACTIVE = 'groupsActive',
  GROUPS_ARCHIVED = 'groupsArchived',
  ACTIVE_GLC = 'glcActive',
  ARCHIVED_GLC = 'glcArchived',
  CONTROL_INSTANT = 'controlInstant',
  CONTROL_RANDOM = 'controlRandom',
  WANTED = 'wanted',
}

export enum GroupHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  KOMORKA = 'komorka',
  POSIADACZ = 'posiadacz',
  QUERY = 'query',
}

export enum GroupPermissionsHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  GLC = 'glc',
  WYKONAWCA = 'wykonawca',
}

export enum GlcHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  CZYTNIK = 'czytnik',
  QUERY = 'query',
}

export enum OwnerEffectivePermissionsHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  WYKONAWCA = 'wykonawca',
}

export enum TerminalsWithDisplayQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
}

export enum TerminalWithDisplayIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum TerminalWithSequenceQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  SORT = 'sort',
  SEKWENCJA_TYP = 'sekwencjaTyp',
}

export enum TerminalWithSequenceIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum TerminalWithSequenceNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum ControlHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  TYP = 'typ',
  QUERY = 'query',
  POSIADACZ = 'posiadacz',
}

export enum InstantControlQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  PROBLEM_TKD = 'problemTkd',
  TYP = 'typ',
}

export enum RandomControlQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  PROBLEM_TKD = 'problemTkd',
  TYP = 'typ',
}
export enum InstantControlIdSortEnum {
  ASC = 'skdId',
  DESC = '-skdId',
}
export enum InstantControlOwnerRegistrationNumberSortEnum {
  ASC = 'nrEwidencyjny',
  DESC = '-nrEwidencyjny',
}
export enum InstantControlOwnerNameSortEnum {
  ASC = 'nazwisko',
  DESC = '-nazwisko',
}

export enum InstantControlTerminalsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
}

export enum InstantControlTerminalsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum InstantControlTerminalsNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum RandomControlIdSortEnum {
  ASC = 'skdId',
  DESC = '-skdId',
}

export enum RandomControlOwnerRegistrationNumberSortEnum {
  ASC = 'nrEwidencyjny',
  DESC = '-nrEwidencyjny',
}

export enum RandomControlOwnerNameSortEnum {
  ASC = 'nazwisko',
  DESC = '-nazwisko',
}

export enum RandomControlTerminalsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum RandomControlTerminalsNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum RandomControlTerminalsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
}

export enum WantedQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  PROBLEM_TKD = 'problemTkd',
}

export enum WantedIdSortEnum {
  ASC = 'skdId',
  DESC = '-skdId',
}

export enum WantedOwnerRegistrationNumberSortEnum {
  ASC = 'nrEwidencyjny',
  DESC = '-nrEwidencyjny',
}

export enum WantedOwnerNameSortEnum {
  ASC = 'nazwisko',
  DESC = '-nazwisko',
}

export enum WantedHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  INFORMACJA = 'informacja',
  QUERY = 'query',
  POSIADACZ = 'posiadacz',
}

export enum WantedTerminalsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
}

export enum WantedTerminalsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum WantedTerminalsNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum AnnouncementsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  PROBLEM_TKD = 'problemTkd',
  MOD = 'mod',
  TRYB = 'tryb',
  POSIADACZ = 'posiadacz',
}

export enum AnnouncementsIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum AnnouncementsNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum AnnouncementTerminalNameSortEnum {
  ASC = 'nazwaCzytnika',
  DESC = '-nazwaCzytnika',
}

export enum AnnouncementsHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  MOD = 'mod',
  TRYB = 'tryb',
  NAZWA = 'nazwa',
  QUERY = 'query',
  POSIADACZ = 'posiadacz',
}

export enum AnnouncementAssignedOwnersQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  KOMORKA = 'komorka',
  STANOWISKO = 'stanowisko',
}

export enum SchedulesQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  PROBLEM_TKD = 'problemTkd',
  TYP = 'typ',
}

export enum SchedulesNameSortEnum {
  ASC = 'nazwa',
  DESC = '-nazwa',
}

export enum SchedulesExceptionsQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  PROBLEM_TKD = 'problemTkd',
  TYP = 'typ',
}

export enum SchedulesExceptionsDateSortEnum {
  ASC = 'data',
  DESC = '-data',
}

export enum SchedulesHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  TYP = 'typ',
  QUERY = 'query',
  NAZWA = 'nazwa',
  WYJATEK = 'wyjatek',
}

export enum SequencesQueryKeysEnum {
  LIMIT = 'limit',
  OFFSET = 'offset',
  QUERY = 'query',
  SORT = 'sort',
  PROBLEM_TKD = 'problemTkd',
  TYP = 'typ',
  NAZWA_CZYTNIKA = 'numerLogicznyKontrolera',
}

export enum SequencesIdSortEnum {
  ASC = 'numerLogicznyKontrolera',
  DESC = '-numerLogicznyKontrolera',
}

export enum SequencesCardASortEnum {
  ASC = 'kartaA',
  DESC = '-kartaA',
}

export enum SequencesCardBSortEnum {
  ASC = 'kartaB',
  DESC = '-kartaB',
}

export enum SequencesHistoryQueryKeysEnum {
  OFFSET = 'offset',
  LIMIT = 'limit',
  DATA_OD = 'dataOd',
  DATA_DO = 'dataDo',
  AKCJA = 'akcja',
  SERWER = 'serwer',
  CZYTNIK = 'tkd',
  TYP = 'typ',
  QUERY = 'query',
  NUMER_KARTY = 'numerKarty',
}
