import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

import { TerminalsTableHeader } from './terminalsTableHeader/TerminalsTableHeader';
import { TerminalsProps } from './Terminals.types';
import { TerminalsTableRow } from './terminalsTableRow/TerminalsTableRow';

export const Terminals = ({ terminals, isLoading }: TerminalsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      gridTemplateColumns="200px 1fr 1fr 50px"
      data={terminals || []}
      nothingToShowText={formatMessage({ id: AppMessages['devices.readers.nothingToShow'] })}
      customTableHeight={() => undefined}
      isLoading={isLoading}
      renderHeader={() => <TerminalsTableHeader />}
      renderRow={(terminal) => <TerminalsTableRow terminal={terminal} />}
    />
  );
};
